import {
    List,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableFooter,
    TableHead, TablePagination,
    TableRow,
} from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react";
import ErrorBoundary from "../error-boundary/error-boundary.component";
import SearchBar from "../search-bar/search-bar.component";
import WorkflowRow from "../workflows-table-search/workflow.component";
import '../workflows-table-search/workflows.styles.scss';
import { useAppSelector } from "../../app/hooks";
import webHelpers from "../../scripts/helpers";
import {useSnackbar} from 'notistack';
import CustomTablePagination from "../pagination-buttons/pagination-buttons.component";
import TimeProfileRow from "./time-profile-component";
import { validateLocaleAndSetLanguage } from "typescript";
import WorkflowModal from "./time-profile-modal";

export interface ITimeProfile {
  id: string;
  is_bank_holiday_aware: boolean;
  name: string;
  days: {
    id: string;
    day: string;
    start_time: string;
    end_time: string;
  }
  cb: Function;
}
const defaultWorkflow: ITimeProfile[] = [
    {
      id: "1234",
      is_bank_holiday_aware: true,
      name: "default time_profile",
      days:{
          id: "5678",
          day: "Monday",
          start_time: "10:00",
          end_time: "10:00"
      },
      cb: () => {return;}
   }
  ];

export default function WorkFlowTable() {
    
      
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState<ITimeProfile[]>(defaultWorkflow); 
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [tableHeight, setTableHeight] = useState('400px')
  const [maxPages, setMaxPages] = useState<number>(1);
  
  
  const {enqueueSnackbar} = useSnackbar();
  const environment = useAppSelector(state => state.loggedIn.environment);
  const token = useAppSelector(state => state.loggedIn.token);

    let height = window.screen.height;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }; 

    function searchWorkflows(searchQuery: string){
        if(searchQuery !== '') {
          console.log("search : ", searchQuery);
            webHelpers.get('/api/workflow/timeprofiles?filter=' + searchQuery, environment, 'helios-api', token, (data: any) => {
                if(data === undefined || data === null ||  data.status === 400) {
                    enqueueSnackbar('unable to fetch workflows', {'variant': 'error'})
                    setRows(defaultWorkflow);
                }
                else{
                  //@ts-ignore
                    setRows(Object.values(data.workflows));
                    //@ts-ignore
                    setMaxPages(data.maximum_pages)
                }
            })
        }
        else{
            //webHelpers.get('/api/workflow/timeprofiles/?page=' + (page + 1) + '&pageSize=' + rowsPerPage, environment, 'helios-api', token, (data: any) => {
            webHelpers.get('/api/workflow/timeprofiles', environment, 'helios-api', token, (data: any) => {
                if(data === undefined || data === null || data.status === 400) {
                    enqueueSnackbar('unable to fetch workflows', {'variant': 'error'})
                }
                else{
                    console.log(data);
                    //@ts-ignore
                    setRows(Object.values(data));
                    //setMaxPages(data.maximum_pages)
                }
            })    
        }
    }

    useEffect(() => {
    const requestTimeout = setTimeout(() => {
        searchWorkflows(search)
    }, 500);
    return () => clearTimeout(requestTimeout);
  }, [search, rowsPerPage]);
    
   useEffect(() => {
      searchWorkflows(search);
   }, [page, environment]);
   
    useEffect(() => {
        if (height > 1340) {
            setTableHeight('750px');
        }
        if (height < 1340) {
            setTableHeight('450px');
        }
    }, [height]);
    const refreshTimeProfile = () => {
        searchWorkflows("");
        console.log("refreshed");
    }

  return (
    <>
      <div className="table-container">
        <div className="search-bar-centered">
            
          <SearchBar placeholder="Search by name.." onChange={setSearch} />
          {/* {@ts-ignore} */}
          <WorkflowModal callback={refreshTimeProfile}/>
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={rowsPerPage * maxPages}
                rowsPerPage={rowsPerPage}
                page={page}
                showFirstButton
                showLastButton
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
            
        </div>
        <ErrorBoundary>
          {rows !== defaultWorkflow ? (
            <>
                <TableContainer sx={{height: tableHeight }}>
              <Table
                stickyHeader
                sx={{ minWidth: "100%" }}
                size="small"
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Id</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((workflow: ITimeProfile) => (
                      <TimeProfileRow key={workflow.id}  {...workflow} cb={refreshTimeProfile}/>
                    ))}

                </TableBody>
                <TableFooter>
                  <TableRow>
                     
                  </TableRow>
                </TableFooter>
              </Table>
                </TableContainer>
            </>
          ) : (
            <></>
          )}
        </ErrorBoundary>
      </div>
    </>
  );
}
