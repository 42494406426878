import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from '../protected-route/protected-route.component';
import SignInPage from '../../pages/sign-in/sign-in.page';
import Staff from '../../pages/staff-management/staff.pages';
import Groups from '../../pages/group-management/groups.pages';
import PermissionsManagement from '../../pages/permissions-management/permissions-management.pages';
import BlockedPhoneNumberPage from '../../pages/blocked-numbers-management/blocked-numbers.pages';
import Fade from '@mui/material/Fade';
import WorkflowPage from '../../pages/workflow-management/workflow-management-page.component';
import TimeProfilePage from "../time-profile-modal/time-profile-table";

const PageContainer = (props: any) => {
    return (
        <Routes>
            <Route path='/' element={<Navigate to='signIn' />} />
            <Route path='signIn' element={<SignInPage />} />
            <Route path='management/staff' element={
                <ProtectedRoute>
                    <Staff />
                </ProtectedRoute>}
            />
            <Route path='management/groups' element={
                <ProtectedRoute>
                    <Groups />
                </ProtectedRoute>}
            />
            <Route path='management/permissions' element={
                <ProtectedRoute>
                    <PermissionsManagement />
                </ProtectedRoute>}
            />
            <Route path='management/blocked-numbers' element={
                <ProtectedRoute>
                    <BlockedPhoneNumberPage />
                </ProtectedRoute>}
            />
            {
             <Route path='management/workflow' element={
                <ProtectedRoute>
                    <WorkflowPage />
                </ProtectedRoute>
            }
            />
            }
            <Route path='management/time-profiles' element={
                <ProtectedRoute>
                    <TimeProfilePage/>
                </ProtectedRoute>
            }
            />
        </Routes>
    )
};

export default PageContainer;