import * as React from 'react';
import {useState, useEffect} from 'react';
import './workflow-creator-dialog.styles.scss';
import webHelpers from "../../scripts/helpers";
import {useAppSelector} from "../../app/hooks";
import {useSnackbar} from 'notistack';
import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List
} from '@mui/material';
import SearchBar from "../search-bar/search-bar.component";
import EventButton from "../workflow-step-events-dialog/event.component";
interface Event {
    id: string,
    name: string,
}

const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
};

function CreateWorkflow() {
   const [open, setOpen] = useState(false); 
   const [name, setName] = useState<string>();
   const [description, setDescription] = useState<string>();
   const [eventId, setEventId] = useState<string>();
   const [search, setSearch] = useState<string>('');
   const [events, setEvents] = useState<Event[]>([]);
   const token = useAppSelector(state => state.loggedIn.token);
   const environment =  useAppSelector(state => state.loggedIn.environment);
   const {enqueueSnackbar} = useSnackbar();
   
   
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }; 
    
    const handleNameChange = (e: { target: { value: React.SetStateAction<string | undefined>; }; }) => {
        setName(e.target.value);
    }
    const handleDescriptionChange = (e: { target: { value: React.SetStateAction<string | undefined>; }; }) => {
        setDescription(e.target.value);
    }
    function submitWorkflow(){
        let payload = {
            'name': name,
            'description': description,
            'event_id': eventId,
            'code': '// start coding here',
            'steps': [
                {
                   'name': 'Default Step Name',
                   'description': 'Default Description',
                   'tag': 'default',
                   'cooldown_time': '00:01:00',
                   'estimated_length': '00:01:00',
                   'execution_type': 'CodeBased',
                   'execution_code': `//Event Code`,
                   'events':[],
                }
            ]
        }
       webHelpers.post('/api/workflow/create', environment, 'helios-api', payload, token, (data:any) => {
        if(data.status === 400 || data.status === 404 || data.status === 500 || data.status === 401 ){
            enqueueSnackbar(`${data.status}: Some error ocurred processing this request.`, {'variant': 'error'});
        }
        else {
            enqueueSnackbar(`Workflow ${payload.name} successfully created!`, {'variant': 'success'})
            setOpen(false);
        }
       }) 
    }
    
    
   const handleSubmit = () => {
       if(name!== undefined && name !== '' && description !== undefined && description !== '' && eventId !== undefined && eventId !== ''){
            submitWorkflow();
       }
       else{
          enqueueSnackbar('Please fill out all fields before submitting', {'variant': 'warning'});
       }
   } 
   
   
  useEffect(() => {
    const requestTimeout = setTimeout(() => {
        searchEvents();
    }, 500);
    return () => clearTimeout(requestTimeout); 
  }, [search])
    
    useEffect(() => {
       setName('');
       setDescription('');
       setEventId('');
       setSearch('');
    }, [open])
      
      

function searchEvents() {
    if(search !== ''){
        webHelpers.get('/api/events/?page=1&filter=' + search, environment, 'helios-api', token, (data: any) => {
            if(data.length === 0 || data.response === 400) {
                enqueueSnackbar('Unable to fetch permissions with this name, please try another', {'variant': 'error'});
            }
            else {
                setEvents(data);
            }
        })
    }
}
  
    return(
       <>
           {/*  dialog component here for capturing the form data*/}
           <Button variant="outlined" onClick={handleClickOpen}>
               Create New 
           </Button>
           <Dialog open={open} onClose={handleClose}>
               <DialogTitle>Create Workflow</DialogTitle>
               <DialogContent>
                   <DialogContentText>
                       Please ensure you fill out the forms correctly and select an event Id for the workflow
                   </DialogContentText>
                   <div className="form-fields">
                       <TextField
                           margin="dense"
                           id="name"
                           label="Name"
                           type="text"
                           value={name}
                           onChange={handleNameChange}
                           variant="standard"
                       />
                       <TextField
                           margin="dense"
                           multiline={true}
                           id="Description"
                           label="Description"
                           type="text"
                           value={description}
                           onChange={handleDescriptionChange}
                           variant="standard"
                       /> 
                       <p>Selected Event ID: {eventId}</p>
                       <div className="search-containment">

                           <List sx={style}> 
                           <SearchBar placeholder={'Search events'} onChange={setSearch} />
                           {events.map((event: Event) => (<>
                                   <EventButton key={event.id} id={event.id} cb={setEventId}  />
                               </>
                           ))}
                           </List>
                       </div>
                   </div>
                   
               </DialogContent>
               <DialogActions>
                   <Button onClick={handleClose}>Cancel</Button>
                   <Button onClick={handleSubmit}>Submit</Button>
               </DialogActions>
           </Dialog> 
           
       </> 
    )
};

export default CreateWorkflow;