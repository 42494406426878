import {
    List,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableFooter,
    TableHead, TablePagination,
    TableRow,
} from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react";
import ErrorBoundary from "../error-boundary/error-boundary.component";
import SearchBar from "../search-bar/search-bar.component";
import WorkflowRow from "./workflow.component";
import './workflows.styles.scss';
import { useAppSelector } from "../../app/hooks";
import webHelpers from "../../scripts/helpers";
import {useSnackbar} from 'notistack';
import CustomTablePagination from "../pagination-buttons/pagination-buttons.component";

export interface IWorkflow {
  id: string;
  name: string;
  description: string;
  initializer_code_id: string;
}

const defaultWorkflow: IWorkflow[] = [
  {
    id: "1234",
    name: "default workflow",
    description: "this is a workflow",
    initializer_code_id: "1245123451235",
  },
];

export default function WorkFlowTable() {
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState<IWorkflow[]>(defaultWorkflow); 
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [tableHeight, setTableHeight] = useState('400px')
  const [maxPages, setMaxPages] = useState<number>(1);
  
  
  const {enqueueSnackbar} = useSnackbar();
  const environment = useAppSelector(state => state.loggedIn.environment);
  const token = useAppSelector(state => state.loggedIn.token);

    let height = window.screen.height;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }; 

    function searchWorkflows(searchQuery: string){
        if(searchQuery !== '') {
            webHelpers.get('/api/workflows/?page=' + (page + 1) + '&pageSize=' + rowsPerPage + '&filter=' + searchQuery, environment, 'helios-api', token, (data: any) => {
                if(data === undefined || data === null ||  data.status === 400) {
                    enqueueSnackbar('unable to fetch workflows', {'variant': 'error'})
                    setRows(defaultWorkflow);
                }
                else{
                    setRows(Object.values(data.workflows));
                    setMaxPages(data.maximum_pages)
                }
            })
        }
        else{
            webHelpers.get('/api/workflows/?page=' + (page + 1) + '&pageSize=' + rowsPerPage, environment, 'helios-api', token, (data: any) => {
                if(data === undefined || data === null || data.status === 400) {
                    enqueueSnackbar('unable to fetch workflows', {'variant': 'error'})
                }
                else{
                    setRows(Object.values(data.workflows));
                    setMaxPages(data.maximum_pages)
                }
            })    
        }
    }

    useEffect(() => {
    const requestTimeout = setTimeout(() => {
        searchWorkflows(search)
    }, 500);
    return () => clearTimeout(requestTimeout);
  }, [search, rowsPerPage]);
    
   useEffect(() => {
      searchWorkflows(search);
   }, [page, environment]);
   
    useEffect(() => {
        if (height > 1340) {
            setTableHeight('750px');
        }
        if (height < 1340) {
            setTableHeight('450px');
        }
    }, [height]);

  return (
    <>
      <div className="table-container">
        <div className="search-bar-centered">
            
          <SearchBar placeholder="Search by name.." onChange={setSearch} />
            <TablePagination
                rowsPerPageOptions={[10, 25, 50]}
                component="div"
                count={rowsPerPage * maxPages}
                rowsPerPage={rowsPerPage}
                page={page}
                showFirstButton
                showLastButton
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
        <ErrorBoundary>
          {rows !== defaultWorkflow ? (
            <>
                <TableContainer sx={{height: tableHeight }}>
              <Table
                stickyHeader
                sx={{ minWidth: "100%" }}
                size="small"
                aria-label="custom pagination table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <strong>Name</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Id</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Description</strong>
                    </TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((workflow: IWorkflow) => (
                      <WorkflowRow key={workflow.id} {...workflow} />
                    ))}

                </TableBody>
                <TableFooter>
                  <TableRow>
                     
                  </TableRow>
                </TableFooter>
              </Table>
                </TableContainer>
            </>
          ) : (
            <></>
          )}
        </ErrorBoundary>
      </div>
    </>
  );
}
