import React from 'react';
import { useState } from 'react';
import './header.styles.scss';
import ErrorBoundary from '../error-boundary/error-boundary.component';
import LogoutIcon from '@mui/icons-material/Logout';
import { store } from '../../app/store';
import { toggle, updateUser, saveToken } from '../../features/loggedIn/loggedInSlice';
import { useNavigate } from 'react-router-dom';
import EnvironmentSelector from '../environment-selector/environment-selector.component';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { useSnackbar } from 'notistack'
// PLEASE NOTE: This file depends on the npm module 'sass'
// If missing please run 'npm install sass' or 'yarn add sass'

// takes a username and Icon and renders these dynamically at the top. 
interface HEADERPROPS {
    userName: string,
    page: string,
}
const Header = (props: HEADERPROPS) => {
    const [fullscreen, setFullscreen] = useState(false);
    let navigate = useNavigate();
    let width = window.screen.width;
    const { enqueueSnackbar } = useSnackbar();

    const signOut = (e: { target: any; }) => {
        store.dispatch({ type: toggle });
        store.dispatch({ type: saveToken, payload: '' });
        store.dispatch({ type: updateUser, payload: '' });
        navigate('../../signIn');
    }

    const handleFullscreenToggle = () => {
        let dashboard = document.getElementsByClassName('dashboard-container')
        let app = document.getElementsByClassName('app-container')

        if (fullscreen) {

            dashboard[0].classList.remove('--fullscreen');
            app[0].classList.remove('--fullscreen');
            enqueueSnackbar('Exiting fullscreen mode', { 'variant': 'success' })
        }
        else {
            dashboard[0].classList.add('--fullscreen');
            app[0].classList.add('--fullscreen');
            enqueueSnackbar('Entering fullscreen mode', { 'variant': 'success' })
        }
        setFullscreen(!fullscreen);
    }

    return (
        <ErrorBoundary>
            <header className="header-container">
                <h1 className="header-page-title"></h1>
                <div className="header-user-container">
                    <EnvironmentSelector />
                    <h3 className="header-user-name">{props.userName}</h3>
                    {width >= 900 && !fullscreen ?
                        (
                            <Tooltip title='Enter Fullscreen mode'>
                                <IconButton onClick={handleFullscreenToggle}>
                                    <FullscreenIcon />
                                </IconButton>
                            </Tooltip>

                        ) : (
                            <></>
                        )
                    }
                    {width >= 900 && fullscreen ? (
                        <Tooltip title='Exit Fullscreen mode'>
                            <IconButton onClick={handleFullscreenToggle}>
                                <FullscreenExitIcon />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <></>
                    )}

                    <Tooltip title='sign out'>
                        <LogoutIcon onClick={signOut} className='logout' />
                    </Tooltip>
                </div>
            </header>
        </ErrorBoundary>
    )
};
export default Header;