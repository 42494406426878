import * as React from 'react';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../app/hooks';
import { useSnackbar } from 'notistack';
import webHelpers from '../../scripts/helpers';
import { Stack, Chip } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Grow from '@mui/material/Grow';
import Box from '@mui/material/Box';
import AllPermissionsTable from './all-permissions-table.component';
interface IPermissionTabProps {
    user_id: string,

}
interface Permission {
    id: string,
    name: string,
    revoked: boolean,
    sid: string,
}

export default function PermissionsTab(props: IPermissionTabProps) {
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [id, setId] = useState(props.user_id);

    const { enqueueSnackbar } = useSnackbar();
    const token = useAppSelector(state => state.loggedIn.token)
    const environment = useAppSelector(state => state.loggedIn.environment)


    function handleDelete(permission: Permission) {
        webHelpers.post('/api/management/permission/revoke/' + permission.id, environment, 'auth', {}, token, (data: any) => {
            if (data.status === 200) {
                enqueueSnackbar('Permission successfully revoked', { 'variant': 'success' });
                updatePermissions();
            }
            else {
                enqueueSnackbar(`${data.status}: Unable to revoke this permission.`, { 'variant': 'error' })
            }
        })
    }
    function handleAssign(name: string) {
        const data = {
            'permission': name
        };
        webHelpers.post('/api/management/permission/assign/' + props.user_id, environment, 'auth', data, token, (data: any) => {
            if (data.status === 200) {
                enqueueSnackbar('Permission successfully assigned', { 'variant': 'success' });
                updatePermissions();
            }
            else {
                enqueueSnackbar(`${data.status}: Unable to assign this permission.`, { 'variant': 'error' });
            }
        })
    };

    function updatePermissions() {
        webHelpers.get('/api/management/user/' + props.user_id + '/permissions', environment, 'auth', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {

                // Some fallback ui needed here.
            }
            else {
                setPermissions(data);
            }
        })
    }

    useEffect(() => {
        updatePermissions();
    }, []);


    return (
        <>
            <Grow in={true} mountOnEnter unmountOnExit>
                <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'column', p: 3, mt: 4 }}>

                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 360,
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 475,
                            }}
                        >
                            {permissions.length >= 0 ? (
                                permissions.map(permission => (
                                    permission.revoked ? (<></>) :
                                        (
                                            <Chip
                                                key={permission.id}
                                                label={permission.name}
                                                onDelete={() => handleDelete(permission)}
                                                sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }}
                                                
                                            />
                                        )
                                ))
                            )   // lord jesus forgive me for this bracket structure 
                                :
                                (
                                    permissions.map(permission => (
                                        permission.revoked ? (<></>) :
                                            (
                                                <Chip
                                                    key={permission.id}
                                                    label={permission.name}
                                                    onDelete={() => handleDelete(permission)}
                                                    sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }}

                                                />
                                            )
                                    ))
                                )
                            }
                        </List>
                    </Stack>

                    <Stack sx={{ display: 'flex', width: '100%' }}>

                        <AllPermissionsTable handleAssign={handleAssign} />

                    </Stack>
                </Stack>
            </Grow>
        </>
    )
}