import * as React from 'react';
import { useState, useEffect } from 'react';
import webHelpers from "../../scripts/helpers";
import { useAppSelector } from '../../app/hooks';
import { useSnackbar } from "notistack";
import AceEditor from "react-ace";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import DialogTitle from "@mui/material/DialogTitle";
import { DialogActions, DialogContentText } from "@mui/material";
import { SidePane } from 'react-side-pane';
import {split as splitEditior} from "react-ace"; 
import DisplayNamespaces from "../namespaces/display-namespaces-component"
import { fontFamily } from '@material-ui/system';

interface ICodeEditorDialogProps {
    stepFriendlyName: string,
    code_id: string,
    buttonText: string
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CodeEditorDialog(props: ICodeEditorDialogProps) {
    const [open, setOpen] = useState(false);
    const [initialCode, setInitialCode] = useState<string>();
    const [code, setCode] = useState('');
    const [unsavedCode, setUnsavedCode] = useState(false);
    const [version, setVersion] = useState(0);
    const environment = useAppSelector(state => state.loggedIn.environment);
    const token = useAppSelector(state => state.loggedIn.token);
    const { enqueueSnackbar } = useSnackbar();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (code !== initialCode) {
            setUnsavedCode(true);
        }
        else {
            setOpen(false);
        }
    };
    const handleCancel = () => {
        setUnsavedCode(false);
    }

    function saveCode() {
        if (code !== initialCode) {
            var payload = {
                'id': props.code_id,
                'code': code
            }
            webHelpers.post('/api/code/' + props.code_id, environment, 'helios-api', payload, token, (data: any) => {
                if (data === undefined || data === null) {
                    if (data.status === 200){
                        enqueueSnackbar('Code successfully updated!', { 'variant': 'success' });
                        setOpen(false);
                        setUnsavedCode(false);
                    }
                    else if (data.status === 500){
                        console.log(data);
                        data.Message.messages.forEach((element:any) => {
                            enqueueSnackbar(data.Status + " status error, message : " + element.message, { 'variant': 'error' })
                            
                        });
                    }
                    enqueueSnackbar('${data.status}: Unable to update code', { 'variant': 'error' });
                }
                else {
                    console.log(data)
                    if (data.id === props.code_id){
                        enqueueSnackbar('Code successfully updated!', { 'variant': 'success' });
                        setOpen(false);
                        setUnsavedCode(false);
                    }
                    else if (data.Status === 500){
                        data.Message.messages.forEach((element:any) => {
                            enqueueSnackbar(data.Status + " status error, message : " + element.message, { 'variant': 'error' })
                            
                        });
                    }
                    else if (data.Status === 400){
                        data.Message.messages.forEach((element:any) => {
                            enqueueSnackbar(data.Status + " status error, message : " + element.message, { 'variant': 'error' })
                            
                        });
                        //enqueueSnackbar(data.Status + " status error, message : " + data.Message.messages[0].message, { 'variant': 'error' })
                    }
                }
            })
        }
        else {
            enqueueSnackbar('No changes to code made!', { 'variant': 'warning' }); 
        }
    }

    const handleSave = () => {
        saveCode();
    }

    function getCode() {
        if (props.code_id !== 'null' && props.code_id !== null) {
            webHelpers.get('/api/code/' + props.code_id, environment, 'helios-api', token, (data: any) => {
                if (data === undefined || data === null) {
                    enqueueSnackbar('API error: Unable to fetch code for this step', { 'variant': 'error' });
                }
                else {
                    setCode(data.code);
                    setInitialCode(data.code);
                    setVersion(data.version);
                }
            })
        }
    }

    useEffect(() => {
        getCode();
    }, [open, props.code_id])

    return (
        <>
            <Button sx={{ marginLeft: '9rem', height: '2.5rem', marginTop: '2rem' }} variant="outlined" onClick={handleClickOpen}>
                {props.buttonText}
            </Button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >

                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <p>   Step: {props.stepFriendlyName} | Version: {version}</p>
                        <Button autoFocus color="inherit" onClick={handleSave}>
                            Save
                        </Button>
                    </Toolbar>
                    <div className='test'>
                        <AceEditor
                            style={{
                                height: "95vh",
                                width: "80%",
                                font: "icon",
                                fontFamily: "monospace"
                            }}
                            theme='dracula'
                            placeholder={initialCode}
                            mode="csharp"
                            name="Script for step"
                            onChange={(currentCode) => setCode(currentCode)}
                            fontSize={18}
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            value={code}
                            enableBasicAutocompletion={true}
                            tabSize={4}
                            
                        />
                        <div className='test-div'>

<DisplayNamespaces {...props}/>
</div>
                    </div>
                </AppBar>

            </Dialog>

            <Dialog
                open={unsavedCode}
                onClose={handleCancel}
                TransitionComponent={Transition}
                sx={{ padding: '2rem' }}
            >
                <DialogTitle>Wait!</DialogTitle>
                <p style={{ padding: '1rem' }}> You have made changes to the code which haven't been saved,
                    if you exit now you'll lose any changes made!</p>
                <DialogActions>
                    <Button onClick={() => setUnsavedCode(false)}>Continue Coding</Button>
                    <Button onClick={handleSave}>Save Changes</Button>
                    <Button onClick={() => {
                        setOpen(false);
                        setUnsavedCode(false);
                    }}>Discard Changes</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
export default CodeEditorDialog;