import * as React from 'react';
import { useState } from 'react';
import { store } from '../../app/store';
import { updateEnvironment } from '../../features/loggedIn/loggedInSlice';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { InputLabel, Select } from '@mui/material';


const EnvironmentSelector = () => {
    const [environment, setEnvironment] = useState('live');


    const envHandler = (e: { target: { value: any; }; }) => {
        store.dispatch({ type: updateEnvironment, payload: e.target.value })
        setEnvironment(e.target.value);
    }
    return (
        <div>
            <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel id="demo-simple-select-autowidth-label">Env</InputLabel>
                <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={environment}
                    onChange={envHandler}
                    autoWidth
                    label="Environment"
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value='sandbox'>Sandbox</MenuItem>
                    <MenuItem value='live'>Live</MenuItem>
                    <MenuItem value='gav'>Gav</MenuItem>
                    <MenuItem value='sam'>Sam</MenuItem>
                </Select>
            </FormControl>
        </div>)
}

export default EnvironmentSelector;