import * as React from 'react';

import { IconButton } from '@mui/material';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
interface CustomTablePaginationActionsProps {
    onPrev: Function;
    onNext: Function;
    goFirst: Function;
    goLast: Function;
}

function CustomTablePagination(props: CustomTablePaginationActionsProps) {
    const handleLeft = () => props.onPrev();
    const handleRight = () => props.onNext();
    const handleFirst = () => props.goFirst();
    const handleLast = () => props.goLast();
    return (
        <>
            <td>
                <IconButton onClick={handleFirst} aria-label='first page'>
                    <FirstPageIcon />
                </IconButton>
                <IconButton onClick={handleLeft} aria-label="previous page">
                    <KeyboardArrowLeft />
                </IconButton>
                <IconButton onClick={handleRight} aria-label="next page">
                    <KeyboardArrowRight />
                </IconButton>
                <IconButton onClick={handleLast} aria-label='last page'>
                    <LastPageIcon />
                </IconButton>
            </td>
        </>
    )
}

export default CustomTablePagination;