import * as React from "react";
import {useState} from 'react';
import webHelpers from "../../scripts/helpers";
import { useAppSelector } from "../../app/hooks";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import {useSnackbar} from 'notistack';

interface BlockedNumbersDeleteDialogProps {
  id: string;
  type: number;
  cb: Function
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BlockedNumbersDeleteDialog = (props: BlockedNumbersDeleteDialogProps) => {
  const [open, setOpen] = useState(false);

  const token = useAppSelector(state => state.loggedIn.token);
  const environment = useAppSelector(state => state.loggedIn.environment);
  const { enqueueSnackbar } =  useSnackbar();
  const handleClickOpen = () => -setOpen(true)
  const handleClose = () => -setOpen(false)

  const handleConfirm = () => {
    let id = props.id;
    id = id.replace('+', '$');

      webHelpers.delete('/api/call/control/block/?id=' + id, 'zeus-api', environment, token, (data:any) => {
          if(data.status == "400") {
            enqueueSnackbar(`Unable to delete this number from the table!`, {'variant': 'error'});

          }
          else {
              enqueueSnackbar('number removed from blocklist!', {'variant': 'success'});
              props.cb();
              setOpen(false);
          }
      })
  };
  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>Unblock</Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Delete this number from the block list?"}</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>No</Button>
          <Button onClick={handleConfirm}>Yes</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BlockedNumbersDeleteDialog;
