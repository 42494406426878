import * as  React from 'react';
import './permission.styles.scss';
import { IPermissionRegular } from '../../assets/global-interfaces/permission.interfaces';
import {
    TableRow, TableCell
} from '@mui/material';
import DeletePermission from './delete-permission-dialog.component';
interface PermissionProps {
    permission: IPermissionRegular,
    cb: Function,
}

function Permission(props: PermissionProps) {
    return (<>
        <TableRow key={props.permission.id}>
            <TableCell className="cell-data" sx={{ width: 400,  padding: 0, paddingLeft: 2}} component="th" scope="row">
                <p >{props.permission.id}</p>
            </TableCell>
            <TableCell className="cell-data" style={{ width: 'min-content', padding: 0.75 }} align="left">
                <p >{props.permission.name}</p>
            </TableCell>
            {/* <TableCell style={{ width: 60 }} align="right">
                <DeletePermission id={props.permission.id} cb={props.cb}/>
            </TableCell> */}
        </TableRow>
    </>)
}

export default Permission;