import * as React from 'react';
import './navigation-bar.styles.scss';
import { Link } from 'react-router-dom';
import ErrorBoundary from '../error-boundary/error-boundary.component';

import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import GroupIcon from '@mui/icons-material/Group';
import AbleLogo from './logo.svg';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import ConstructionIcon from '@mui/icons-material/Construction';
import AccessAlarm from "@mui/icons-material/AccessAlarm"
const NavigationBar = () => {
    return (
        <>
            <ErrorBoundary>
                <div className="navigation-bar-container">

                    <div className="navigation-bar-header">
                        {/* Logo and able group text */}
                        <img src={AbleLogo} className='able-logo' alt='Able-Group' />
                    </div>
                    <ul className="navigation-bar-links">

                        <li className="navigation-bar-item">
                            <Link to='management/staff' className="navigation-bar-option" >
                                <ManageAccountsIcon className='navigation-bar-icon' />
                                <p className='navigation-bar-option-text'>STAFF</p>
                            </Link>
                        </li>

                        <li className="navigation-bar-item">
                            <Link to='management/groups' className="navigation-bar-option">
                                <GroupIcon  className='navigation-bar-icon'/>
                                <p className='navigation-bar-option-text'>GROUPS</p>

                            </Link>
                        </li>

                        <li className="navigation-bar-item">
                            <Link to='management/permissions' className="navigation-bar-option">
                                <BubbleChartIcon  className='navigation-bar-icon'/>
                                <p className='navigation-bar-option-text'>Permissions</p>

                            </Link>
                        </li>
                        <li className="navigation-bar-item">
                            <Link to='management/blocked-numbers' className="navigation-bar-option">
                                <PhoneDisabledIcon className='navigation-bar-icon' />
                                <p className='navigation-bar-option-text'>Blocklist</p>

                            </Link>
                        </li>
                        {
                         <li className="navigation-bar-item">
                            <Link to='management/workflow' className="navigation-bar-option">
                                <ConstructionIcon className='navigation-bar-icon' />
                                <p className='navigation-bar-option-text'>Workflows</p>

                            </Link>
                        </li>
                        }
                        <li className='navigation-bar-item'>
                            <Link to="management/time-profiles" className='navigation-bar-option'>
                                <AccessAlarm className='navigation-bar-icon' />
                                <p className='navigation-bar-option-text'>Time Profiles</p>
                            </Link>
                        </li>

                    </ul>

                </div>
            </ErrorBoundary>
        </>
    );
};

export default NavigationBar;