import * as React from 'react';
import {useState} from 'react';
import {TextField, Button, Dialog, DialogActions, DialogTitle, 
        DialogContent, DialogContentText, FormLabel, FormControl, 
        FormControlLabel, Radio, RadioGroup} 
    from '@mui/material';

import webHelpers from '../../scripts/helpers';
import '../workflow-steps/workflow-step-panel.styles.scss';
import {useAppSelector} from '../../app/hooks';
import {useSnackbar} from 'notistack';
import DateTimePicker from "../date-time-picker/date-time-picker.component";
import StepCodeEditor from "./workflow-step-creator-code-editor.component";

interface ICreateStepProps {
    workflow_id: string,
}

function CreateStep(props: ICreateStepProps) {
   const [open, setOpen] = useState(false);
   const [name, setName] = useState('');
   const [executionType, setExecutionType] = useState('CodeBased');
   const [description, setDescription] = useState('');
   const [code, setCode] = useState('');
   const [twilioSid, setTwilioSid] = useState('');
   const [twilioTaskType, setTwilioTaskType] = useState('');
   const [cooldownTime, setCooldownTime] = useState('');
   const [tag, setTag] = useState('');
   const environment = useAppSelector(state => state.loggedIn.environment);
   const token = useAppSelector(state => state.loggedIn.token);
   const {enqueueSnackbar} = useSnackbar(); 
   
   const handleNameChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
       setName(e.target.value);
   }
   
   const handleDescriptionChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
       setDescription(e.target.value);
   }
   const handleTagChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
       setTag(e.target.value);
   }
   const handleRadioChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
      setExecutionType(e.target.value);
   }
   const handleTwilioSidChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
       setTwilioSid(e.target.value);
   }
   const handleTwilioTaskTypeChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
       setTwilioTaskType(e.target.value);
   }
   
  function buildTwilioStep() {
      if(twilioSid === '' || twilioTaskType === '') {
         enqueueSnackbar('Please ensure you supply a TwilioSid and Twilio Task Type', {'variant': 'warning'});
      } else {
          let payload = {
              'workflow_id': props.workflow_id,
              'name': name,
              'description': description,
              'execution_type': executionType,
              'cooldown_time': cooldownTime,
              'twilio_task' : {
                 'workflow_sid': twilioSid,
                 'echo_interaction_type': twilioTaskType
              },
              'tag': tag,
              'events': [],
          }
          createStep(payload);         
      }
  }
   
  function buildCodeBasedStep() {
      if(code === ''){
          enqueueSnackbar("You haven't written any code, please write some or leave a comment in the code to show the intended use of the step", {'variant': 'warning'});
      }
      else {
          let payload = {
              'workflow_id': props.workflow_id,
              'name': name,
              'description': description,
              'execution_type': executionType,
              'execution_code': code,
              'cooldown_time': cooldownTime,
              'tag': tag,
              'events': [],
          }
         createStep(payload);
      }
  } 
  
  
  function createStep(payload: any) {
      webHelpers.put('/api/workflow/step', 'helios-api', environment, payload, token, (data:any) => {
          if(data.response === 400){
              enqueueSnackbar('Unable to create step', {'variant': 'error'});
          }
          else {
              setOpen(false);
          }
      })
  }
  
  
   function submitStepCreation() {
       if(name !== '' && description !== '') {
          // set up payload, sanitise data further and prep for API call 
           switch(executionType){
               case 'CodeBased': 
                  buildCodeBasedStep();
                  break;
               case 'TwilioTask':
                   buildTwilioStep();
                   break;
               default:
                   enqueueSnackbar('Not sure how you managed that one...', {'variant': 'warning'});
           }
       }
   }
   
    return(
    <>
        <Button sx={{height: '2.5rem', alignSelf: 'center'}} variant='outlined' onClick={() => setOpen(true)}>
             Add Step
        </Button>
        <Dialog  open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Create New Step</DialogTitle>
            <DialogContent
                sx={{display: 'flex', flexDirection: 'column'}} 
            >
                <DialogContentText>
                    This step will be added after the existing steps
                </DialogContentText>
                <TextField
                    autoFocus
                    onChange={handleNameChange}
                    margin="dense"
                    id="name"
                    label="Name"
                    type="text"
                    variant="standard"
                />
                <TextField
                    margin="dense"
                    id="description"
                    multiline={true}
                    onChange={handleDescriptionChange}
                    label="Description"
                    type="text"
                    variant="standard"
                />
                <TextField
                    margin="dense"
                    id="tag"
                    multiline={true}
                    onChange={handleTagChange}
                    label="Tag"
                    type="text"
                    variant="standard"
                />
                <div className="label-container" style={{paddingTop:'1rem'}}>
                    <p className='label-container-text'>Cooldown: </p>
                    <DateTimePicker handDateTimeUp={setCooldownTime} days='0' hours='0' minutes='0' seconds='0' />
                </div>
                
                <FormControl className='exec-type'>
                    <FormLabel className='exec-type-label' id="demo-controlled-radio-buttons-group">
                        Execution Type:
                    </FormLabel>
                    <RadioGroup
                        className='exec-type-selector'
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        name="controlled-radio-buttons-group"
                        value={executionType}
                        onChange={handleRadioChange}
                    >
                        <FormControlLabel
                            value="CodeBased"
                            control={<Radio />}
                            label="Code"
                        />
                        <FormControlLabel
                            value="TwilioTask"
                            control={<Radio />}
                            label="Twilio"
                        />
                    </RadioGroup>
                </FormControl>
                {executionType === 'CodeBased' ?
                    (
                        <>
                        <StepCodeEditor code={code} setCode={setCode} />
                        </>
                    ) : (
                        <>
                            <TextField
                                margin="dense"
                                id="twilioSid"
                                onChange={handleTwilioSidChange}
                                label="TwilioSid"
                                type="text"
                                variant="standard"
                            />
                            <TextField
                                margin="dense"
                                id="tag"
                                onChange={handleTwilioTaskTypeChange}
                                label="Twilio Task Type"
                                type="twilioTaskType"
                                variant="standard"
                            />         
                        </>
                    )
                } 
                
                
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={() => submitStepCreation()}>Create</Button>
            </DialogActions>
        </Dialog> 
    </>)
}

export default CreateStep;