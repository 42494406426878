import * as React from 'react';
import {useState} from 'react';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AceEditor from "react-ace";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogActions} from "@mui/material";
import {TransitionProps} from "@mui/material/transitions";
import Slide from "@mui/material/Slide";

interface IEventSubscriberCodeEditorComponent{
   cb: Function ,
}
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});



function EventSubscriberCodeEditor(props: IEventSubscriberCodeEditorComponent ){
   const [open, setOpen] = useState(false);
   const [code, setCode] = useState('');
   const [initialCode, setInitialCode]= useState('');
   const [unsavedCode, setUnsavedCode] = useState(false);
   
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if(code !== initialCode) {
            setUnsavedCode(true);
        }
        else {
            setOpen(false);
        }
    };
    const handleCancel  = () => {
        setUnsavedCode(false);
    } 
    
    
    const handleSave = () => {
        setOpen(false);
        setUnsavedCode(false);
        props.cb(code);
        setInitialCode(code);
    }
    
    return(<>
        <Button variant="outlined" onClick={handleClickOpen}>
        Edit Code
        </Button>
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
                <Toolbar sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Button autoFocus color="inherit" onClick={handleSave}>
                        Save
                    </Button>
                </Toolbar>
                <AceEditor
                    style={{
                        height: "95vh",
                        width: "100%",
                    }}
                    theme="gruvbox"
                    placeholder={initialCode}
                    mode="csharp"
                    name="Script for step"
                    onChange={(currentCode) => setCode(currentCode)}
                    fontSize={18}
                    showPrintMargin={true}
                    showGutter={true}
                    highlightActiveLine={true}
                    value={code}
                    enableBasicAutocompletion={true}
                    tabSize={4}
                />
            </AppBar>
        </Dialog>

        <Dialog
            open={unsavedCode}
            onClose={handleCancel}
            TransitionComponent={Transition}
            sx={{padding: '2rem' }}
        >
            <DialogTitle>Wait!</DialogTitle>
            <p style={{padding: '1rem'}}> You have made changes to the code which haven't been saved,
                if you exit now you'll lose any changes made!</p>
            <DialogActions>
                <Button onClick={() => setUnsavedCode(false)}>Continue Coding</Button>
                <Button onClick={handleSave}>Save Changes</Button>
                <Button onClick={() => {
                    setOpen(false);
                    setUnsavedCode(false);
                }}>Discard Changes</Button>
            </DialogActions>
        </Dialog>
    </>)
}

export default EventSubscriberCodeEditor;