import * as React from 'react';
import {useState, useEffect} from 'react';
import {useAppSelector} from '../../app/hooks';
import {Table, TableHead, TableCell, TableBody, TableFooter, TableRow} from '@mui/material';
import webHelpers from '../../scripts/helpers';
import CustomTablePagination from '../pagination-buttons/pagination-buttons.component';
import {Detail, Skill} from '../../assets/global-interfaces/role-management.interfaces';
import {useSnackbar} from 'notistack';
import SkillsTableRow from './skill-management/skill-table-row.component';
import {Level} from '../../assets/global-interfaces/role-management.interfaces';
import SearchBar from '../search-bar/search-bar.component';

interface ChooseSkillsProps {
    handleAddedSkills: Function,
}

export default function ChooseSkills(props: ChooseSkillsProps) {
    const [page, setPage] = useState(0);
    const [allSkills, setAllSkills] = useState<Skill[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [maxPages, setMaxPages] = useState((allSkills.length / rowsPerPage) -1 );
    const token = useAppSelector(state => state.loggedIn.token)
    const environment = useAppSelector(state => state.loggedIn.environment)

    const {enqueueSnackbar} = useSnackbar();
    const prevPage = () => {
        if (page === 0) {
            enqueueSnackbar('Already on the first page!', {'variant': 'error'});
        } else {
            setPage(page - 1);
        }
    }
    const nextPage = () => {
        if (page == maxPages-1) {
            enqueueSnackbar('On last page already!', {'variant': 'error'})
        } else {
            setPage(page + 1);
        }
    }

    const emptyRows =
        (rowsPerPage - allSkills.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length)

    useEffect(() => {
        if(allSkills.length === 0) {
            webHelpers.get('/api/skills/definitions', environment, 'zeus-api', token, (data: any) => {
                setAllSkills(data)
            });
        }
        
    }, [])
    return (
        <>
            <Table sx={{minWidth: '100%'}} aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left"><strong>Skill</strong></TableCell>
                        <TableCell align="left"></TableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {allSkills  && allSkills
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((skill: Skill) => (
                            <SkillsTableRow key={skill.id}
                                       id={skill.id} 
                                       name={skill.name} 
                                       levels={skill.levels} 
                                       handleAddedSkills={props.handleAddedSkills}/>
                        ))
                    }
                    {emptyRows > 0 && (
                        <TableRow
                            style={{
                                height: 61 * emptyRows,
                            }}
                        >
                            <TableCell colSpan={6}/>
                        </TableRow>
                    )}

                </TableBody>
                <TableFooter>
                    <TableRow>

                        <CustomTablePagination
                            goFirst={() => setPage(0)}
                            onPrev={prevPage}
                            onNext={nextPage}
                            goLast={() => setPage(maxPages-1)}
                        />

                    </TableRow>
                </TableFooter>
            </Table>
        </>)
}