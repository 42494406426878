import * as React from 'react';
import ErrorBoundary from '../../components/error-boundary/error-boundary.component';
import Card from '../../components/card/card.component';
import GroupsTable from '../../components/groups-table/groups-table.component';

const Groups = () => {

        return (
            <ErrorBoundary>
                <Card>
                    <GroupsTable  />
                </Card>
            </ErrorBoundary>
        )
};

export default Groups;