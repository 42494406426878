import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';



// Defining type for state
interface LoggedInState {
    value: boolean;
    token: string;
    user_name: string,
    environment: string,
};

// define the initial state using that type
const initialState: LoggedInState = {
    value: false,
    token: '',
    user_name: '',
    environment: 'live',
}

export const loggedInSlice = createSlice({
    name: 'loggedIn',
    // createSlice will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        toggle: (state) => {
            state.value = !state.value
        },
        saveToken: (state, action: PayloadAction<string>) =>{
            state.token = action.payload
        },
        updateUser: (state, action:PayloadAction<string>) => {
            state.user_name = action.payload
        },
        updateEnvironment: (state, action:PayloadAction<string>) => {
            state.environment = action.payload
        }
    },
});



export const { toggle, saveToken, updateUser, updateEnvironment } = loggedInSlice.actions;

export const selectLoggedIn = (state: RootState) => state.loggedIn.value;

export default loggedInSlice.reducer;
