import * as React from "react";
import { TableRow, TableCell } from "@mui/material";
import BlockedNumbersDeleteDialog from '../blocked-numbers-delete-dialog/blocked-numbers-delete-dialog.component';


interface BlockedNumberProps {
  id: string;
  reason: string;
  type: number;
  createdByName: string;
  expires: string;
  cb:Function;
}

function BlockedNumber(props: BlockedNumberProps) {


    function sanitiseType(type: number) {
        switch(type){
            case 0:
                return 'Whole Number';
            case 1:
                return 'Starts With';
            case 2:
                return 'Ends With';
            default:
                return 'No type';
        }
    }

    function sanitiseDate(date: string){
        let newString = "Date: " + date.replace('T', ' Time: ')
        return newString
    }
  return (
    <>
      <TableRow key={props.id}>
        <TableCell
          style={{ width: "15%", paddingLeft: 10 }}
          component="th"
          scope="row"
          align="left"
        >
          <p>{props.id}</p>
        </TableCell>
        <TableCell style={{ width: "1fr", padding:'none' }} align="left" >
          <p>{sanitiseType(props.type)}</p>
        </TableCell>
        <TableCell style={{ width: "25%", padding:'none' }} align="left">
          <p>{props.reason}</p>
        </TableCell>
        <TableCell style={{ width: "1fr", padding:'none' }} align="left">
          <p>{props.createdByName}</p>
        </TableCell>
        <TableCell style={{ width: "1fr", padding:'none' }} align="left">
          <p>{sanitiseDate(props.expires)}</p>
        </TableCell>
        <TableCell style={{ inlineSize: ".5rem", padding:'none' }} align="right">
          <BlockedNumbersDeleteDialog id={props.id} cb={props.cb} type={props.type} />
        </TableCell>
      </TableRow>
    </>
  );
}

export default BlockedNumber;