import * as React from 'react';
import { useState, useEffect } from 'react';
import '../role-management.styles.scss';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import RoleStepper from './create-role-stepper.component';
import webHelpers from '../../../scripts/helpers';
import { useAppSelector } from '../../../app/hooks';
import { useSnackbar } from 'notistack';

interface ICreateRoleProps {
    worker_id: string,
    cb: Function,
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '55%',
    height: '75vh',
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
    overflow: 'hidden',
};

export default function CreateRole(props: ICreateRoleProps) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        props.cb();
        setOpen(false);
    };
    const [skills, setSkills] = useState();
    const [outboundNumbers, setOutboundNumbers] = useState();

    const environment = useAppSelector(state => state.loggedIn.environment)
    const token = useAppSelector(state => state.loggedIn.token)
    const { enqueueSnackbar } = useSnackbar();



    useEffect(() => {
        if(open) {
            webHelpers.get('/api/skills/definitions', environment, 'zeus-api', token, (data: any) => {
                setSkills(data)
            });
            webHelpers.get('/api/hosted/outboundnumbers', environment, 'zeus-api', token, (data: any) => {
                if (data != undefined && data != null) {
                    setOutboundNumbers(data)
                    console.log(data)
                }
                else {
                    enqueueSnackbar(`${data.status}: unable to fetch outbound numbers`)
                }
            })
        }
    }, [open])

    return (
        <div>
            <Button variant='outlined' startIcon={<AddIcon />} onClick={handleOpen}>Create Role</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="modal-header">
                        <Typography id="modal-modal-title" variant="h5" component="h4" sx={{ p: 1 }}>
                            New Role
                        </Typography>
                        <div className="modal-header-close">
                            <CloseIcon onClick={handleClose} />
                        </div>
                    </div>


                    <div className="modal-stepper">
                        {skills !== undefined && outboundNumbers !== undefined ?
                            (<RoleStepper skills={skills} outbound_numbers={outboundNumbers} worker_id={props.worker_id} cb={handleClose} />)
                            :
                            (
                                <p>there was some error fetching skills...</p>
                            )
                        }


                    </div>

                </Box>
            </Modal>
        </div>
    );
}