import { TableCell, TableRow } from "@mui/material";
import * as React from "react";
import { IWorkflow } from "./workflows-table.component";
import WorkflowModal from "./edit-workflow-modal.component";
export default function WorkflowRow(props: IWorkflow) {
  return(
  <>
    <TableRow key={props.id} hover role="checkbox" sx={{ maxHeight:'3rem'}}>
      <TableCell
        style={{ width: "35%", paddingLeft: 10 }}
        component="th"
        scope="row"
        align="left"
      >
        <p>{props.name}</p>
      </TableCell>
      <TableCell style={{ width: "1fr" }} align="left">
        <p>{props.id}</p>
      </TableCell>
      <TableCell style={{ width: "1fr" }} align="left">
        <p>{props.description}</p>
      </TableCell>
      <TableCell style={{ inlineSize: ".5rem" }} align="right">
        <WorkflowModal id={props.id} initializer_code_id={props.initializer_code_id} />
      </TableCell>
    </TableRow>
  </>)
}
