import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import {Stack, Button} from '@mui/material';

interface IEventButtonProps {
    id: string,
    cb:Function,
}

function EventButton(props: IEventButtonProps) {
   return(
       <>
        <Stack direction='row'  spacing={2} sx={{paddingTop: '0.5rem'}}>
            <Button sx={{width: '100%'}} variant='outlined' onClick={() => props.cb(props.id)} startIcon={<AddIcon />}>{props.id}</Button>
        </Stack>
       </>
   ) 
}
export default EventButton;