import * as React from 'react';
import { useState, useEffect } from 'react';
import Header from '../header/header.component';
import NavigationBar from '../navigation-bar/navigation-bar.component';
import './dashboard.styles.scss';
import SpeedDialTooltipOpen from '../icon-menu/speed-dial.component';
import { store } from '../../app/store';
import PageContainer from '../page-container/page-container.component';
import Grow from '@mui/material/Grow';
import Fade from '@mui/material/Fade';

// dashboard component acts as a container for the main applications routing and framing components.
// this will contain the header, navigation, and pages that are dynamically rendered.
// Page is split into left and right containers for the navigation and header/content respectively


function Dashboard() {
    const [page, setPage] = useState('Home');
    const [user, setUser] = useState(store.getState().loggedIn.user_name);

    const [isTablet, setTablet] = useState(window.innerWidth < 900);

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const handleLoginChange = store.subscribe(() => {
        let previousValue = isLoggedIn;
        let currentValue = store.getState();
        if (previousValue !== currentValue.loggedIn.value) {
            setIsLoggedIn(currentValue.loggedIn.value)
        }
    });

    const handleUpdateUser = store.subscribe(() => {
        let previousValue = user;
        let currentValue = store.getState();
        if (previousValue !== currentValue.loggedIn.user_name) {
            setUser(currentValue.loggedIn.user_name);
        }
    });

    // const loggedIn = store.subscribe(handleLoginChange);
    // const userName = store.subscribe(handleUpdateUser);
    const handlePageChange = (page: string) => {
        setPage(page);
    };

    const updateMedia = () => {
        setTablet(window.innerWidth < 900);
    };
    useEffect(() => {
        window.addEventListener("resize", updateMedia);
        return () => window.removeEventListener("resize", updateMedia);
    });

    return (

        <>
            {isLoggedIn ? (
                <>
                    <Fade in={true}>
                        <div className="dashboard-container">
                            <div className="left-panel">
                                <NavigationBar />
                            </div>
                            <span className="right-panel">

                                {isTablet ? (
                                    <div className="tablet">
                                        <div className="tablet-page">
                                            <div className="padding-spacer">
                                                <PageContainer onChange={handlePageChange} />
                                            </div>
                                        </div>
                                        <div className="tablet-nav">
                                            <SpeedDialTooltipOpen />
                                        </div>
                                    </div>


                                ) : (
                                    <>
                                        {/*     Data like username and icon will be loaded dynamically by users */}
                                        <Header page={page} userName={user} />

                                        <PageContainer onChange={handlePageChange} />

                                    </>
                                )}
                            </span>
                        </div>
                    </Fade>
                </>
            ) : (

                <>
                    <Grow in={!isLoggedIn}>
                        <div className="padding-spacer">
                            <PageContainer onChange={handlePageChange} />
                        </div>
                    </Grow>
                </>
            )}
        </>
    )
}

export default Dashboard;