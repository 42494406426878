import * as React from "react";
import { useState, useEffect } from "react";
import { IWorkflowStep } from "../workflows-table-search/edit-workflow-modal.component";
import {
  Button, TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel,
  Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { useSnackbar } from 'notistack';
import webHelpers from "../../scripts/helpers";
import CodeEditorDialog from "../code-editor-dialog/code-editor-dialog.component";
import WorkflowStepEventsDialogComponent from "../workflow-step-events-dialog/workflow-step-events-dialog.component";
import "./workflow-step-panel.styles.scss";
import "ace-builds/src-noconflict/mode-csharp";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-gruvbox";
import "ace-builds/src-noconflict/theme-dracula";
import DateTimePicker from "../date-time-picker/date-time-picker.component";
import TagEditor from "../workflow-step-events-dialog/tag-editor-dialog.component";
import TimeProfileSelector from "../time-profile-modal/time-profile-selector";
let longtime = false;
export default function StepPanel(props: IWorkflowStep) {
  const [name, setName] = useState(props.name);
  const [description, setDescription] = useState(props.description);
  const [stepId, setStepId] = useState<string>(props.id);
  const [version, setVersion] = useState<number>(0);
  const [code, setCode] = useState("");
  const [tag, setTag] = useState<string | null>(props.tag || null);
  const [events, setEvents] = useState(props.events);
  const [twilioTaskTypeKey, setTwilioTaskTypeKey] = useState<string | null>(props.twilio_task_type_key);
  const [twilioTaskType, setTwilioTaskType] = useState<string | null>(props.twilio_task_type);
  const [executionType, setExecutionType] = useState(props.execution_type);
  const [dateTime, setDateTime] = useState<string[]>(props.cooldown_time.split(':'));
  const [dateTimeString, setDateTimeString] = useState(props.cooldown_time);
  const [saveRequested, setSaveRequested] = useState(false);
  const [selected_profile, setProfile] = useState<string>(props.time_profile_id);

  const token = useAppSelector((state) => state.loggedIn.token);
  const environment = useAppSelector((state) => state.loggedIn.environment);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setSaveRequested(false);
  }
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setExecutionType((event.target as HTMLInputElement).value);
  };

  const handleNameChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setDescription(e.target.value);
  };

  const handleTwilioTaskTypeKeyChange = (e: { target: { value: React.SetStateAction<string | null>; }; }) => {
    setTwilioTaskTypeKey(e.target.value);
  }
  const handleTwilioTaskTypeChange = (e: { target: { value: React.SetStateAction<string | null>; }; }) => {
    setTwilioTaskType(e.target.value);
  }

  function handleDateTimeChange(dateTimeString: string) {
    setDateTimeString(dateTimeString);
  }


  // checking if the user modified any fields 
  function changesMade() {
    let changed = false;
    if (name != props.name) {
      changed = true;
    }
    if (description != props.description) {
      changed = true;
    }
    try {
      const test_arr = props.cooldown_time.split(':');
      dateTime.forEach((item: string, index: number) => {
        if (test_arr[index] != item) {
          changed = true
        }
      })
    }
    catch (e) {
      console.log(e)
    }
    if (selected_profile !== props.time_profile_id){
      changed=true;
    }
    if (dateTimeString !== props.cooldown_time) {
      changed = true;
    }
    if (tag !== props.tag) {
      changed = true;
    }

    if (executionType == 'TwilioTask') {
      if (twilioTaskType != props.twilio_task_type) {
        changed = true;
      }
      if (twilioTaskTypeKey != props.twilio_task_type_key) {
        changed = true;
      }
    }
    return changed;
  }

  const handleSave = () => {
    // sanitise the data to ensure it's changed 
    if (changesMade()) {
      // display a dialog showing the changes made of props vs. hooks value
      setSaveRequested(true);
    }
    else {
      enqueueSnackbar("You haven't made any changes!", { 'variant': 'warning' });
    }
  }

  const saveChanges = () => {
    // api call to update info 
    let oldProfile = props;
    console.log(oldProfile);
    let payload: object;
    if (executionType == 'CodeBased') {
      payload = {
        'id': oldProfile.id,
        'name': name,
        'description': description,
        'execution_type': executionType,
        "time_profile_id": selected_profile,  
        'tag': tag,
        'cooldown_time': dateTimeString,
      }
    }
    else {
      payload = {
        'id': oldProfile.id,
        'name': name,
        'description': description,
        'execution_type': executionType,
        'twilio_task': {
          'echo_interaction_type': twilioTaskType,
          'echo_interaction_type_key': twilioTaskTypeKey,
        },
        "time_profile_id": selected_profile,
        'tag': tag,
        'cooldown_time': dateTimeString,
      }
    }
    if (payload) {
      console.log(payload)
      webHelpers.post('/api/workflow/step/' + oldProfile.id, environment, 'helios-api', payload, token, (data: any) => {
        if (data.status === 400 || data.status === 500) {
          enqueueSnackbar('Unable to process this update request', { 'variant': 'error' });
        }
        else {
          console.log(data);
          enqueueSnackbar('Step successfully updated', { 'variant': 'success' });
          setSaveRequested(false);
        }
      })
    }
  }

  function compareDateTimeToOld(datetime: string) {
    return datetime === props.cooldown_time;
  }

  function getCode(id: string) {
    if (props.code_id !== null || props.code_id !== 'null') {
      webHelpers.get(
        "/api/code/" + props.code_id,
        environment,
        "helios",
        token,
        (data: any) => {
          if (id === props.id) {
            if (data === undefined || data === null) {
              //
              setCode('');
              console.log('no code found')
            } else {
              setCode(data.code);
              setVersion(data.version);
            }
          }
          else {
            console.log('durr')
          }
        }
      );
    }
  }

  useEffect(() => {
    if (props.id !== stepId) {
      longtime = false;
      let timeSplit = props.cooldown_time.split(':');
      if (timeSplit[0].includes('.')) {
        longtime = true;
        let longTimeSplit = timeSplit[0].split('.');
        timeSplit.forEach((time: string) => {
          longTimeSplit.push(time)
        });
        setDateTime(longTimeSplit);
      }
      else {
        setDateTime(timeSplit);
      }

      setStepId(props.id);
      setName(props.name);
      setDescription(props.description);
      setDateTimeString(props.cooldown_time);
      setTwilioTaskTypeKey(props.twilio_task_type_key);
      setTwilioTaskType(props.twilio_task_type);
      setExecutionType(props.execution_type);
      setTag(props.tag)
      if (props.events.length > 0) {
        setEvents(props.events);
      }
      if (props.execution_type == 'CodeBased') {
        setCode('');
        getCode(props.id);
      }
    }
  },);
  const handleTimeProfileChange = (newName: string) => {
    setProfile(newName);
    console.log("new profile is ", selected_profile);

  }
  useEffect(() => {
    console.log("props =", props.time_profile_id);
    console.log("local var = ", selected_profile);
  },[selected_profile]);
  return (
    <>
      <div className="label-container">
        <p className='label-container-text'>Name:</p>
        <TextField
          size='small'
          placeholder={props.name}
          value={name}
          onChange={handleNameChange}
        />
      </div>

      <div className="label-container">
        <p className='label-container-text'>Description:</p>
        <TextField
          sx={{ width: '55%' }}
          size='small'
          multiline={true}
          placeholder={props.description}
          value={description}
          onChange={handleDescriptionChange}
        />
      </div>
      <div className="label-container">
        <p className='label-container-text'></p>
        <WorkflowStepEventsDialogComponent events={props.events} step_id={stepId} />
        <TagEditor tag={tag} cb={setTag} />
      </div>
      <div className="label-container">
        <p className='label-container-text'>Cooldown: </p>
        {longtime ? (
          <DateTimePicker key={dateTime[1]} dateTime={dateTime} days={dateTime[0]} hours={dateTime[1]} minutes={dateTime[2]} seconds={dateTime[3]} handDateTimeUp={handleDateTimeChange} />
        ) : (
          <DateTimePicker key={dateTime[1]} dateTime={dateTime} hours={dateTime[0]} minutes={dateTime[1]} seconds={dateTime[2]} handDateTimeUp={handleDateTimeChange} />
        )}
      </div>
      <div className="test">
        <div className="test-two">
        <FormControl className='exec-type'>
          <FormLabel className='exec-type-label' id="demo-controlled-radio-buttons-group">
            Execution Type:
          </FormLabel>
          <RadioGroup
            className='exec-type-selector'
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={executionType}
            onChange={handleChange}
          >
            <FormControlLabel
              value="CodeBased"
              control={<Radio />}
              label="Code"
            />
            <FormControlLabel
              value="TwilioTask"
              control={<Radio />}
              label="Twilio"
            />
          </RadioGroup>
        </FormControl>
        </div>
        {/* Time Profile Picker goes here */}
        <div className="time-profile-selector">
          <TimeProfileSelector default_value={selected_profile} cb={handleTimeProfileChange}/>
        </div>
      </div>
      <Divider sx={{ marginBottom: '1rem' }} />
      <div className="step-panel">
        {executionType === "CodeBased" ? (
          <>
            <CodeEditorDialog code_id={props.code_id} stepFriendlyName={name} buttonText="Edit Code" />
          </>
        ) : (
          <>
            <div className="twilio-container">
              <div className="label-container">
                <p className='label-container-text' >Twilio Task Type Key:</p>
                <TextField
                  sx={{ width: '12rem' }}
                  size='small'
                  placeholder={props.twilio_task_type_key === null ? 'no value' : props.twilio_task_type_key}
                  value={twilioTaskTypeKey}
                  onChange={handleTwilioTaskTypeKeyChange}
                />
              </div>
              <div className="label-container">
                <p className='label-container-text'>Twilio Task Type:</p>
                <TextField
                  sx={{ width: '12rem' }}
                  size='small'
                  placeholder={props.twilio_task_type === null ? 'no value' : props.twilio_task_type}
                  value={twilioTaskType}
                  onChange={handleTwilioTaskTypeChange}
                />
              </div>
            </div>
          </>
        )}
        <div className="save-changes-container">
          <Button variant="contained" color='success' onClick={handleSave}>Save Changes</Button>
        </div>
      </div>
      <Dialog
        open={saveRequested}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ display: 'flex', flexDirection: 'column' }}
      >
        <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', justifyContent: 'center' }}>
          Save changes?
        </DialogTitle>
        <DialogContent>
          {/* 


 
                    syntax lords forgive me for i have given you eye cancer 
                    this dialog context text element just renders any changes a user makes on a step, it looks very terse but it's fairly straight forward 
                    */}
          <DialogContentText id="alert-dialog-description" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {name !== props.name ? <p> Name: <em>"{props.name}"</em> {'\u2192'} <strong>"{name}"</strong></p> : (<></>)}
            {tag !== props.tag ? <p> Tag: <em>"{props.tag}"</em> {'\u2192'} <strong>"{tag}"</strong></p> : (<></>)}
            {description !== props.description ? <p className='description-changes-text'>Description: <em>"{props.description}"</em> {'\u2192'} <strong>{'\n'} "{description}"</strong></p> : (<></>)}
            {compareDateTimeToOld(dateTimeString) ? <></> : <p>Cooldown Time: <em>"{props.cooldown_time}"</em> {'\u2192'} <strong>{'\n'} "{dateTimeString}"</strong></p>}
            {twilioTaskTypeKey !== props.twilio_task_type_key ? <p>Twilio Task Type Key: <em>"{props.twilio_task_type_key}"</em> {'\u2192'} <strong>"{twilioTaskTypeKey}"</strong></p> : (<></>)}
            {twilioTaskType !== props.twilio_task_type ? <p>Twilio Task Type: <em>"{props.twilio_task_type}"</em> {'\u2192'} <strong>{'\n'} "{twilioTaskType}"</strong></p> : (<></>)}
            {executionType !== props.execution_type ? (<p>Execution Type:<em>{props.execution_type}</em> {'\u2192'}<strong>{'\t'}{executionType}</strong></p>) : (<></>)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant='outlined' onClick={handleClose}>Discard</Button>
          <Button variant='contained' onClick={saveChanges} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>

    </>
  );
}
