import * as React from 'react';

import { useState, useEffect } from 'react';
import { useAppSelector } from '../../app/hooks';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import { TextField } from '@mui/material';
import Transition from '../../assets/animations/slide.transition';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
interface CloneRoleDialogProps {
    cloneRoleFunction: Function;
}

function CloneRoleDialog(props: CloneRoleDialogProps) {
    const [open, setOpen] = useState(false);
    const [roleName, setRoleName] = useState('');

    const handleClickOpen = () => setOpen(true)
    const handleNameChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setRoleName(e.target.value);
    }
    const handleClose = () => setOpen(false)

    const handleClone = () => {
        props.cloneRoleFunction(roleName);
        setOpen(false);
    }

    return (
        <div>
            <Button variant='contained' endIcon={<PersonOutlineIcon />} onClick={handleClickOpen}>Clone</Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Enter cloned role name"}</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="normal"
                        id="role_name"
                        label="Role Name"
                        type="text"
                        autoComplete='off'
                        fullWidth
                        variant="standard"
                        onChange={handleNameChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClone}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CloneRoleDialog;