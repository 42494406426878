import * as React from 'react';
import { useState } from 'react';
import {
    Table, TableBody, TableCell, TableFooter,
    TableRow, TableHead, TablePagination, TableContainer,
    InputLabel,List,MenuItem,Select,SelectChangeEvent,
} from '@mui/material';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FormGroup, Box, Divider } from '@mui/material';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Transition from '../../assets/animations/slide.transition';
import './create-user-dialog.styles.scss';
import webHelpers from '../../scripts/helpers';
import { useAppSelector } from '../../app/hooks';

interface IUserTypes {
    id: string,
    name: string,
}

const CreateUserDialog = () => {
    const [open, setOpen] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [department, setDepartment] = useState('');
    const [forcePassword, setForcePassword] = useState(false);
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");
    
    const [userTypes, setUserTypes ] = useState<IUserTypes[]>([]);

    const handleClickOpen = () => {
        getUserTypes();
        setOpen(true);
    }
    const environment = useAppSelector(state => state.loggedIn.environment)
    const token = useAppSelector(state => state.loggedIn.token)
    const handleClose = () => setOpen(false);

    const handleForcePasswordChange = () => setForcePassword(!forcePassword);

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();

        let modifiedFirstName = firstName.toLowerCase()
        modifiedFirstName = modifiedFirstName.charAt(0).toUpperCase() + modifiedFirstName.slice(1)

        let modifiedLastName = lastName.toLowerCase()
        modifiedLastName = lastName.charAt(0).toUpperCase() + modifiedLastName.slice(1);

        const account_id = modifiedFirstName + '.' + modifiedLastName;
        const payload = {
            'user_type_id': "803130a7-7a5c-43d3-b850-bf8ecba20cd9",
            'account_id': account_id,
            'department': department,
            'email_address': email,
            'first_name': firstName,
            'force_password_change': forcePassword,
            'last_name': lastName,
            'password': password,
        }
        webHelpers.put('/api/management/user', 'auth', environment, payload, token, (data: any) => {
            console.log(data);
        })

        setOpen(false);
    }
    const handleProfileChange = (event: SelectChangeEvent) => {
        setSelectedType(event.target.value);

    }
    function getUserTypes() {
        webHelpers.get('/api/usertypes', environment, 'auth', token, (data: any) => {
                console.log("usertypes", data);
                setUserTypes(data);
                 }
        );
    }


    const handleFirstNameChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setFirstName(e.target.value);
        
    }
    const handleLastNameChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setLastName(e.target.value);
    }
    const handleEmailChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setEmail(e.target.value);
    }
    const handlePasswordChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setPassword(e.target.value);
    }
    const handleDepartmentChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setDepartment(e.target.value);
    }


    return (
        
        <div>
            
            <Button variant="outlined" endIcon={<GroupAddIcon />} onClick={handleClickOpen}>
                Create a User
            </Button>
            <Dialog open={open}  onClose={handleClose} TransitionComponent={Transition}>
                <DialogTitle>New User</DialogTitle>
                <Divider />
                <DialogContent>
                
                    <DialogContentText>
                        Please enter the form, ensuring all fields are correct, before submitting.
                    </DialogContentText>
                    <FormGroup className='create-user-form' >

                        <TextField
                            autoFocus
                            margin="normal"
                            id="first name"
                            label="First Name"
                            type="text"
                            autoComplete='off'
                            variant="standard"
                            fullWidth
                            onChange={handleFirstNameChange}
                        />
                        <TextField
                            autoFocus
                            margin="normal"
                            id="last name"
                            label="Last Name"
                            type="text"
                            autoComplete='off'
                            fullWidth
                            variant="standard"
                            onChange={handleLastNameChange}
                        />
                        <TextField
                            autoFocus
                            margin="normal"
                            id="email"
                            label="Email"
                            type="email"
                            autoComplete='off'
                            fullWidth
                            variant="standard"
                            onChange={handleEmailChange}
                        />
                        <TextField
                            autoFocus
                            margin="normal"
                            id="password"
                            label="Password"
                            type="password"
                            fullWidth
                            autoComplete='off'
                            variant="standard"
                            onChange={handlePasswordChange}
                        />
                        <TextField
                            autoFocus
                            margin="normal"
                            id="department"
                            label="Department"
                            type="text"
                            autoComplete='off'
                            fullWidth
                            variant="standard"
                            onChange={handleDepartmentChange}
                        />
                        <br />
                        <FormControlLabel control={<Radio
                            checked={forcePassword}
                            onClick={handleForcePasswordChange}
                            value="b"
                            name="radio-buttons"

                            inputProps={{ 'aria-label': 'Security' }}
                        />} label="Force Password Change" />
                        <br />
                        <Divider />

                        <DialogActions className='create-user-form-buttons'>
                            <Button onClick={handleClose}>Cancel</Button>
                            <Button type='submit' onClick={handleSubmit}>Submit</Button>
                        </DialogActions>



                    </FormGroup>
                </DialogContent>

            </Dialog>
        </div>
    );
}

export default CreateUserDialog;