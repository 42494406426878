import { TableCell, TableRow } from "@mui/material";
import * as React from "react";
import TimeProfileEditModal from "./time-profile-modal-edit";
import { ITimeProfile } from "./time-profile-table";
export default function TimeProfileRow(props: ITimeProfile) {
  return(
  <>
    <TableRow key={props.id} hover role="checkbox" sx={{ maxHeight:'3rem'}}>
      <TableCell
        style={{ width: "35%", paddingLeft: 10 }}
        component="th"
        scope="row"
        align="left"
      >
        <p>{props.name}</p>
      </TableCell>
      <TableCell style={{ width: "1fr" }} align="left">
        <p>{props.id}</p>
      </TableCell>
      <TableCell style={{ width: "1fr" }} align="left">
        <p>{props.is_bank_holiday_aware}</p>
      </TableCell>
      <TableCell style={{ inlineSize: ".5rem" }} align="right">
        <TimeProfileEditModal {...props}/>
      </TableCell>
    </TableRow>
  </>)
}
