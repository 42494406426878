import { getEnvironmentData } from 'worker_threads';
class Webhelpers {

    get(url: string, environment: string, endpoint: string, token: string, cb: Function) {
        var data = {
            url: url,
            endpoint: endpoint,
            environment: environment,
            method: 'GET',
            data: {}
        }

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(data)
        };

        fetch('/api/proxy', requestOptions)
            .then(response => response.json())
            .then(data => cb(data));
    }

    post(url: string, environment: string, endpoint: string, data: any, token: string, cb: Function) {
        var d = {
            url: url,
            endpoint: endpoint,
            environment: environment,
            method: 'POST',
            data: data
        }

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(d)
        };

        fetch('/api/proxy', requestOptions)
            .then(response => {
                let isJson = response.headers.get('content-type')?.includes('application/json');
                let data = isJson ? response.json() : response;
                return data;
            })
            .then(data => cb(data))
            .catch(e => {
                alert(e);
            });
    }

    put(url: string, endpoint: string, environment: string, data: any, token: string, cb: Function) {
        var d = {
            url: url,
            endpoint: endpoint,
            environment: environment,
            method: 'PUT',
            data: data
        }

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(d)
        };

        fetch('/api/proxy', requestOptions)
            .then(response => response.json())
            .then(data => cb(data));
    }

    delete(url: string, endpoint: string, environment: string, token: string, cb: Function) {
        var data = {
            url: url,
            endpoint: endpoint,
            environment: environment,
            method: 'DELETE',
            data: {}
        }

        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token },
            body: JSON.stringify(data)
        };

        fetch('/api/proxy', requestOptions)
            .then(response => cb(response));
    }
}

var webHelpers = new Webhelpers();


export default webHelpers;