import * as React from 'react';
// import Card from '../../components/card/card.component';
import SignIn from '../../components/sign-in/sign-in.component';
import './sign-in.styles.scss';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
export default function SignInPage() {
    return (
        <div className="sign-in-page">
            <Card>
                <CardContent>
                    <SignIn />
                </CardContent>
            </Card>
        </div>
    )
};


