import * as React from 'react';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../app/hooks';
import webHelpers from '../../../scripts/helpers';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import ChooseSkills from '../choose-skills.component';
import { useSnackbar } from 'notistack';
import {Detail, Skill, UserRole} from '../../../assets/global-interfaces/role-management.interfaces';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import {useDictionary} from "../../../scripts/useDictionary";
import {ISkill} from "../create-role/create-role-stepper.component";

interface IAddSkillProps {
    role: UserRole,
    handleAddSkills: Function,
    updateSkills: Function,
}

export interface ISkillToAdd {
    skill_id: string,
    level_id: string,
    name: string,
    level_name: string,
}

const style = {
    position: 'absolute' as 'abssolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
};

const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: ISkillToAdd }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}

const AddSkill = (props: IAddSkillProps) => {
    const [currentSkills, setCurrentSkills] = useState(props.role.details);
    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();
    const [open, setOpen] = useState(false);

    const { enqueueSnackbar } = useSnackbar();
    const handleOpen = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const handleSubmit = () => {
        if(Object.values(skills).length > 0) {
            props.handleAddSkills(skills); // Parent component handles API calls for managing all skills
            props.updateSkills();
            setOpen(false);
        }
        else {
            enqueueSnackbar(`no skills added!`)
        }
    }

    function handleAddedSkills(addedSkill : ISkillToAdd) {
            onUpdateSkill(addedSkill.skill_id, addedSkill);
    }
    
    useEffect(() => {
        onClearSkills();
    },[open])

    return (
        <>
            <div>
                <Button onClick={handleOpen}>Add Skill<AddIcon /></Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <div className="modal-header">
                            <Typography id="modal-modal-title" variant="h5" component="h3">
                                Add one or more Skills
                            </Typography>
                            <div className="modal-header-close">
                                <CloseIcon onClick={handleClose} />
                            </div>
                        </div>
                        
                            <Box sx={{ width: '100%', height: '100%', m:5, display: 'flex' }}>
                                <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', margin: '2rem'}}>
                                    <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'column', p: 2, mt: 4, mr: 2, width: '35%' }}>
                                        {Object.values(skills).length > 0  && Object.values(skills).map(skill => (
                                            <Chip key={skill.skill_id} label={skill.name && ': ' && skill.level_name} onDelete={() => onClearSkill(skill.skill_id)} />
                                        ))}
                                    </Stack>
                                    <Divider orientation="vertical" flexItem />
                                    <Stack sx={{ display: 'flex', width: '65%' }}>
                                        <ChooseSkills handleAddedSkills={handleAddedSkills} />
                                    </Stack>
                                </Stack>
                            </Box>
                        <Stack sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Button variant="outlined" endIcon={<SendIcon />} onClick={handleSubmit}>
                                Submit
                            </Button>
                        </Stack>
                    </Box>
                </Modal>
            </div>
        </>)
}

export default AddSkill;