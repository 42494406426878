import * as React from 'react';
import './permissions-management-table.styles.scss';
import { useState, useEffect } from 'react';
import webHelpers from '../../scripts/helpers';
import CustomTablePagination from '../pagination-buttons/pagination-buttons.component';
import { useAppSelector } from '../../app/hooks';
import { useSnackbar } from 'notistack';
import SearchBar from '../search-bar/search-bar.component';
import { IPermissionRegular } from '../../assets/global-interfaces/permission.interfaces';
import {
    Table, TableBody, TableCell, TableFooter,
    TableRow, TableHead
} from '@mui/material';
import Permission from './permission.component';

const dummy_permissions: IPermissionRegular[] = [{ 'id': '/some/permission/here', 'name': 'some dummy permission' }]

const PermissionsTable = () => {
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState<IPermissionRegular[]>(dummy_permissions);
    const [search, setSearch] = useState('');
    const [maxPages, setMaxPages] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(7)
    const { enqueueSnackbar } = useSnackbar();

    let environment = useAppSelector(state => state.loggedIn.environment)
    const token = useAppSelector(state => state.loggedIn.token)

    const prevPage = () => { page === 1 ? (enqueueSnackbar('Already on the first page!', { 'variant': 'error' })) : (setPage(page - 1)) }
    const nextPage = () => { rows.length > rowsPerPage ? (enqueueSnackbar('On last page already!', { 'variant': 'error' })) : (setPage(page + 1)) }

    let height = window.screen.height;

    function searchPermissions(search: string) {
        webHelpers.get('/api/management/permissions/' + rowsPerPage + '/1/' + search, environment, 'auth', token, (data: any) => {
            if (data.status === 200 || data.status === undefined) {
                setRows(Object.values(data.permissions));
                setMaxPages(data.max_pages);
                console.log(data.permissions);
            }
            else {
                enqueueSnackbar(`${data.status}: Unable to find permissions under name ${search}`, { 'variant': 'error' })
            }
        });
    }
    useEffect(() => {
        webHelpers.get('/api/management/permissions/' + rowsPerPage + '/' + page, environment, 'auth', token, (data: any) => {
            if (data.status === 200 || data.status === undefined) {
                setRows(data.permissions);
                setMaxPages(data.max_pages);
            }
            else {
                enqueueSnackbar(`${data.status}: Unable to find permissions under name ${search}`, { 'variant': 'error' })
                setSearch('');
            }
        })
    }, [page, environment, enqueueSnackbar, token, rowsPerPage]);

    useEffect(() => {
        let customTimeout = setTimeout(() => {
            searchPermissions(search)
        }, 500);
        return () => clearTimeout(customTimeout);
    }, [search, rowsPerPage]);

    useEffect(() => {
        if (height >= 1340) {
            setRowsPerPage(12);
        }
        if (height < 1340) {
            setRowsPerPage(7);
        }
    }, [height]);

    const emptyRows = (rowsPerPage - rows.length)

    return (
        <>
            <div className="table-container">
                <div className="table-header">
                    <SearchBar placeholder={'Search Permissions..'} onChange={setSearch} />

                </div>
                <Table sx={{ minWidth: '100%' }} aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" width={'7rem'}><strong>ID</strong></TableCell>
                            <TableCell align="left"><strong>Description</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((permission: IPermissionRegular) => (
                            <Permission permission={permission} cb={() => searchPermissions(search)} />
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 49 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <CustomTablePagination
                                goFirst={() => setPage(1)}
                                onPrev={prevPage}
                                onNext={nextPage}
                                goLast={() => setPage(maxPages)}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>

            </div>
        </>
    )
}

export default PermissionsTable;