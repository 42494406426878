import * as React from 'react';
import { useState, useEffect } from 'react';

import AllGroupsTables from './all-groups-table.component';
import { useAppSelector } from '../../app/hooks';
import webHelpers from '../../scripts/helpers';
import { useSnackbar } from 'notistack';
import { Stack, Chip } from '@mui/material';
import Grow from '@mui/material/Grow';
import List from '@mui/material/List';

interface IGroupsTabProps {
    user_id: string,
    groups: Group[],
    updateGroups: Function,
}
interface Group {
    id: string,
    is_security: boolean,
    name: string,
}

export default function GroupsTab(props: IGroupsTabProps) {
    const [groups, setGroups] = useState(props.groups);
    const [rows, setRows] = useState([]);
    const [id, setId] = useState(props.user_id);

    const { enqueueSnackbar } = useSnackbar();
    const token = useAppSelector(state => state.loggedIn.token)
    const environment = useAppSelector(state => state.loggedIn.environment)


    function handleDelete(group: Group) {
        webHelpers.post('/api/management/group/' + group.id + '/revoke/' + id, environment, 'auth', {}, token, (data: any) => {
            if (data.status === 200) {
                enqueueSnackbar('User removed from group', { 'variant': 'success' });
                updateGroups();
            }
            else {
                enqueueSnackbar(`${data.status}: Unable to remove user from this group.`, { 'variant': 'error' })
            }
        })
    }
    function updateGroups() {
        webHelpers.get('/api/management/user/' + props.user_id + '/groups', environment, 'auth', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log("undefined or null data")
                // Some fallback ui needed here.
            }
            else {
                setGroups(data);
            }
        })
    }

    function updateRows() {
        webHelpers.get('/api/management/groups/7/1', environment, 'auth', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {

            }
            else {
                setRows(data);
            }
        })
    }


    useEffect(() => {
        updateRows()
        updateGroups()
    }, [])

    return (
        <>
            <Grow in={true} mountOnEnter unmountOnExit>
                <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'column', p: 3, mt: 4 }}>
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 360,
                                bgcolor: 'background.paper',
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 475,
                            }}
                        >
                            {groups.length >= 0 ? (
                                groups.map(group => (
                                    <Chip key={group.id} label={group.name} onDelete={() => handleDelete(group)} sx={{ display: 'flex', justifyContent: 'space-between', m: 1}} />
                                ))
                            ) : (
                                groups.map(group => (
                                    <Chip key={group.id} label={group.name} onDelete={() => handleDelete(group)} sx={{ display: 'flex', justifyContent: 'space-between', m: 1 }} />
                                ))
                            )
                            }
                        </List>
                    </Stack>

                    <Stack sx={{ display: 'flex', width: '100%' }}>
                        {rows.length > 0 ? (
                            <AllGroupsTables user_id={id} groups={rows} cb={updateGroups} />
                        ) : (
                            <AllGroupsTables user_id={id} groups={rows} cb={updateGroups} />
                        )}

                    </Stack>
                </Stack>
            </Grow>
        </>
    )
}