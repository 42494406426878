import * as React from 'react';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../app/hooks';
import SearchBar from '../search-bar/search-bar.component';
import CustomTablePagination from '../pagination-buttons/pagination-buttons.component';
import webHelpers from '../../scripts/helpers';
import {
    Table, TableBody, TableCell,
    TableRow, TableHead
} from '@mui/material';
import Button from '@mui/material/Button';
import { useSnackbar } from 'notistack';
import { IPermission } from '../../assets/global-interfaces/permission.interfaces';
interface IAllAssignablePermissionsProps {
    id: string,
    cb: Function,
}


const AllAssignablePermissions = (props: IAllAssignablePermissionsProps) => {
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [rows, setRows] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const [maxPages, setMaxPages] = useState(1);

    const environment = useAppSelector(state => state.loggedIn.environment)

    const token = useAppSelector(state => state.loggedIn.token)

    const prevPage = () => {
        if (page === 1) {
            enqueueSnackbar('Already on the first page!', { 'variant': 'error' });
        } else { setPage(page - 1); }
    }
    const nextPage = () => {
        if (rows.length < 7) {
            enqueueSnackbar('On last page already!', { 'variant': 'error' })
        } else { setPage(page + 1); }
    }

    function fetchPermissions() {
        webHelpers.get('/api/management/permissions/7/' + page + '/', environment, 'auth', token, (data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                enqueueSnackbar(`No permissions found by the name "${search}", please try another search`, { 'variant': 'error' })
            }
            else {
                setRows(Object.values(data.permissions));
                setMaxPages(data.max_pages);
            }
        })
    }
    function searchPermissions(search: string) {
        webHelpers.get('/api/management/permissions/7/1/' + search, environment, 'auth', token, (data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                enqueueSnackbar(`No permissions found by the name "${search}", please try another search`, { 'variant': 'error' })
            }
            else {
                setRows(Object.values(data.permissions));
                setMaxPages(data.max_pages);
            }
        })
    }

    useEffect(() => {
        const requestTimeout = setTimeout(() => {
            searchPermissions(search)
        }, 500);
        return () => clearTimeout(requestTimeout);
    }, [search])

    useEffect(() => {
        const requestTimeout = setTimeout(() => {
            fetchPermissions()
        }, 500);
        return () => clearTimeout(requestTimeout);
    }, [page])

    return (<>
        <div className="table-container">
            <div className="table-top-container">
                <SearchBar placeholder='Search permissions..' onChange={setSearch} />
                <div className="pagination-container">
                    <CustomTablePagination
                        goFirst={() => { setPage(1) }}
                        onPrev={prevPage}
                        onNext={nextPage}
                        goLast={() => setPage(maxPages)}
                    /></div>

            </div>

            <Table sx={{ minWidth: '100%' }} size='small' aria-label="custom pagination table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left"><strong>ID</strong></TableCell>
                        <TableCell align="left"><strong>Description</strong></TableCell>
                        <TableCell align="left"></TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {rows.map((permission: IPermission) => (
                        // Permission component needed
                        <TableRow key={permission.id}>
                            <TableCell component="th" scope="row">
                                <p>{permission.id}</p>
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="left">
                                <p>{permission.name}</p>
                            </TableCell>
                            <TableCell style={{ width: 160 }} align="right">
                                <Button onClick={() => props.cb(permission.id)}><strong>Assign</strong></Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    </>
    )
}

export default AllAssignablePermissions;