﻿import { configureStore } from '@reduxjs/toolkit';
import loggedInReducer from '../features/loggedIn/loggedInSlice';
import environmentReducer from '../features/environment/environmentSlice';
import tokenReducer from '../features/token/tokenSlice';


export const store = configureStore({
    reducer: {
        loggedIn: loggedInReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;