import * as React from 'react';
import {useState} from 'react';
import {TableRow, TableCell} from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import {Level} from '../../../assets/global-interfaces/role-management.interfaces';
import LevelSelect from '../../level-selector/level-selector.component';
import {useSnackbar} from 'notistack';
import {ISkillToAdd} from "../edit-role/role-add-skill.component";

interface SkillProps {
    id: string,
    name: string,
    levels: Level[],
    handleAddedSkills: Function,
}

export default function SkillsTableRow(props: SkillProps) {
    const [level, setLevel] = useState<Level>();

    const {enqueueSnackbar} = useSnackbar();

    const addSkill = () => {

        if (level !== undefined) {
            const addedSkill: ISkillToAdd = {
                skill_id: props.id,
                level_id: level.id,
                name: props.name,
                level_name: level.name,
            }
            props.handleAddedSkills(addedSkill)
            console.log('added skill', addedSkill)
        } else {
            enqueueSnackbar(`Please choose a level before clicking Add`, {'variant': 'warning'});
        }
    }

    return (
        <>
            <TableRow key={props.id}>
                <TableCell style={{width: 350}} scope="row">
                    <p className="cell-data" style={{marginTop: '-8px'}}>{props.name}</p>
                </TableCell>
                <TableCell style={{width: 340}} align="right">
                    {/* Some dialog button for choosing level */}
                    <Stack sx={{display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center'}}>
                        <LevelSelect levels={props.levels} cb={setLevel}/>
                        <div className="button-container" style={{marginTop: '10px'}}>
                            <Button onClick={addSkill}>Add</Button>
                        </div>
                    </Stack>
                </TableCell>
            </TableRow>
        </>)
}