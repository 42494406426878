import * as React from 'react';
import './app.scss';
import Dashboard from './components/dashboard/dashboard.component';
import { SnackbarProvider } from 'notistack';
function App() {
    return (
        <div className="App">
            <SnackbarProvider maxSnack={3}>
                <div className="app-container">
                    <Dashboard />
                </div>
            </SnackbarProvider>
        </div>
    );
}

export default App;