import * as React from 'react';
import { User } from '../../assets/global-interfaces/user.interface';
import { TableRow, TableCell, Button, Modal, Box, Typography } from "@mui/material";
import UserAppsDialog from '../user-applications-dialog/user-applications-dialog.component';
import EditUserModal from '../modal/edit-user-modal.component';
import ResetPasswordDialog from '../reset-password-dialog/reset-password-dialog.component';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import webHelpers from '../../scripts/helpers';


import { useAppSelector } from '../../app/hooks';

import { useState, useEffect, memo } from 'react';



interface UserRowProps {
    user: User,
    cb: Function,
}



export default function ConfirmModal(props: UserRowProps) {
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<User[]>([]);
    const [tableHeight, setTableHeight] = useState('5100px');
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(15);
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");

    let environment = useAppSelector(state => state.loggedIn.environment)
    const token = useAppSelector(state => state.loggedIn.token)

    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: '20vw',
        height: '20vh',
        borderRadius: "15px",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const handleOpen = () => {
        setOpen(true);
        //@ts-ignore
    }
    const handleClose = () => {
        setOpen(false);
    }
    const cleanName = (name: string) => {
        const regex = /[.,\s]/g;
        return name.replace(regex, ' ');
    }

    const enableUser = () => {
        webHelpers.post("/api/management/user/" + props.user.id + "/enable", environment, 'auth', "", token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log("error running API call")
            }
            else {
                console.log("success: ", data);
                console.log("user status = ", props.user.status)
                props.cb();
            }
        })
    }
    const disableUser = () => {
        webHelpers.post("/api/management/user/" + props.user.id + "/disable", environment, 'auth', "", token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log("error running API call")
            }
            else {
                console.log("success: ", data);
                props.cb();
            }
        })
    }

    return (<>

        {String(props.user.status) === "Active" ?
            <Button variant="text" endIcon={<PersonOffIcon />} onClick={handleOpen}>Disable</Button>
            :
            <Button variant="text" endIcon={<PersonAddAlt1Icon />} onClick={handleOpen}>Enable</Button>
        }

        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={style} justifyContent={"center"}>
                {String(props.user.status) === "Active" ?
                    <>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Disable User
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Are you sure you would like to disable this user?
                        </Typography>
                        <Button
                            
                            variant='contained'
                            color='success'
                            onClick={disableUser}
                            size='small'
                        >
                            Yes
                        </Button>
                    </>
                    :
                    <>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Enable User
                        </Typography>
                        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                            Are you sure you want to enable this user?
                        </Typography>
                        <Button
                            onClick={enableUser}
                        >
                            Yes
                        </Button>
                    </>
                }
                <Button
                    variant='contained'
                    color='error'
                    onClick={handleClose}
                    size='small'
                >
                    No
                </Button>

            </Box>

        </Modal>
    </>)

}
