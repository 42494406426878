import * as React from "react";
import { useState, useEffect } from "react";
import "./blocked-numbers-table.styles.scss";
import webHelpers from "../../scripts/helpers";

import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination, TableContainer,
} from "@mui/material";

import SearchBar from "../search-bar/search-bar.component";
import ErrorBoundary from "../error-boundary/error-boundary.component";
import BlockedNumber from "./blocked-number.component";
import { useSnackbar } from "notistack";
import { useAppSelector } from "../../app/hooks";
import AddNumber from "../blocked-numbers-add-modal/blocked-numbers-add-modal.component";

interface IBlockedNumber {
  id: string;
  reason: string;
  type: number;
  createdByName: string;
  expires: string;
}
const BlockedNumbersTable = () => {
  const [search, setSearch] = useState("");
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [maxPages, setMaxPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [tableHeight, setTableHeight] = useState('400px')

  const { enqueueSnackbar } = useSnackbar();
  
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const token = useAppSelector((state) => state.loggedIn.token);
  const environment = useAppSelector(state => state.loggedIn.environment);


  let height = window.screen.height;

  function updateTable() {
    webHelpers.get(
      "/api/call/control/block/?limit=" +
        rowsPerPage +
        "&page=" +
        (page+1) +
        "&filter=" +
        search,
      environment,
      "zeus-api",
      token,
      (data: any) => {
        if (data === undefined ) {
          enqueueSnackbar(
            `${data.status}: Unable to fetch numbers from the API`,
            { variant: "error" }
          );
        } else {
          setRows(data.results);
          console.log(data.results);
          console.log(data);
          setMaxPages(data.maxPage);
        }
      }
    );
  }
  
  useEffect(() => {
    if (height > 1340) {
      setTableHeight('750px');
    }
    if (height < 1340) {
      setTableHeight('450px');
    }
  }, [height]);
  
  useEffect(() => {
    if(search !== ''){
      const requestTimeout = setTimeout(() => {
        updateTable();
      }, 500);
      return () => clearTimeout(requestTimeout);   
    }
    else {
      updateTable();
    }
  }, [search, rowsPerPage, page]);
  
useEffect(() => {
 updateTable();
}, [page, rowsPerPage]);

  return (
    <>
      <div className="table-padding">
        <div className="table-add-number-spacer">
          <h2 className="table-header">Blocked Numbers</h2>
          <AddNumber cb={updateTable} />
        </div>
        
        <div className="table-top-controls">
          <div className="search-bar-box">
            <SearchBar placeholder="Search by number" onChange={setSearch} />
          </div>

          <div className="add-number-button">
            
          <TablePagination
              rowsPerPageOptions={[15, 25, 50]}
              component="div"
              count={rowsPerPage * maxPages}
              rowsPerPage={rowsPerPage}
              page={page}
              showFirstButton
              showLastButton
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
          />
          </div> 
        </div>

        <ErrorBoundary>

          <TableContainer sx={{height: tableHeight }}>
            <Table
            stickyHeader
            
            sx={{ minWidth: "100%"}}
            size="small"
            aria-label="custom pagination table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <strong>Number</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Type</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Reason</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Created By</strong>
                </TableCell>
                <TableCell align="left">
                  <strong>Expires</strong>
                </TableCell>

                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((blockedNumber: IBlockedNumber) => (
                <BlockedNumber
                  id={blockedNumber.id}
                  type={blockedNumber.type}
                  reason={blockedNumber.reason}
                  createdByName={blockedNumber.createdByName}
                  expires={blockedNumber.expires}
                  cb={updateTable}
                />
              ))}
            </TableBody>
          </Table>
          </TableContainer> 
        </ErrorBoundary>
      </div>
    </>
  );
};

export default BlockedNumbersTable;