import * as React from "react";
import { useState, useEffect } from "react";
import { useAppSelector } from "../../app/hooks";
import webHelpers from "../../scripts/helpers";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import { User } from "../../assets/global-interfaces/user.interface";
import { RadioGroup, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import AppsIcon from "@mui/icons-material/Apps";
interface Application {
  id: string;
  ttl: number;
}
interface UserAppsDialogProps {
  user: User;
  cb: Function;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserAppsDialog(props: UserAppsDialogProps) {
  const [open, setOpen] = useState(false);
  const [applications, setApplications] = useState<Application[]>([
    { id: "echo", ttl: 1235 },
  ]);
  const [loaded, setLoaded] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const token = useAppSelector((state) => state.loggedIn.token);
  const environment = useAppSelector((state) => state.loggedIn.environment);

  const handleClickOpen = () => {
    webHelpers.get(
      "/api/management/applications",
      environment,
      "auth",
      token,
      (data: any) => {
        setApplications(data);
      }
    );
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  function handleRadioChange(appId: string, value: boolean) {
    if (value === true) {
      revokeApp(appId);
    } else {
      assignApp(appId);
    }
  }
  function assignApp(id: string) {
    webHelpers.post(
      "/api/management/application/" + id + "/assign/" + props.user.id,
      environment,
      "auth",
      {},
      token,
      (data: any) => {
        if (data.status == 200) {
          enqueueSnackbar(`${id} added to user: ${props.user.full_name}`, {
            variant: "success",
          });
          props.cb();
        } else {
          enqueueSnackbar(`${data.status}: Unable to assign this app`, {
            variant: "error",
          });
        }
      }
    );
  }
  function revokeApp(id: string) {
    webHelpers.post(
      "/api/management/application/" + id + "/revoke/" + props.user.id,
      environment,
      "auth",
      {},
      token,
      (data: any) => {
        if (data.status == 200) {
          enqueueSnackbar(`${id} removed from user: ${props.user.full_name}`, {
            variant: "warning",
          });
          props.cb();
        } else {
          enqueueSnackbar(`${data.status}: Unable to revoke this app`, {
            variant: "error",
          });
        }
      }
    );
  }

  function userHasApplicationCheck(id: string) {
    let found = false;
    props.user.applications.forEach((app: Application) => {
      if (app.id == id) {
        found = true;
      }
    });
    return found;
  }
  useEffect(() => {
    // console.log('rendered')
  }, []);

  return (
    <>
      <Button onClick={handleClickOpen} endIcon={<AppsIcon />}>
        Apps
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Manage User Applications"}</DialogTitle>
        <DialogContent>
          <Stack
            direction="column"
            spacing={1}
            sx={{ padding: 1, alignItems: "left", justifyContent: "left" }}
          >
            <RadioGroup>
              {applications.map((app: Application) => (
                <FormControlLabel
                  key={app.id}
                  control={
                    <Radio
                      style={{ alignContent: "center" }}
                      checked={userHasApplicationCheck(app.id)}
                      onClick={() =>
                        handleRadioChange(
                          app.id,
                          userHasApplicationCheck(app.id)
                        )
                      }
                      value="a"
                      name="radio-buttons"
                      inputProps={{ "aria-label": app.id }}
                    />
                  }
                  label={app.id}
                />
              ))}
            </RadioGroup>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Finish</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
