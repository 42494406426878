import * as React from 'react';
import {useState, useEffect } from 'react';
import {TextField} from '@mui/material';
import {useSnackbar} from 'notistack';
import {Exception} from "sass";

interface IDateTimeProps {
    handDateTimeUp?: Function,
    dateTime?: string[],
    days?: string,
    hours: string,
    minutes: string,
    seconds: string,
}

export default function DateTimePicker(props: IDateTimeProps){
    const [hours, setHours] = useState<string>(props.hours);
    const [minutes, setMinutes] = useState<string>(props.minutes);
    const [seconds, setSeconds] = useState<string>(props.seconds);
    const [days, setDays] = useState<string>(props.days || '0');
    const [dateTime, setDateTime] = useState<string[]>(props.dateTime || []);
    
    const {enqueueSnackbar} = useSnackbar();
    
    function passUpDateTime(d: string, h: string, m: string, s: string) {
        if(props.handDateTimeUp !== undefined) {
            props.handDateTimeUp(d + '.' + h + ':' + m + ':' + s)
        }
    }
    function checkInputType(e:any) {
        let val;
        try{
            val = Number(e.target.value)
            if(isNaN(val)){
                enqueueSnackbar('Not a number!!!!');
                val = 0;
            }
        }
        catch(e:any){
            enqueueSnackbar(`${e.message}: cannot parse this character for a datetime`)
        }
        return val;
    } 
    const handleHoursChange = (e:any) => {
       let val = checkInputType(e);
       if(val !== undefined) {
          if(val > 24 || val < 0) {
              enqueueSnackbar('Hours must be a value between 0-24', {'variant': 'warning'});
          }
          else{ 
            setHours(val.toString());
            passUpDateTime(days, val.toString(), minutes, seconds);
          }
       }
    }
    const handleMinutesChange= (e:any) => {
        let val = checkInputType(e);
        if(val !== undefined){
            if(val > 60 || val < 0){
                enqueueSnackbar('Minutes must be set between 0-60', {'variant': 'warning'})
            }
            else {
                setMinutes(val.toString());
                passUpDateTime(days, hours, val.toString(), seconds);
            }
        }
    }
    const handleSecondsChange= (e:any) => {
        let val = checkInputType(e);
        if(val !== undefined) {
            if(val > 60 || val < 0) {
                enqueueSnackbar('Seconds must be set  to a value between 0-60', {'variant':'warning'});
            }
            else {
               setSeconds(val.toString());
               passUpDateTime(days, hours, minutes, e.target.value);
            }    
        }
    }
   
    const handleDaysChange = (e:any) => {
        let val = checkInputType(e);
        if(val !== undefined){
            setDays(val.toString());
            passUpDateTime(val.toString(), hours, minutes, seconds);
        }
    }
    useEffect(() => {
       if(dateTime.length !== 0) {
           setHours(dateTime[0]);
           setMinutes(dateTime[1]);
           setSeconds(dateTime[2]);
       }
    }, [])
    return(<> 
   <div className="date-time-row">
       <TextField
           sx={{width: '4rem', paddingRight: '1rem'}} 
           size='small'
           label='days'
           
           value={days}
           onChange={handleDaysChange}
       />
   <TextField
       sx={{width: '4rem', paddingRight: '1rem'}}
       size='small'
       label='hours' 
       value={hours} 
       onChange={handleHoursChange}/>
   <TextField
       sx={{width: '4rem', paddingRight: '1rem'}}
       size='small'
       label='minutes' 
       value={minutes} 
       onChange={handleMinutesChange}/>
   <TextField
       sx={{width: '4rem', paddingRight: '1rem'}}
       size='small'
       label='seconds' 
       value={seconds} 
       onChange={handleSecondsChange}/>
   </div>
    </>)
}