import * as React from 'react';
import { User } from '../../assets/global-interfaces/user.interface';
import { TableRow, TableCell, Button } from "@mui/material";
import UserAppsDialog from '../user-applications-dialog/user-applications-dialog.component';
import EditUserModal from '../modal/edit-user-modal.component';
import ResetPasswordDialog from '../reset-password-dialog/reset-password-dialog.component';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import webHelpers from '../../scripts/helpers';

import { useAppSelector } from '../../app/hooks';

import { useState, useEffect, memo } from 'react';
import ConfirmModal from './user-confirm-disable';



interface UserRowProps {
    user: User,
    cb: Function,
}



function UserRow(props: UserRowProps) {
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<User[]>([]);
    const [tableHeight, setTableHeight] = useState('5100px');
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(15);
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");

    let environment = useAppSelector(state => state.loggedIn.environment)
    const token = useAppSelector(state => state.loggedIn.token)

    const cleanName = (name: string) => {
        const regex = /[.,\s]/g;
        return name.replace(regex, ' ');
    }

    const enableUser = () => {
        webHelpers.post("/api/management/user/"+props.user.id+"/enable", environment, 'auth',"", token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined){
                console.log("error running API call")
            }
            else{
                console.log("success: ", data);
                console.log("user status = ", props.user.status)
                props.cb();
            }
        })
    }
    const disableUser = () => {
        webHelpers.post("/api/management/user/"+props.user.id+"/disable", environment, 'auth',"", token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined){
                console.log("error running API call")
            }
            else{
                console.log("success: ", data);
                props.cb();
            }
        })
    }

    return (<>
        <TableRow key={props.user.id.toString()}>
            <TableCell style={{ width: '35%', paddingLeft: 10 }} component="th" scope="row" align='left'>
                <p >{cleanName(props.user.full_name.toString())}</p>
            </TableCell>
            <TableCell style={{ width: '1fr' }} align="left">
                <p >{props.user.department}</p>
            </TableCell>
            <TableCell style={{ width: '1fr' }} align="left">
                <p >{props.user.email_address}</p>
            </TableCell>
            <TableCell style={{ inlineSize: '.5rem' }} align="right">
                <UserAppsDialog cb={props.cb} user={props.user} />
            </TableCell>
            <TableCell style={{ inlineSize: '.5rem' }} align="right">
                <ResetPasswordDialog id={props.user.id}/>
            </TableCell>
            <TableCell style={{ inlineSize: '.5rem' }} align="right">
                <EditUserModal key={props.user.id} id={props.user.id} cb={props.cb} name={cleanName(props.user.full_name)} email={props.user.email_address} />
            </TableCell>
            <TableCell style={{inlineSize: '.5rem'}} align='right'>
                {String(props.user.status) === "Active" ?
                <ConfirmModal {...props}/>
                :
                <ConfirmModal {...props}/>
    }
            </TableCell>
        </TableRow>

    </>)

}

export default UserRow;