import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import webHelpers from '../../scripts/helpers';
import { useAppSelector } from '../../app/hooks';
import { useSnackbar } from 'notistack';

export default function CreatePermission() {
    const [open, setOpen] = useState(false);
    const [id, setID] = useState<string>('');
    const [description, setDescription] = useState<string>('');

    const environment = useAppSelector(state => state.loggedIn.environment);
    const token = useAppSelector(state => state.loggedIn.token);
    const { enqueueSnackbar } = useSnackbar();

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        createPermission(id, description);
    }

    const handleIdChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setID(e.target.value);
    }
    const handleDescriptionChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setDescription(e.target.value);
    }
    function createPermission(id: string, description: string) {
        let d = {
            'id': id,
            'name': description,
        }
        webHelpers.put('/api/management/permission', 'auth', environment, d, token, (data: any) => {
            if (data.id !== id || data.name !== description) {
                enqueueSnackbar(`${data.status}: Unable to create permission with ID: ${id}, 
                please if check this permission doesn't already exist.`, { 'variant': 'error' });
            }
            else {
                enqueueSnackbar(`Permission ${id} successfully created!`, { 'variant': 'success' });
                setOpen(false);
            }
        })
    }

    return (
        <>
            <div>
                <Button variant="outlined" onClick={handleClickOpen}>
                    Create Permission
                </Button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Create a Permission</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please enter a name and description for the permission being created.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="ID"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleIdChange}
                        />
                        <TextField
                            autoFocus
                            id="name"
                            label="Description"
                            type="text"
                            fullWidth
                            variant="standard"
                            onChange={handleDescriptionChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button onClick={handleSubmit}>Create</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
}