import * as React from "react";

import "./blocked-numbers.styles.scss";
import Card from '../../components/card/card.component';
import BlockedNumbersTable from "../../components/blocked-numbers-table/blocked-numbers-table.component";
const BlockedNumbersPage = () => {
  return (
    <>
        <Card>
          <BlockedNumbersTable />
        </Card>
    </>
  );
};

export default BlockedNumbersPage;
