import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { store } from '../../app/store';
const ProtectedRoute = ({children}: any) => {
    if(store.getState().loggedIn.value) {
        return children
    }
    return <Navigate to='/signIn' replace />
};

export default ProtectedRoute;