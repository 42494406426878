import * as React from 'react';
import { useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import './modal.styles.scss';
import { UserRole, dummyRoles } from '../../assets/global-interfaces/role-management.interfaces';

// Third party stuff
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SettingsIcon from '@mui/icons-material/Settings';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';

// Custom Components
import RoleManagement from '../modal-role-management/roles-table.component';
import PermissionsTab from '../user-permissions-tab/permissions-tab.component';
import GroupsTab from '../user-groups-tab/groups-tab.component';

// Helpers
import { useSnackbar } from 'notistack';
import webHelpers from '../../scripts/helpers';

interface EditUserProps {
    id: string,
    name: string,
    email: string,
    cb: Function,
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '60%',
    width: '65%',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
};

export default function EditUserModal(props: EditUserProps) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    const [roles, setRoles] = useState<UserRole[]>(dummyRoles);

    const { enqueueSnackbar } = useSnackbar();
    const environment = useAppSelector(state => state.loggedIn.environment)
    const token = useAppSelector(state => state.loggedIn.token)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue)


    // Grabs data to fill out seperate tabs
    const handleOpen = () => {
        webHelpers.get('/api/worker/roles/' + props.id, environment, 'zeus-api', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log('bad fetch call');

                enqueueSnackbar(`${data.status}: Unable to get information for this user`, {'variant': 'error'})
                // Some fallback ui needed here.
            }
            else {
                setRoles(data);
                setOpen(true);
            }
        });
    };

    function updateGroups() {
        webHelpers.get('/api/management/user/' + props.id + '/groups', environment, 'auth', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {

                // Some fallback ui needed here.
            }
            else {
                setGroups(data);
            }
        })
    };
    const handleClose = () => setOpen(false);
    // Content array contains our seperate tabs to cycle through and bring into view, these will be subcomponents

    let content_array = [
        <RoleManagement cb={props.cb} roles={roles} user_id={props.id} user_name={props.name} />, // Pass roles in here into a new component that renders all the fancy details nicely
        <PermissionsTab user_id={props.id} />,
        <GroupsTab user_id={props.id} groups={groups} updateGroups={updateGroups} />
    ];

    function saveChanges() {
        console.log('saved changes');
    };

    return (
        <>
            <Button onClick={handleOpen}>Edit<SettingsIcon /></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="modal-header">
                        <Typography id="modal-modal-title" variant="h4" component="h2">
                            Editing User: {props.name}
                        </Typography>
                        {/* Add an edit department bit here at some point */}
                        <div className="modal-header-close">
                            <CloseIcon onClick={handleClose} />
                        </div>
                    </div>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Box sx={{ width: '100%' }}>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                                sx={{'marginBottom':'1rem'}}
                            >
                                <Tab value={1} label="Roles" />
                                <Tab value={2} label="Permissions" />
                                <Tab value={3} label="Groups" />
                            </Tabs>
                            {/* Renders content to page based on which tab is active from the content array */}
                            {content_array[value - 1]}

                            <div className="modal-footer">
                                <div className="redundant-div"></div>
                                <div onClick={handleClose}>
                                    <Button variant='contained'  endIcon={<SendIcon />} onClick={saveChanges}  >Finish</Button>
                                </div>

                            </div>

                        </Box>
                    </Typography>
                </Box>
            </Modal>
        </>
    );
}