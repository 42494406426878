import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';


interface TokenState {
    value: string,
}

const initialState = {
    value: ' '
} as TokenState;

export const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        updateToken: (state, action: PayloadAction<string>) => {
            state.value = action.payload
        },
    }
})

export const { updateToken } = tokenSlice.actions;

// export const selectToken = (state: RootState) => state.token.value;

export default tokenSlice.reducer;
