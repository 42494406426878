import * as React from 'react';
import './users-table.styles.scss';
import { useState, useEffect, memo } from 'react';

// Material UI Imports
import {
    Table, TableBody, TableCell, TableFooter,
    TableRow, TableHead, TablePagination, TableContainer,
    InputLabel, List, MenuItem, Select, SelectChangeEvent,
} from '@mui/material';
// Custom component imports
import UserRow from './user.component';
import SearchBar from "../search-bar/search-bar.component";
import ErrorBoundary from '../error-boundary/error-boundary.component';
import CustomTablePagination from '../pagination-buttons/pagination-buttons.component';
import { useSnackbar } from 'notistack';

//  tools and interfaces
import webHelpers from '../../scripts/helpers';
import { useAppSelector } from '../../app/hooks';
import { User } from '../../assets/global-interfaces/user.interface';

interface IUserTypes {
    id: string,
    name: string,
}

const UsersTable = memo(function UserTable() {
    const [search, setSearch] = useState<string>('');
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<User[]>([]);
    const [tableHeight, setTableHeight] = useState('5100px')
    const { enqueueSnackbar } = useSnackbar();
    const [maxPages, setMaxPages] = useState<number>(1);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [userTypes, setUserTypes] = useState<IUserTypes[]>([]);
    const [selectedType, setSelectedType] = useState<string>("803130a7-7a5c-43d3-b850-bf8ecba20cd9");

    let environment = useAppSelector(state => state.loggedIn.environment)
    const token = useAppSelector(state => state.loggedIn.token)

    let height = window.screen.height;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function getUserTypes() {
        webHelpers.get('/api/usertypes', environment, 'auth', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                enqueueSnackbar(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
            }
            else {
                setUserTypes(data);
            }
        });
    }

    function searchUsers(search: string) {
        console.log('/api/management/users/' + selectedType + "/" + rowsPerPage + '/' + page + '/' + search);

        webHelpers.get('/api/management/users/' + selectedType + "/" + rowsPerPage + '/' + (page + 1) + '/' + search, environment, 'auth', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                enqueueSnackbar(`No users found by the name "${search}", please try another search`, { 'variant': 'error' })
            }
            else {
                console.log("data",data);
                setRows(data.users)
                setMaxPages(data.max_pages)
            }
        });
    }

    useEffect(() => {
        if (search !== '') {
            const requestTimeout = setTimeout(() => {
                searchUsers(search)
            }, 500);
            return () => clearTimeout(requestTimeout);
        } else {
            searchUsers(search)
        }
    }, [search, rowsPerPage]);


    useEffect(() => {
        getUserTypes();
        console.log('/api/management/users/' + selectedType + "/" + rowsPerPage + '/' + (page + 1) + '/' + search);

        webHelpers.get('/api/management/users/' + selectedType + '/' + rowsPerPage + '/' + (page+1) + '/' + search, environment, 'auth', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                console.log('bad fetch call');
                enqueueSnackbar(`${data.status}: Unable to find any users through this request, please try another environment`, { 'variant': 'error' })
            }
            else {
                console.log(data);
                setRows(data.users);
                setMaxPages(data.max_pages);
            }
        });
    }, [page, environment, rowsPerPage]);

    const refreshUsers = () => {
        searchUsers(search)

    }
    const emptyRows =
        (rowsPerPage - rows.length)

    useEffect(() => {
        if (height > 1340) {
            setTableHeight('750px');
        }
        if (height < 1340) {
            setTableHeight('650px');
        }
    }, [height]);

    useEffect(() =>{
        refreshUsers();
    },[selectedType]);

    const handleProfileChange = (event: SelectChangeEvent) => {
        setSelectedType(event.target.value);
    }

    return (
        <>
         {refreshUsers}
        <div className="table-container">
           
            <div className="search-bar-box">
                <div className='filter-user-type'>
                    <InputLabel id="demo-simple-select-label">Filter User Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedType}
                        onChange={handleProfileChange}
                    >
                        {userTypes.map((workflow: IUserTypes) => (
                            <MenuItem value={workflow.id}>{workflow.name}</MenuItem>
                        ))}
                    </Select>
                </div>
                <SearchBar placeholder='Search by name..' onChange={setSearch} />

                <TablePagination
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={rowsPerPage * maxPages}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    showFirstButton
                    showLastButton
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
            <ErrorBoundary>

                <TableContainer sx={{ height: tableHeight }}>
                    <Table sx={{ minWidth: '100%' }} stickyHeader size='small' aria-label="custom pagination table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left"><strong>Name</strong></TableCell>
                                <TableCell align="left"><strong>Department</strong></TableCell>
                                <TableCell align="left"><strong>Email</strong></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                                <TableCell align="right"></TableCell>
                            </TableRow>


                        </TableHead>
                        <TableBody>
                            {/* @ts-ignore */}
                            {rows.map((user: User) => (
                                <UserRow key={user.id} user={user} cb={refreshUsers} />
                            ))}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: 61 * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ErrorBoundary>
        </div>
        </>
    );
});

export default UsersTable;