import * as React from 'react';
// import './groups-table.styles.scss';
import { useState, useEffect } from 'react';
// Material UI Imports
import {
    Table, TableBody, TableCell, TableFooter, TableRow, TableHead, Button
} from '@mui/material';

// Custom Components
import SearchBar from '../search-bar/search-bar.component';
import ErrorBoundary from '../error-boundary/error-boundary.component';
import CustomTablePagination from '../pagination-buttons/pagination-buttons.component';
import webHelpers from '../../scripts/helpers';
import { useAppSelector } from '../../app/hooks';
import { useSnackbar } from 'notistack';

interface Group {
    id: string,
    name: string,
    is_security: boolean,
}
interface IGroupsData {
    user_id: string,
    groups: Group[],
    cb: Function,
}


export default function AllGroupsTables(props: IGroupsData) {
    const [page, setPage] = useState<number>(1);
    const [rows, setRows] = useState(Object.values(props.groups));
    const [search, setSearch] = useState('');
    const [maxPages, setMaxPages] = useState(1);
    const { enqueueSnackbar } = useSnackbar();

    const environment = useAppSelector(state => state.loggedIn.environment)
    const token = useAppSelector(state => state.loggedIn.token)

    const prevPage = () => {
        if (page === 1) {
            enqueueSnackbar('Already on the first page!', { 'variant': 'error' });
        } else { setPage(page - 1); }
    }
    const nextPage = () => {
        if (rows.length < 7) {
            enqueueSnackbar('On last page already!', { 'variant': 'error' })
        } else { setPage(page + 1); }
    }

    function searchGroups(search: string) {
        webHelpers.get('/api/management/groups/7/' + page + '/' + search, environment, 'auth', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                enqueueSnackbar(`No groups found by the name "${search}", please try another search`, { 'variant': 'error' })
            }
            else {
                setRows(data.groups);
                setMaxPages(data.max_pages);
            }
        });
    }


    useEffect(() => {
        const requestTimeout = setTimeout(() => {
            searchGroups(search)
        }, 400);
        return () => clearTimeout(requestTimeout);
    }, [search]);

    // Secondary useEffect hook for handling changes in environment/page. This allows pagination to work
    useEffect(() => {
        webHelpers.get('/api/management/groups/' + 7 + '/' + page, environment, 'auth', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                enqueueSnackbar(`Unexpected error, code: ${data.status}.`, { 'variant': 'error' })
                // Add some alert logic here for bad search inputs, try to relay the input
            }
            else {
                setRows(data.groups);
                setMaxPages(data.max_pages);
            }
        })
    }, [page, environment, token, enqueueSnackbar])

    function handleAssign(id: string) {
        webHelpers.post('/api/management/group/' + id + '/assign/' + props.user_id, environment, 'auth', {}, token, (data: any) => {
            if (data.status !== 200) {
                enqueueSnackbar(`${data.status}: Unable to assign this user to this group.`, { 'variant': 'error' })
            }
            else {
                enqueueSnackbar('User successfully added to group!', { 'variant': 'success' })
                props.cb();
            }
        })
    }
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        (7 - rows.length)

    return (
        <div className="table-container">
            <div className="table-top-container">
                <SearchBar placeholder='Search groups..' onChange={setSearch} />

                <div className="table-top-buttons">

                </div>
            </div>

            <ErrorBoundary>
                <Table sx={{ minWidth: '50%' }} size='small' aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"><strong>Name</strong></TableCell>
                            <TableCell align="left"><strong>Security</strong></TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows.map((group) => (
                            <TableRow key={group.id.toString()}>
                                <TableCell className='table-text' scope="row" sx={{ width: '1fr' }}>
                                    <p >{group.name.toString()}</p>
                                </TableCell>
                                <TableCell className='table-text' sx={{ width: '1fr' }} align="left">
                                    <p >{group.is_security ? 'yes' : 'no'}</p>
                                </TableCell>
                                <TableCell style={{ width: '1rem' }} align="right">
                                    <Button onClick={() => handleAssign(group.id)} >Assign</Button>
                                </TableCell>

                            </TableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 61 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>

                    <TableFooter>
                        <TableRow>
                            <CustomTablePagination
                                goFirst={() => setPage(1)}
                                onPrev={prevPage}
                                onNext={nextPage}
                                goLast={() => setPage(maxPages)}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </ErrorBoundary>
        </div >
    );
}