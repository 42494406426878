export interface UserRole {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    details: Detail[],
}

export interface UserRoleUpdate {
    id: string,
    name: string,
    worker_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    outbound_rule: string | null,
    outbound_number_id: string,
    skills: ISkill[],
}

export interface Detail {
    id: string,
    level: Level,
    level_id: string,
    role_id: string,
    skill: Skill,
    skill_id: string,
    worker_id: string,
}
export interface Role {
    id: string,
    worker_id: string,
    name: string,
    outbound_number_id: string,
    allow_aux: boolean,
    auto_answer: boolean,
    details: string
}

export interface Level {
    id: string,
    key: string,
    name: string,
    order: number,
    skill_id: string,
}

export interface Skill {
    id: string,
    key: string,
    name: string,
    levels: Level[],
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}

// Jesus Christ    'skills': [{ 'skill_id': 'dummy_skill_id', 'level_id': 'dummy_level_id' }],
export const dummyRoles: UserRole[] = [{
    'id': 'dummy_id',
    'name': 'dummy_name',
    'worker_id': 'dummy_worker_id',
    'allow_aux': true,
    'auto_answer': false,
    'outbound_rule': 'dummy_outbound_rule',
    'outbound_number_id': 'dummy_outbound_number_id',
    'details': [{
        'id': 'dummy_details_id',
        'level': {
            'id': 'dummy_level_id',
            'key': 'dummy_level_key',
            'name': 'dummy_level_name',
            'order': 100,
            'skill_id': 'dummy_level_skill_id',
        },
        'level_id': 'dummy_level_id_top_level',
        'role_id': 'dummy_role_id',
        'skill': {
            'id': 'dummy_skill_id',
            'key': 'dummy_skill_key',
            'name': 'dummy_kill_name',
            'levels': [{
                'id': 'dummy_skill_level1',
                'key': 'dummy_level_key',
                'name': 'dummy_level_name',
                'order': 100,
                'skill_id': 'dummy_level_skill_id',
            }],
        },
        'skill_id': 'dummy_skill_id',
        'worker_id': 'dummy_worker_id'
    }],
}]