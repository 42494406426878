import * as React from "react";
import { useState } from "react";
import { Select, MenuItem, SelectChangeEvent } from "@mui/material";
import * as JsonData from "../../assets/data/country-codes.json";

interface ICountryCodesProps {
  cb: Function;
}
interface CountryCode {
  name: string;
  dial_code: string;
  code: string;
}

function CountryCodes(props: ICountryCodesProps) {
  const [countryCode, setCountryCode] = useState("");

  const AllCodes = Object.values(JsonData);

  const handleMenuSelect = (event: SelectChangeEvent) => {
    setCountryCode(event.target.value as string);
    props.cb(event.target.value as string);
  };

  return (
    <>
      <Select
        defaultValue="+44"
        labelId="Country Code"
        id="countryCode"
        value={countryCode}
        label="country"
        autoWidth
        sx={{marginRight: '1rem'}}
        required
        onChange={handleMenuSelect}
      >
        {AllCodes.map((code: CountryCode) => (
          <MenuItem key={code.code} value={code.dial_code}>
            {code.name} {code.dial_code}
          </MenuItem>
        ))}
      </Select>
    </>
  );
}

export default CountryCodes;
