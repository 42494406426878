import React from 'react';
import './sign-in.styles.scss';
import { useState } from 'react';
import { useAppDispatch } from '../../app/hooks';
import { useNavigate } from 'react-router';
// Components
import CustomButton from '../custom-button/custom-button.component';
import InputBox from '../form-inputs/input-box.component';
import { toggle, updateUser, saveToken } from '../../features/loggedIn/loggedInSlice';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import webHelpers from '../../scripts/helpers';
const SignIn = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    // Hooks
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [badLogin, setBadLogin] = useState(false);
    // Event Handlers 
    const handleNameChange = (n: string) => { setBadLogin(false); setName(n); };
    const handlePasswordChange = (pass: string) => { setBadLogin(false); setPassword(pass); };
    const handleSubmit = (e: { preventDefault: () => void; }) => { e.preventDefault(); }

    // Authentication function
    const auth = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        var r = {
            user_name: name,
            password: password
        };
        var requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(r)
        };

        fetch('/api/authenticate', requestOptions)
            .then(async response => {
                if (response.status === 200) {
                    let data = await response.json();
                    dispatch({ type: toggle });
                    dispatch({ type: saveToken, payload: data.token });

                    webHelpers.get('/api/user', 'live', 'auth', data.token, (data: any) => {
                        dispatch({ type: updateUser, payload: data.full_name })
                    });
                    navigate('../management/staff');

                } else if (response.status === 401) {
                    setBadLogin(true);
                };
            });
    };

    return (<>
        <div className="sign-in">
            <h1 className='title'>Sign In</h1>
            <form onSubmit={handleSubmit}>
                <InputBox type='text' placeholder='name' onChange={handleNameChange} />
                <InputBox type='password' placeholder='password' onChange={handlePasswordChange} />
                <div className="button-container">
                    <CustomButton onClick={auth} >Sign In</CustomButton>
                </div>
            </form>
        </div>
        {badLogin ?
            (<Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Incorrect login details — <strong>try again!</strong>
            </Alert>
            ) : (
                <></>
            )}
    </>
    );
};

export default SignIn;