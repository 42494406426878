import * as React from 'react';
import {useState} from 'react';
import {
    Button, Dialog, DialogActions, AppBar, Toolbar, IconButton, DialogTitle, Slide
} from '@mui/material';
import AceEditor from "react-ace";
import CloseIcon from "@mui/icons-material/Close";
import {TransitionProps} from "@mui/material/transitions";

interface IStepCodeEditorProps{
    code: string,
    setCode: Function,
}


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function StepCodeEditor(props: IStepCodeEditorProps) {
    const [open, setOpen] = useState(false);
    const [code, setCode] = useState(props.code);
    const [unsavedCode, setUnsavedCode] = useState(false);
    const [initialCode, setInitialCode] = useState<string>(props.code);
    
    const handleClose = () => {
        if(code !== '' && code !== initialCode) {
            setUnsavedCode(true);
        }
        else {
            setOpen(false);
        }
    } 
    
    const handleSave = () => {
        if(code === '') {
           setOpen(false);
        }
        else {
            props.setCode(code);
            setOpen(false);
            setUnsavedCode(false);
        }
    }
   const handleOpen = () => {
        setOpen(true);
        setInitialCode(code);
   } 
    
    
    return(
        <>
            <Button variant='outlined' onClick={handleOpen} >
                Edit Code
            </Button>


            {/* code editor goes here */}
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Button autoFocus color="inherit" onClick={handleSave}>
                            Save
                        </Button>
                    </Toolbar>
                    <AceEditor
                        style={{
                            height: "95vh",
                            width: "100%",
                        }}
                        theme="gruvbox"
                        placeholder={initialCode}
                        mode="csharp"
                        name="Script for step"
                        onChange={(currentCode) => setCode(currentCode)}
                        fontSize={18}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={code}
                        enableBasicAutocompletion={true}
                        tabSize={4}
                    />
                </AppBar>
            </Dialog>

            <Dialog
                open={unsavedCode}
                onClose={() => setUnsavedCode(false)}
                TransitionComponent={Transition}
                sx={{padding: '2rem' }}
            >
                <DialogTitle>Wait!</DialogTitle>
                <p style={{padding: '1rem'}}> You have made changes to the code which haven't been saved,
                    if you exit now you'll lose any changes made!</p>
                <DialogActions>
                    <Button onClick={() => setUnsavedCode(false)}>Continue Coding</Button>
                    <Button onClick={handleSave}>Save Changes</Button>
                    <Button onClick={() => {
                        setOpen(false);
                        setUnsavedCode(false);
                        setCode(props.code);
                    }}>Discard Changes</Button>
                </DialogActions>
            </Dialog>        </>
    )
}

export default StepCodeEditor;