import * as React from 'react';
import { useState } from 'react';
import {
    Table, TableBody, TableCell, TableFooter,
    TableRow, TableHead
} from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import webHelpers from '../../scripts/helpers';
import { useAppSelector } from '../../app/hooks';
import { useSnackbar } from 'notistack';
import { IPermission } from '../../assets/global-interfaces/permission.interfaces';

interface IGroupPermissionsTableProps {
    cb: Function,
    permissions: IPermission[],
}


const GroupPermissionsTable = (props: IGroupPermissionsTableProps) => {
    const [rows, setRows] = useState(props.permissions);
    const { enqueueSnackbar } = useSnackbar();

    const token = useAppSelector(state => state.loggedIn.token)
    const environment = useAppSelector(state => state.loggedIn.environment)

    function handleDelete(id: string) {
        webHelpers.post('/api/management/permission/revoke/' + id, environment, 'auth', {}, token, (data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                enqueueSnackbar(`${data.status}: Unable to remove this permission`, { 'variant': 'error' })
            }
            else {
                enqueueSnackbar('Permissions revoked!', { 'variant': 'success' })
                props.cb();
            }
        })
    }


    return (<>
        <Table sx={{ minWidth: '100%' }} aria-label="custom pagination table">
            <TableHead>
                <TableRow>
                    <TableCell align="left"><strong>Permissions</strong></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <Stack direction='column' spacing={1} sx={{ padding: 1 }}>
                    {Object.values(rows).map((permission: IPermission) => (
                        permission.revoked ? (<></>) : (<Chip id={permission.id} label={permission.name} onDelete={() => handleDelete(permission.id)} />)
                    ))}
                </Stack>
            </TableBody>
            <TableFooter>
                <TableRow>
                </TableRow>
            </TableFooter>
        </Table>
    </>)
}

export default GroupPermissionsTable;