import * as React from 'react';
import './groups-table.styles.scss';
import { useState, useEffect } from 'react';
// Material UI Imports
import {
    Table, TableBody, TableCell,  TableRow, TableHead, TablePagination, TableContainer
} from '@mui/material';

// Custom Components
import CreateGroupDialog from '../modal-groups-management/create-group-dialog.component';
import SearchBar from '../search-bar/search-bar.component';
import ErrorBoundary from '../error-boundary/error-boundary.component';
import EditGroupModal from '../modal-groups-management/edit-group-modal.component';


import webHelpers from '../../scripts/helpers';
import { useAppSelector } from '../../app/hooks';
import { useSnackbar } from 'notistack';
interface Group {
    id: string,
    name: string,
    is_security: boolean,
}

export default function GroupsTable() {
    const [page, setPage] = useState<number>(0);
    const [rows, setRows] = useState<Group[]>([]);
    const [search, setSearch] = useState('');
    const [maxPages, setMaxPages] = useState(1);
    const [tableHeight, setTableHeight] = useState('400px')
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const { enqueueSnackbar } = useSnackbar();


    const environment = useAppSelector(state => state.loggedIn.environment)
    const token = useAppSelector(state => state.loggedIn.token)

    let height = window.screen.height;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function searchGroups(search: string) {
        webHelpers.get('/api/management/groups/' + rowsPerPage + '/' + (page +1) + '/' + search, environment, 'auth', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                enqueueSnackbar(`No groups found by the name "${search}", please try another search`, { 'variant': 'error' })
            }
            else {
                setRows(data.groups);
                setMaxPages(data.max_pages);
            }
        });
    }

    useEffect(() => {
        if(search !== ''){
            const requestTimeout = setTimeout(() => {
                searchGroups(search)
            }, 400);
            return () => clearTimeout(requestTimeout);    
        }
        else {
            searchGroups(search);
        }
    }, [search, rowsPerPage]);

    useEffect(() => {
        searchGroups('');
    }, [page, environment, token, environment])

    useEffect(() => {
        if (height > 1340) {
            setTableHeight('750px');
        }
        if (height < 1340) {
            setTableHeight('450px');
        }
    }, [height]);
    
    function createGroup(name: string, isSecurity: boolean) {
        webHelpers.put('/api/management/group', 'auth', environment, { 'name': name, 'is_security': isSecurity }, token, (data: any) => {
            console.log(data);
        })
        let security;
        { isSecurity ? security = 'security ' : security = '' }
        enqueueSnackbar(`New ${security}Group ${name} created!`, { 'variant': 'success' });
        setSearch(name);
    }
    
    return (
        <div className="table-container">
            <h2 className='table-heading'>Group Management</h2>
            <div className="table-top-container">
                <SearchBar placeholder='Search groups..' onChange={setSearch} />

                <div className="table-top-buttons">
                    <CreateGroupDialog cb={createGroup} />
                </div>
                <TablePagination
                    rowsPerPageOptions={[15, 25, 50]}
                    component="div"
                    count={rowsPerPage * maxPages}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    showFirstButton
                    showLastButton
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>

            <ErrorBoundary>

                <TableContainer sx={{height: tableHeight }}>
                <Table sx={{ minWidth: '100%' }} size='small' aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"><strong>Name</strong></TableCell>
                            <TableCell align="left"><strong>Security</strong></TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {rows.map((group) => (
                            <TableRow key={group.id.toString()} sx={{p:0.5}}>
                                <TableCell component="th" scope="row" sx={{p:0.5, paddingLeft: 2}}>
                                    <p>{group.name.toString()}</p>
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="left" sx={{p:0.5}}>
                                    <p>{group.is_security ? 'yes' : 'no'}</p>
                                </TableCell>
                                <TableCell style={{ width: 160 }} align="right" sx={{p:0.5}}>
                                    <EditGroupModal id={group.id} name={group.name} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                </TableContainer> 
            </ErrorBoundary>
        </div>
    );
}
