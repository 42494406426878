import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import webHelpers from '../../../scripts/helpers';
import { useAppSelector } from '../../../app/hooks';
import { useSnackbar } from 'notistack';
import WorkerRoleStepper from './create-worker-stepper.component';

interface ICreateWorkerProps {
    cb: Function,
    userId: string,
    user_name: string,
    refreshRoles: Function,
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '55%',
    height: '65vh',
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
    overflow: 'hidden',
};

export default function CreateWorker(props: ICreateWorkerProps) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const handleClose = (id: string) => {
        if (id !== '') {
            props.cb(id);
            props.refreshRoles();
            setOpen(false);
        }
        else {
            setOpen(false);
            enqueueSnackbar('Worker creation cancelled', { 'variant': 'warning' })
        }

    };
    const [skills, setSkills] = useState();
    const [outboundNumbers, setOutboundNumbers] = useState();
    const environment = useAppSelector(state => state.loggedIn.environment)
    const token = useAppSelector(state => state.loggedIn.token)
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if(open){
            webHelpers.get('/api/skills/definitions', environment, 'zeus-api', token, (data: any) => {
                setSkills(data)
            });
            webHelpers.get('/api/hosted/outboundnumbers', environment, 'zeus-api', token, (data: any) => {
                if (data != undefined) {
                    setOutboundNumbers(data)
                    console.log(data)
                }
                else {
                    enqueueSnackbar(`Unable to fetch outbound numbers`)
                }
            })    
        }
    }, [open])

    const handleBackDropClick = () => {
        enqueueSnackbar('Please finish creating a role before exiting the modal', { 'variant': 'warning' });
    }

    return (
        <>
            <Button variant='outlined' startIcon={<AddIcon />} onClick={handleOpen}>Create Worker</Button>
            <Modal
                open={open}
                onBackdropClick={handleBackDropClick}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="modal-header">
                        <Typography id="modal-modal-title" variant="h5" component="h4" sx={{ p: 1 }}>
                            New Role
                        </Typography>
                        <div className="modal-header-close">
                            <CloseIcon onClick={() => handleClose('')} />
                        </div>
                    </div>

                    <div className="modal-stepper">
                        {skills !== undefined && 
                            <WorkerRoleStepper skills={skills} outbound_numbers={outboundNumbers} cb={handleClose} userId={props.userId} user_name={props.user_name} />
                        }
                    </div>
                </Box>
            </Modal>
        </>
    );
};