import * as React from "react";


import { Box } from "@mui/material";
import Card from '../../components/card/card.component';

import WorkFlowTable from "../../components/workflows-table-search/workflows-table.component";
import WorkflowCreatorDialogComponent from "../../components/workflow-creator-dialog/workflow-creator-dialog.component";
export default function WorkflowPage() {
  return (
    <>
    <Box>

    
      <Card>
        <div className="card-header-container">
          <div className="left-items">
            <h2>Workflow Manager</h2>
          </div>
          <div className="right-items">
            <WorkflowCreatorDialogComponent />
          </div>
        </div>

        <div className="table-container">
          <WorkFlowTable />
        </div>
        <br />
      </Card>
      </Box>
    </>
  );
}
