import * as React from 'react';

import { useState, useEffect } from 'react';
import { useAppSelector } from '../../../app/hooks';
import webHelpers from '../../../scripts/helpers';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import Transition from '../../../assets/animations/slide.transition';
import {
    Detail,
    Skill,
    UserRole,
    Level,
    UserRoleUpdate
} from '../../../assets/global-interfaces/role-management.interfaces';
import SkillChip from '../skill-management/skill-chip.component';
import AddSkill from './role-add-skill.component';
import { ISkill } from '../create-role/create-role-stepper.component';
import {useDictionary} from "../../../scripts/useDictionary";

interface SkillsDialogProps {
    userId: string,
    role: UserRole,
    submitAddedSkills: Function,
    removeSkill: Function,
    refreshRoles: Function,
}

const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: ISkill }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}
const skills: { [skill_id: string]: ISkill } = {};
function SkillsDialog(props: SkillsDialogProps) {
    const [open, setOpen] = useState(false);
    const [role, setRole] = useState(props.role);
    const [allSkills, setAllSkills] = useState();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [skillToDelete, setSkillToDelete] = useState<string>();
    const [userSkills, setUserSkills] = useState<Detail[]>(props.role.details.sort());

    //const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();
    
    
    const token = useAppSelector(state => state.loggedIn.token)
    const environment = useAppSelector(state => state.loggedIn.environment)

    let levels: Level[] = [];
    props.role.details.forEach(detail => {
        levels.push(detail.level)
    })
    
    const { enqueueSnackbar } = useSnackbar();
    function addSkills(additionalSkills: ISkill[]) {
        Object.values(additionalSkills).forEach(skill => {
            console.log("skill:",skill);
            skills[skill.skill_id] = skill;
        });
        updateRole();
    }

    const handleClickOpen = () => setOpen(true)

    const handleClose = () => setOpen(false)
    
    const handleEdit = (skill_id: string, level: Level) => {
        skills[skill_id] = { skill_id, level_id: level.id };
        
        console.log("Previous skills: ", userSkills);
        console.log("Updated skills: ", skills);
        updateRole();
    }
    
    const handleDelete = (id: string) => {
        setConfirmOpen(true);
        setSkillToDelete(id);
    }
    
    function updateRole() {
        let updatedRole : UserRoleUpdate = {
            ...props.role,
            skills: Object.values(skills),
        }
        console.log("Updated role with skills: ", skills);
        // Push up the new skill level
        webHelpers.post('/api/worker/role', environment, 'zeus-api', updatedRole, token, (data: UserRole) => {
            if (data != undefined) {
                setRole(data);
                fetchSkills();
                // setSkills(data.details); 
                enqueueSnackbar('role updated!', {'variant': 'success'});
            }
            else {
                console.log(data);
                //enqueueSnackbar(`${data.status}: Unable to apply changes to the role`, { 'variant': 'error' })
            }
        });
        // Update the client side role
        props.refreshRoles();
    }
    
    const handleConfirm = () => {
        console.log(`Deleting skill ${skillToDelete}`)
        if(skillToDelete !== '' && skillToDelete) {
            //props.removeSkill(skillToDelete);
            removeSkill(skillToDelete);
            setConfirmOpen(false);
        }
    }

    function removeSkill(remove_skill_id: string) {
        for(let remove_skill in skills) {
            if(skills.hasOwnProperty(remove_skill_id)) {
                delete skills[remove_skill_id];
                console.log("Deleted skill", skills[remove_skill_id]);
                updateRole();
            }
        }

    }

    function fetchSkills() {
        // For updating the skills chips on change
        webHelpers.get('/api/worker/roles/' + props.userId, environment, 'zeus-api', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                enqueueSnackbar(`Unable to fetch skills`, { 'variant': 'error' })
            }
            else {
                data.forEach((role: UserRole) => {
                    if (role.id == props.role.id) {
                        Object.values(skills).forEach(skill => {
                            delete skills[skill.skill_id];
                        })
                        setUserSkills(role.details.sort());
                        role.details.forEach((detail: Detail) => {
                            skills[detail.skill_id] =  {skill_id: detail.skill_id, level_id: detail.level_id};
                        });
                        console.log("Found skills and populated with: ", skills);
                    }
                })
            }
        });
    }
    
    useEffect(() => {
        console.log("current skills: ", skills);
        role.details.forEach((detail: Detail) => {
            skills[detail.skill_id] =  {skill_id: detail.skill_id, level_id: detail.level_id};
        });
    }, [skills])

    useEffect(() => {
        if(open && allSkills === undefined) {
            fetchSkills();
            webHelpers.get('/api/skills/definitions', environment, 'zeus-api', token, (data: any) => {
                setAllSkills(data)
            });
        }
    }, [open]);
    
    return (
        <div>
            <Button onClick={handleClickOpen} endIcon={<GroupWorkIcon />}>
                Skills
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth='xl'
            >
                <DialogTitle>{props.role.name}</DialogTitle>
                <DialogContent>
                    {userSkills.length === 0 || allSkills === undefined ? (
                        <p>User doesn't have any skills!</p>

                    ) : (

                        <Stack direction='row' spacing={0.5}>
                            <Stack direction='column' spacing={1} sx={{ padding: 1 }}>
                                {userSkills
                                    .slice(0, userSkills.length / 2)
                                    .map(skill => (
                                        <SkillChip
                                            key={skill.id}
                                            handleEdit={handleEdit}
                                            handleDelete={() => handleDelete(skill.skill_id)}
                                            skill={skill} levels={levels}
                                            allSkills={allSkills}
                                            userId={props.userId}
                                        />
                                    ))}
                            </Stack>
                            <Stack direction='column' spacing={1} sx={{ padding: 1 }}>
                                {userSkills
                                    .slice(userSkills.length / 2, userSkills.length)
                                    .map(skill => (
                                        <SkillChip
                                            key={skill.id}
                                            handleEdit={handleEdit}
                                            handleDelete={() => handleDelete(skill.skill_id)}
                                            skill={skill} levels={levels}
                                            allSkills={allSkills}
                                            userId={props.userId} />
                                    ))}
                            </Stack>
                        </Stack>
                    )}
                </DialogContent>
                <DialogActions>
                    {/*// To Do: Refactor state management of the AddSkill component so that the state is managed by itself, not by the parent*/}
                    {/*// Set up a useEffect hook to fetch the skills when the dialog is opened*/}
                    <AddSkill role={props.role} handleAddSkills={addSkills} updateSkills={fetchSkills} />
                    <Button onClick={handleClose}>Finish</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={confirmOpen}
                TransitionComponent={Transition}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth='xl'
            >
                <DialogTitle>Delete Skill?</DialogTitle>
                <DialogActions>
                    <Button onClick={() => setConfirmOpen(false)}>No</Button>
                    <Button onClick={handleConfirm}>Yes</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default SkillsDialog;