import * as React from 'react';
import { useState } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';


interface IOutboundSelector {
    cb: Function,
    outbound_numbers: OutboundNumber[],
}
interface OutboundNumber {
    id: string,
    name: string,
    phoneNumber: string,
}


export default function OutboundSelector(props: IOutboundSelector) {
    const [outboundNumber, setOutboundNumber] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setOutboundNumber(event.target.value);
    };

    function handleClick(number: OutboundNumber) {
        props.cb(number);
    }

    function concatName(num: string, name: string) {
        return num + ' ' + name;
    }

    return (<>
        <div className="container">
            <FormControl sx={{ m: 1, width: 300, mt: 3 }}>
                <Select
                    displayEmpty
                    value={outboundNumber}
                    onChange={handleChange}
                    input={<OutlinedInput />}
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem disabled value="">
                        <em>Outbound Number</em>
                    </MenuItem>
                    {props.outbound_numbers.map((number) => (
                        <MenuItem
                            key={number.id}
                            value={number.name}
                            onClick={() => { handleClick(number) }}
                        >
                            {concatName(number.name, number.phoneNumber)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    </>)

}