import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Level } from '../../assets/global-interfaces/role-management.interfaces';

interface LevelSelectProps {
    levels: Level[],
    cb: Function,
}

const dummy_level = {
    'id': 'string',
    'key': 'string',
    'name': 'string',
    'order': 0,
    'skill_id': 'string',
}

export default function LevelSelect(props: LevelSelectProps) {
    const [selectedLevel, setSelectedLevel] = useState<Level>(dummy_level);
    const [label, setLabel] = useState<string>('');

    function handleClick(level: Level) {
        setSelectedLevel(level);
        props.cb(level);
        console.log(level)
    }

    const handleChange = (event: SelectChangeEvent) => {
        setLabel(event.target.value);
    };

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormControl margin='none' variant='standard' sx={{ minWidth: 120 }} >

                <InputLabel id="demo-simple-select-label">Level</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={label}
                    label="Level"
                    margin='dense'
                    onChange={handleChange}
                >
                    {props.levels.map(level => (
                        <MenuItem  key={level.id} value={level.id} onClick={() => handleClick(level)}>{level.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}