import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import Transition from '../../assets/animations/slide.transition';
interface ICreateGroupProps {
    cb: Function,
}


export default function CreateGroupDialog(props: ICreateGroupProps) {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState('');
    const [isSecurity, setIsSecurity] = useState(false);

    const handleClickOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleIsSecurityChange = () => setIsSecurity(!isSecurity);

    const handleSubmit = () => {
        props.cb(name, isSecurity);
        setOpen(false);
    }

    const handleNameChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setName(e.target.value);
    }

    return (
        <div>
            <Button variant="outlined" endIcon={<GroupAddIcon />} onClick={handleClickOpen}>
                Create a Group
            </Button>
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
                <DialogTitle>New Group</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter the group name and choose whether they are a security group or not.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={handleNameChange}
                    />
                    <FormControlLabel control={<Radio
                        checked={isSecurity}
                        onClick={handleIsSecurityChange}
                        value="b"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'Security' }}
                    />} label="Security" />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}