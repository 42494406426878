import { Box, Button, Modal, Tab, Tabs, Typography } from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAppSelector } from "../../app/hooks";
import webHelpers from "../../scripts/helpers";
import { useSnackbar } from "notistack";
import StepPanel from "../workflow-steps/workflow-step-panel.component";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CreateStep from '../workflow-step-creator/workflow-step-creator.component';
import './workflows.styles.scss';
import CodeEditorDialog from "../code-editor-dialog/code-editor-dialog.component";
import TimeProfileModal from "../time-profile-modal/time-profile-modal";


interface IWorkflowModalProps {
    id: string;
    initializer_code_id: string;
}
export interface IWorkflowStep {
  id: string;
  created: string;
  created_by_id: string;
  created_by_name: string;
  updated: string;
  updated_by_id: string;
  updated_by_name: string;
  name: string;
  description: string;
  definition_id: string;
  index: number;
  cooldown_time: string;
  estimated_length: string | null;
  maximum_length: string | null;
  tag: string | null;
  execution_type: string;
  code_id: string;
  events: event[];
  twilio_task_type: string | null;
  twilio_task_type_key: string | null;
  time_profile_id: string;
}

export interface event {
  event_id: string;
  step_id: string;
  code_definition: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '45vw',
  height: '65vh',
  borderRadius: "15px",
  bgcolor:'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function WorkflowModal(props: IWorkflowModalProps) {
  const [open, setOpen] = useState(false);
  const [steps, setSteps] = useState<IWorkflowStep[]>();
  const [value, setValue] = useState(1);
  const [initialOpen, setInitialOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState<IWorkflowStep>();
  const handleOpen = () => {
    setOpen(true);
    setInitialOpen(true);
  }
  const handleClose = () => {
    setOpen(false) 
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) =>
    setValue(newValue);

  const token = useAppSelector((state) => state.loggedIn.token);
  const environment = useAppSelector((state) => state.loggedIn.environment);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
     if(open) {
       getStepsDefinition();
     } 
  }, [open] );
  
  function getStepsDefinition() {
    webHelpers.get(
        "/api/workflow/" + props.id + "/steps",
        environment,
        "helios-api",
        token,
        (data: IWorkflowStep[]) => {
          if (data === undefined || data == null) {
            enqueueSnackbar("Unable to fetch steps from API", {
              variant: "error",
            });
          } else {
            setSteps(Object.values(data));
            setCurrentStep(Object.values(data)[value-1])
          }
        }
    ); 
    }

    function editInitializer() {
        
    }
  
  
  useEffect(() => {
   if(steps !== undefined) {
     setCurrentStep(steps[value-1])
   }
 }, [value]) 
  
  return (
    <>
      <Button onClick={handleOpen}>
        Edit
        <SettingsIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="header-row">
            <IconButton onClick={() => setOpen(false)} >
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Editing Workflow: {props.id}
            </Typography>
            
            <div></div>
          </div>
          <div className="tab-button-container">
            <div className="button-container">
              <CreateStep workflow_id={props.id} />
            </div>

            <Tabs
                value={value}
                onChange={handleChange}
                textColor="secondary"
                variant="scrollable"
                scrollButtons="auto"
                indicatorColor="secondary"
                aria-label="secondary tabs example"
                sx={{ marginBottom: "1rem" }}
            >
              {steps !== undefined ? (
                  steps.map((step: IWorkflowStep, index: number) => (
                      <Tab key={step.id} value={index + 1} label={step.name} />
                  ))
              ) : (<></>)}
            </Tabs>  
          </div>
          
     
          {currentStep !== undefined ? 
              (
                  <StepPanel {...currentStep} />
              ) 
          : (<></>)}
          
          <div className="button-holder">
            <div className="button-container">
                <CodeEditorDialog stepFriendlyName='Initializer' code_id={props.initializer_code_id} buttonText='Edit Initializer Code'/>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
