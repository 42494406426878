import * as React from 'react';
import { useState, useEffect } from 'react';
import { useAppSelector } from '../../app/hooks';
import webHelpers from '../../scripts/helpers';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SettingsIcon from '@mui/icons-material/Settings';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import './edit-group-modal.styles.scss';
import GroupPermissionsTable from './groups-permissions-table.component';
import AllAssignablePermissions from './all-permissions-table.component';
import { useSnackbar } from 'notistack';

interface EditGroupProps {
    id: string,
    name: string,
}
const dummy_permissions = [{
    id: '1234',
    name: 'donkey',
    revoked: false,
    sid: 'string',
}]

const EditGroupModal = (props: EditGroupProps) => {
    const [open, setOpen] = useState(false);
    const [permissions, setPermissions] = useState(dummy_permissions);
    const { enqueueSnackbar } = useSnackbar();
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '65%',
        bgcolor: 'background.paper',
        borderRadius: 5,
        boxShadow: 24,
        p: 4,
    };

    const environment = useAppSelector(state => state.loggedIn.environment)
    const token = useAppSelector(state => state.loggedIn.token)

    // const saveChanges = () => enqueueSnackbar('Changes made to group', { 'variant': 'success' })

    function assignPermission(permission_id: string) {
        webHelpers.post('/api/management/permission/assign/' + props.id, environment, "auth", { permission: permission_id }, token, (data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                enqueueSnackbar(`${data.status}: Unable to assign this permission.`, { 'variant': 'error' })
            }
            else {
                fetchPermissions();
                enqueueSnackbar(`Permission successfully assigned!`, { 'variant': 'success' })
            }
        });
    }
    const handleDelete = () => {
        fetchPermissions();
    }
    const handleOpen = () => {
        webHelpers.get('/api/management/group/' + props.id + '/' + 'permissions', environment, 'auth', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                enqueueSnackbar(`Error Code ${data.status}:Unable to access this group's properties`, { 'variant': 'error' })
            }
            else {
                setPermissions(data)
                setOpen(true);
            }
        });
    };

    function fetchPermissions() {
        webHelpers.get('/api/management/group/' + props.id + '/' + 'permissions', environment, 'auth', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                enqueueSnackbar(`Error Code ${data.status}:Unable to access this group's properties`, { 'variant': 'error' })
            }
            else {
                setPermissions(data)
            }
        });
    }

    const handleClose = () => setOpen(false);

    return (<>
        <div>
            <Button onClick={handleOpen}>Edit<SettingsIcon /></Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className="modal-header">
                        <Typography id="modal-modal-title" variant="h4" component="h2">
                            Editing Group: {props.name}
                        </Typography>
                        <div className="modal-header-close">
                            <CloseIcon onClick={handleClose} />
                        </div>
                    </div>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        <Box sx={{ width: '100%', height: '70vh' }}>
                            <div className="groups-tables-container">
                                <div className="groups-permissions-table-owned">
                                    <GroupPermissionsTable cb={fetchPermissions} permissions={permissions} />
                                </div>
                                <div className="groups-permissions-table-unowned">
                                    <AllAssignablePermissions id={props.id} cb={assignPermission} />
                                </div>
                            </div>

                        </Box>
                    </Typography>
                </Box>
            </Modal>
        </div>
    </>)
}

export default EditGroupModal;