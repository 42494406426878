import * as React from 'react';
import './form-input.styles.scss';
interface InputBoxProps {
    placeholder:string,
    onChange: Function, 
    type: string,
}

const InputBox = (props: InputBoxProps) => {
    const handleChange = ((e: { target: { value: any; }; }) => {
        props.onChange(e.target.value)
    }
    )

    return (
        <div className='group'>
            <input className='form-input' type={props.type} onChange={handleChange} />
            <label className='form-input-label'>{props.placeholder}</label>
        </div>
        )
}

export default InputBox;