import * as React from 'react';
import { useState } from 'react';
import { useAppSelector } from '../../app/hooks';
import '../modal/modal.styles.scss';
import { UserRole, dummyRoles } from '../../assets/global-interfaces/role-management.interfaces';

// Third party stuff
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import SettingsIcon from '@mui/icons-material/Settings';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import AddIcon from "@mui/icons-material/Add";

// Custom Components
import RoleManagement from '../modal-role-management/roles-table.component';
import PermissionsTab from '../user-permissions-tab/permissions-tab.component';
import GroupsTab from '../user-groups-tab/groups-tab.component';

// Helpers
import { useSnackbar } from 'notistack';
import webHelpers from '../../scripts/helpers';

import {
    InputLabel,
    List,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell, TableContainer,
    TableFooter,
    TableHead, TablePagination,
    TableRow,
} from "@mui/material";
import ErrorBoundary from "../error-boundary/error-boundary.component";
import SearchBar from "../search-bar/search-bar.component";
import WorkflowRow from "../workflows-table-search/workflow.component";
import '../workflows-table-search/workflows.styles.scss';
import CustomTablePagination from "../pagination-buttons/pagination-buttons.component";

import { validateLocaleAndSetLanguage } from "typescript";



interface IEditNameserver {
    id: string,
    name: string,
    email: string,
    cb: Function,
}
export interface IAssembly {
    assembly_name: string;
    is_default: boolean;
}
const defaultWorkflow: IAssembly[] = [
    {
        assembly_name: "Test.Package",
        is_default: true,
    }
];

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '60%',
    width: '65%',
    overflowY: 'scroll',
    bgcolor: 'background.paper',
    borderRadius: 5,
    boxShadow: 24,
    p: 4,
};

export default function AssembliesModal(props: any) {
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState(1);
    const [groups, setGroups] = useState([]);
    const [roles, setRoles] = useState<UserRole[]>(dummyRoles);
    const [rows2, setRows2] = useState<IAssembly[]>(defaultWorkflow);
    const [newNamespace, setNewNamespace] = useState<string>("Enter Namespace");

    const { enqueueSnackbar } = useSnackbar();
    //@ts-ignore
    const environment = useAppSelector(state => state.loggedIn.environment)
    //@ts-ignore
    const token = useAppSelector(state => state.loggedIn.token)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => setValue(newValue)


    // Grabs data to fill out seperate tabs
    const handleOpen = () => {
        webHelpers.get('/api/code/config/assemblies', environment, 'helios-api', token, (data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                enqueueSnackbar('unable to fetch workflows', { 'variant': 'error' })
            }
            else {
                console.log("namespaces: ", data);
                //@ts-ignore
                setRows2(Object.values(data));
                setOpen(true);
                //setMaxPages(data.maximum_pages)
            }
        })

        // webHelpers.get('/api/worker/roles/' + props.id, environment, 'zeus-api', token, (data: any) => {
        //     if (data === undefined || data === null || data.status !== undefined) {
        //         console.log('bad fetch call');

        //         enqueueSnackbar(`${data.status}: Unable to get information for this user`, {'variant': 'error'})
        //         // Some fallback ui needed here.
        //     }
        //     else {
        //         setRoles(data);
        //         setOpen(true);
        //     }
        // });
    };

    function saveChanges() {
        console.log('saved changes');
    };
    function handleClose() {
        setOpen(false);
    }
    const handleProfileChange = (event: SelectChangeEvent) => {
        console.log("event.target:", event.target.value);
        setNewNamespace(event.target.value);
    }
    const handleSubmit = () => {
        props.cb(newNamespace);
        setOpen(false);
    }
    return (
        <>
            <Button className='button-test' onClick={handleOpen}><AddIcon />Add new Assembly</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="modal-test"
            >
                <Box sx={style} className="modal-test">
                    <div className="modal-header">
                        <Typography id="modal-modal-title" variant="h4" component="h2">
                            Add New Assembly:
                        </Typography>
                        {/* Add an edit department bit here at some point */}
                        <div className="modal-header-close">
                            <CloseIcon onClick={handleClose} />
                        </div>
                    </div>

                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Select a new assemby from the drop down box below
                    </Typography>
                    <InputLabel id="demo-simple-select-label">Assembly</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={newNamespace}
                        onChange={handleProfileChange}
                        autoWidth={true}
                        
                    >
                        {rows2.map((workflow: IAssembly) => (
                            (workflow.is_default ? 
                                <p></p>
                                :
                            <MenuItem value={workflow.assembly_name}>{workflow.assembly_name}</MenuItem>
                        )))}
                    </Select>
                    <Button className="modal-button-submit" onClick={handleSubmit} variant="contained"><AddIcon/>add to code</Button>


                </Box>
            </Modal>
        </>
    );
}