import * as React from 'react';
import { useState, useEffect, useReducer } from 'react';
import { UserRole } from '../../assets/global-interfaces/role-management.interfaces';
import {
    Table, TableBody, TableCell,
    TableRow, TableHead
} from '@mui/material';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import './role-management.styles.scss';
import { useSnackbar } from 'notistack';
import webHelpers from '../../scripts/helpers';
import { useAppSelector } from '../../app/hooks';
import Role from './role.component';
import CreateRole from './create-role/create-role.component';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import SpeakerIcon from '@mui/icons-material/Speaker';
import Tooltip from '@mui/material/Tooltip';
import CreateWorker from './create-worker/create-worker-modal.component';
import { act } from '@testing-library/react';


interface RoleManagementProps {
    roles: UserRole[],
    cb: Function,
    user_id: string,
    user_name: string,
}



const RoleManagement = (props: RoleManagementProps) => {
    const [activeRole, setActiveRole] = useState<string>();
    const [roles, setRoles] = useState<UserRole[]>(props.roles);
    const [activeWorker, setActiveWorker] = useState();
    const [workerExists, setWorkerExists] = useState(false);
    const [activeWorkerId, setActiveWorkerId] = useState<string>('');
    const { enqueueSnackbar } = useSnackbar();
    const token = useAppSelector(state => state.loggedIn.token)
    const environment = useAppSelector(state => state.loggedIn.environment)

    function getRoles() {
        
        webHelpers.get('/api/worker/roles/' + props.user_id, environment, 'zeus-api', token, (data: any) => {
            if (data === undefined || data === null || data.status !== undefined) {
                enqueueSnackbar(`${data.status}: Unable to fetch roles`, { 'variant': 'error' })
            }
            else {
                setRoles(data);
                console.log("setting roles")
            }
        })
    }
    // Takes changes applied to a locally stored role and pushes them to the database
    function handleSetActive(id: string) {
        console.log("setting active");
        console.log("id = ", id);
        console.log("/api/worker/role/"+id+"/set", environment)
        
        webHelpers.post('/api/worker/role/' + id + '/set', environment, 'zeus-api', {}, token, (data: any) => {
            if (data.status === undefined) {
                enqueueSnackbar('role successfully set to active!', { 'variant': 'success' });
                
                
                
            }
        })
        getRoles();
    }
    // Removing a role
    function handleDelete(id: string) {
        if (roles.length == 1) {
            enqueueSnackbar("Cannot delete a user's last role!", { 'variant': 'warning' })
        } else {
            webHelpers.delete('/api/worker/role/' + id, 'zeus-api', environment, token, (data: any) => {
                if (data.status != 200) {
                    enqueueSnackbar(`${data.status}: Unable to remove this role.`, { 'variant': 'error' })
                }
                else {
                    enqueueSnackbar('Role successfully deleted', { 'variant': 'success' })
                    getRoles();
                    props.cb();
                }
            })
        }
    }

    function findWorker(id: string) {
        webHelpers.get('/api/worker/' + id, environment, 'zeus-api', token, (data: any) => {
            if (data === null) {
                // No worker exists
                setWorkerExists(false);
                return false;
            }
            else {
                setActiveWorker(data);
                setActiveRole(data.role_id);
                setActiveWorkerId(data.id);
                console.log(data);
                setWorkerExists(true);
                return true;
            }
        })
    }
    useEffect(() => {
        if (!workerExists) {
            findWorker(props.user_id);
        }
    }, [])
    if (!workerExists) {
        return (
            <>
                <div className="no-worker-container">
                    <div className="no-worker-left">
                        <NoAccountsIcon />
                    </div>
                    <div className="no-worker-right">
                        <p>No Worker Found!</p>
                    </div>
                </div>

                <div className="button-tray">
                    {/* Needs to be a dialog component, force creation of one role */}
                    <CreateWorker cb={findWorker} userId={props.user_id} user_name={props.user_name} refreshRoles={getRoles} />
                </div>
            </>
        );
    }

    if (Object.values(props.roles).length === 0 || activeWorkerId === '') {

        return (
            <>
                <div className="no-worker-container">
                    <div className="no-worker-left">
                        <NoAccountsIcon />
                    </div>
                    <div className="no-worker-right">
                        <p>User has a worker but no roles! This shouldn't happen! Please create one.</p>
                    </div>
                </div>

                <div className="button-tray">
                    {/* Needs to be a dialog component, force creation of one role */}
                    <CreateRole worker_id={activeWorkerId} cb={getRoles} />
                </div>
            </>

        )
    }
    else {
        // Check which role is active by pinging the worker
        return (
            <div>

                <Table sx={{ minWidth: '100%' }} size='small' aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"><strong>Role</strong></TableCell>
                            <TableCell align="left"><strong>Skills</strong>
                            </TableCell>
                            <TableCell align="left">
                                <Tooltip title='Auto Answer' placement="top">
                                    <PhoneCallbackIcon /></Tooltip>
                            </TableCell>
                            <TableCell align="left">
                                <Tooltip title='Allow Aux' placement="top">
                                    <SpeakerIcon />
                                </Tooltip>
                            </TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>

                        {Object.values(roles).map((role) => (
                            <Role key={role.id} role={role} handleDelete={handleDelete} handleSetActive={handleSetActive} activeRoleId={activeRole} cb={getRoles} userId={props.user_id} />

                        ))}
                    </TableBody>
                </Table>
                <div className="button-tray">
                    <CreateRole worker_id={roles[0].worker_id} cb={getRoles} />
                </div>
            </div>
        );
    }
}
export default RoleManagement;