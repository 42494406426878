import * as React from 'react';
import { useState, useEffect } from 'react';
import SearchBar from '../search-bar/search-bar.component';
import { IPermissionRegular } from '../../assets/global-interfaces/permission.interfaces';
import webHelpers from '../../scripts/helpers';
import Button from '@mui/material/Button';
import { useAppSelector } from '../../app/hooks';
import { useSnackbar } from 'notistack';
import {
    Table, TableBody, TableCell, TableFooter,
    TableRow, TableHead
} from '@mui/material';
import CustomTablePagination from '../pagination-buttons/pagination-buttons.component';

interface IAllPermissionsTableProps {
    handleAssign: Function,
}


const dummy_permissions: IPermissionRegular[] = [{ 'id': '/some/permission/here', 'name': 'some dummy permission' }]




export default function AllPermissionsTable(props: IAllPermissionsTableProps) {
    const [page, setPage] = useState(1);
    const [rows, setRows] = useState<IPermissionRegular[]>(dummy_permissions);
    const [search, setSearch] = useState('');
    const [maxPages, setMaxPages] = useState(1);
    const { enqueueSnackbar } = useSnackbar();
    let environment = useAppSelector(state => state.loggedIn.environment)
    const token = useAppSelector(state => state.loggedIn.token)

    const prevPage = () => { page === 1 ? (enqueueSnackbar('Already on the first page!', { 'variant': 'error' })) : (setPage(page - 1)) }
    const nextPage = () => { rows.length < 7 ? (enqueueSnackbar('On last page already!', { 'variant': 'error' })) : (setPage(page + 1)) }

    function searchPermissions(search: string) {
        webHelpers.get('/api/management/permissions/7/1/' + search, environment, 'auth', token, (data: any) => {
            if (data.status === 200 || data.status === undefined) {
                setRows(Object.values(data.permissions));
                console.log(data.permissions);
            }
            else {
                enqueueSnackbar(`${data.status}: Unable to find permissions under name ${search}`, { 'variant': 'error' })
            }
        });
    }
    useEffect(() => {
        webHelpers.get('/api/management/permissions/7/' + page, environment, 'auth', token, (data: any) => {
            if (data.status === 200 || data.status === undefined) {
                setRows(data.permissions);
                setMaxPages(data.max_pages);
            }
            else {
                enqueueSnackbar(`${data.status}: Unable to find permissions under name ${search}`, { 'variant': 'error' })
                setSearch('');
            }
        })
    }, [page, environment, enqueueSnackbar, token]);

    useEffect(() => {
        if(search !== ''){
            let customTimeout = setTimeout(() => {
                searchPermissions(search)
            }, 500);
            return () => clearTimeout(customTimeout);    
        }
    }, [search]);
    const emptyRows =
        (7 - rows.length)

    return (
        <>
            <div className="table-container">
                <div className="table-header">
                    <SearchBar placeholder={'Search Permissions..'} onChange={setSearch} />

                </div>
                <Table sx={{ minWidth: '100%' }} size='small' aria-label="custom pagination table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center"><strong>ID</strong></TableCell>
                            <TableCell align="center"><strong>Description</strong></TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((permission: IPermissionRegular) => (
                            <TableRow key={permission.id}>
                                <TableCell className='table-text' sx={{ width: '1fr', mr: 1 }} component="th" scope="row">
                                    <p className="cell-data">{permission.id}</p>
                                </TableCell>
                                <TableCell className='table-text' sx={{ width: 450 }} align="left">
                                    <p className="cell-data">{permission.name}</p>
                                </TableCell>
                                {/* Some button that fires the assign callback function and passes in permission id */}
                                <TableCell >
                                    <td>
                                        <Button onClick={() => props.handleAssign(permission.id)} >Assign</Button>
                                    </td>
                                </TableCell>
                            </TableRow>
                        ))}

                        {emptyRows > 0 && (
                            <TableRow
                                style={{
                                    height: 61 * emptyRows,
                                }}
                            >
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <CustomTablePagination
                                goFirst={() => setPage(1)}
                                onPrev={prevPage}
                                onNext={nextPage}
                                goLast={() => setPage(maxPages)}
                            />

                        </TableRow>
                    </TableFooter>
                </Table>

            </div>
        </>
    )
}