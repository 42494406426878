import * as React from 'react';
import './permissions-management.styles.scss';
import Card from '../../components/card/card.component';
import PermissionsTable from '../../components/permissions-management-table/permissions-management-table.component';
import CreatePermission from '../../components/permissions-management-table/create-permission-dialog.component';
const PermissionsManagement = () => {

    return (<>
        <Card>
            <div className="card-content">
                <div className="card-header-container">
                    <div className="left-items">
                        <h2>Permissions Management</h2>
                    </div>
                    <div className="right-items">
                        <CreatePermission />
                    </div>
                </div>
                <div className="card-content-body">
                    <PermissionsTable />
                </div>
            </div>
        </Card>

    </>)
}

export default PermissionsManagement;