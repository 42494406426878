import * as React from 'react';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import LogoutIcon from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import { useNavigate } from 'react-router-dom';
import { store } from '../../app/store';
import { toggle, updateUser } from '../../features/loggedIn/loggedInSlice';
import {updateToken} from '../../features/token/tokenSlice';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
// Adding navigation for the burger menu can be done in the in-line onClick functions here for each option


export default function SpeedDialTooltipOpen(props: any) {
    let navigate = useNavigate();
    const actions = [
        { icon: <PersonIcon onClick={() => navigate('/management/staff')} />, name: 'Staff' },
        { icon: <GroupsIcon onClick={() => navigate('./management/groups')} />, name: 'Groups' },
        { icon: <BubbleChartIcon onClick={() => navigate('./management/permissions')} />, name: 'Permissions' },
        {
            icon: <LogoutIcon onClick={() => {
                store.dispatch({ type: toggle });
                store.dispatch({ type: updateToken, payload: '' });
                store.dispatch({ type: updateUser, payload: '' });
                navigate('../../signIn');
            }
            } />, name: 'Sign Out'
        },
    ];
    return (
        <Box sx={{ transform: 'translateZ(0px)', flexGrow: 1 }}>

            <Box sx={{ position: 'fixed', mt: 3, height: 320 }}>
                <SpeedDial
                    ariaLabel="SpeedDial playground example"
                    icon={<MenuIcon />}
                    direction={'down'}
                >
                    {actions.map((action) => (
                        <SpeedDialAction
                            tooltipOpen
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                        />
                    ))}
                </SpeedDial>
            </Box>
        </Box>
    );
}