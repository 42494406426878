import React from 'react';

interface ErrorBoundaryProps {

}

interface ErrorBoundaryState {
    error: any;
    hasError: Boolean;
    errorInfo: any;
}



class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: any) {
        super(props);
        this.state = {
            error: null,
            hasError: false,
            errorInfo: null
        };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        this.setState({
            error: error,
            errorInfo: errorInfo,
            hasError: true
        })
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="error-container">
                    <details>
                        <h1>Something went wrong.</h1>
                        {this.state.error && this.state.error.toString()}
                        {this.state.errorInfo.componentStack}
                    </details>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
