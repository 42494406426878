import * as React from 'react';
import {useState , useEffect} from 'react';
import webHelpers from "../../scripts/helpers";
import {useAppSelector}from '../../app/hooks';
import {useSnackbar} from 'notistack';
import {
    Avatar,
    Button,
    Dialog,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Typography
} from "@mui/material";
import EventCodeEditorComponent from "./event-code-editor.component";
import CreateNewEventDialogComponent from "./create-new-event-dialog.component";


interface IEventsDialogProps {
   events: PropEvent[],
    step_id: string,
}

interface PropEvent {
    event_id: string,
    step_id: string,
}

interface Event {
    step_id: string,
    event_id: string,
    code_id:string,
}

function EventsDialog(props: IEventsDialogProps) {
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState(props.events);
  const [realEvents, setRealEvents] = useState<Event[]>([]);
  
 const token = useAppSelector(state => state.loggedIn.token);
 const environment = useAppSelector(state => state.loggedIn.environment);
 const {enqueueSnackbar} = useSnackbar();
  
  const handleClickOpen = () => {
        setOpen(true);
  };
  
  const handleClose = () => {
       setOpen(false);
  }
  
  function getEvents() {
      webHelpers.get('/api/workflow/step/' + props.step_id + '/events', environment, 'helios-api', token, (data:any) =>  {
         if(data === undefined || data.response === 400) {
             enqueueSnackbar('unable to update events');
         }
         else {
             setRealEvents(data);
         }
     }) 
  }
  
  useEffect(() => {
      if(open){
          console.log(props.step_id)
          getEvents();       
      }
  }, [open])

  return(
  <>
        <Button variant="outlined" sx={{height:'2.5rem'}} onClick={handleClickOpen}>
           Edit Events 
        </Button>
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>Events on this Step</DialogTitle>
            <List sx={{ pt: 0, display: 'flex', flexDirection:'column', justifyContent:'center', alignContent: 'center', alignItems:'center' }}>
                {props.events.length === 0 ? (
                    <Typography sx={{justifySelf:'center', alignSelf:'center', padding: '1rem'}}>No Subscribers found</Typography>  
                    ) :
                    realEvents.map((event:Event) => (
                               <EventCodeEditorComponent step_id={props.step_id} code_id={event.code_id} cb={getEvents}  key={event.event_id} event_id={event.event_id} />
                            ))}
               <CreateNewEventDialogComponent cb={getEvents} step_id={props.step_id}  />
            </List>
        </Dialog>
        
        
  </>)
}
export default EventsDialog;