
import * as React from "react";
import { useState, useEffect } from "react";
import ErrorBoundary from "../error-boundary/error-boundary.component";
import SearchBar from "../search-bar/search-bar.component";
import WorkflowRow from "../workflows-table-search/workflow.component";
import { useAppSelector } from "../../app/hooks";
import webHelpers from "../../scripts/helpers";
import { useSnackbar } from 'notistack';
import CustomTablePagination from "../pagination-buttons/pagination-buttons.component";
import { validateLocaleAndSetLanguage } from "typescript";
import "./display-namespaces.style.scss";
import NamespaceModal from "./namespace-modal";
import AssembliesModal from "./assemblies-modal";
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import Chip from '@material-ui/core/Chip';

export interface INamespace {
    namespace: string;
    is_auto: boolean;
}
const defaultWorkflow: INamespace[] = [
    {
        namespace: "Test.Package",
        is_auto: true,
    }
];
export interface IAssembly {
    code_id: string;
    assembly: string;
}
const defaultAssembly: IAssembly[] = [
    {
        code_id: "test",
        assembly: "test-assembly"
    }
]
export interface IAppliedAssembly {
    assembly: string;
    is_auto: boolean;
}
const defaultAppliedAssembly: IAppliedAssembly[] = [
    {
        assembly: "test",
        is_auto: false,
    }
]
export interface IDefaultNamespaces {
    name_space: string;
    is_default: boolean;
}
const defaultDefaultNamespaces: IDefaultNamespaces[] =[
    {
        name_space: "test",
        is_default: true,
    }
]
export interface IDefaultAssemblies {
    assembly_name: string;
    is_default: boolean;
}
const defaultAssemblies: IDefaultAssemblies[] = [
    {
        assembly_name: "test",
        is_default: true,
    }
]
export default function DisplayNamespaces(props: any) {


    const [search, setSearch] = useState("");
    const [rows, setRows] = useState<INamespace[]>(defaultWorkflow);
    const [rows2, setRows2] = useState<INamespace[]>(defaultWorkflow);
    const [rows3, setRows3] = useState<IAssembly[]>(defaultAssembly);
    const [rows4, setRows4] = useState<IAppliedAssembly[]>(defaultAppliedAssembly);
    const [default_namespaces, setDefaultNamespaces] = useState<IDefaultNamespaces[]>(defaultDefaultNamespaces);
    const [default_assemblies, setDefaultAssemblies] = useState<IDefaultAssemblies[]>(defaultAssemblies);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    const [tableHeight, setTableHeight] = useState('400px')
    const [maxPages, setMaxPages] = useState<number>(1);


    const { enqueueSnackbar } = useSnackbar();
    const environment = useAppSelector(state => state.loggedIn.environment);
    const token = useAppSelector(state => state.loggedIn.token);

    let height = window.screen.height;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    function searchWorkflows() {
        console.log("props:", props);
        console.log('/api/code/' + props.code_id + "/namespaces/applied", environment, 'helios-api', token);
        webHelpers.get('/api/code/' + props.code_id + "/namespaces/applied", environment, 'helios-api', token, (data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                enqueueSnackbar('unable to fetch workflows', { 'variant': 'error' })
            }
            else {
                //@ts-ignore
                setRows(Object.values(data));
                //setMaxPages(data.maximum_pages)
            }
        })
        //get namespaces for code
        webHelpers.get('/api/code/' + props.code_id + '/namespaces', environment, 'helios-api', token, (data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                enqueueSnackbar('unable to fetch workflows', { 'variant': 'error' })
            }
            else {
                //@ts-ignore
                setRows2(Object.values(data));
                //setMaxPages(data.maximum_pages)
            }
        })
        webHelpers.get('/api/code/' + props.code_id + '/assemblies', environment, 'helios-api', token, (data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                enqueueSnackbar('unable to fetch workflows', { 'variant': 'error' })
            }
            else {
                setRows3(Object.values(data));
            }
        })
        webHelpers.get('/api/code/' + props.code_id + '/assemblies/applied', environment, 'helios-api', token, (data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                enqueueSnackbar('unable to fetch workflows', { 'variant': 'error' })
            }
            else {
                setRows4(Object.values(data));
            }
        })
        webHelpers.get('/api/code/config/namespaces',environment,'helios-api', token, (data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                enqueueSnackbar('unable to fetch workflows', {'variant':'error'})
            }
            else{
                setDefaultNamespaces(Object.values(data));
            }
        })
        webHelpers.get('/api/code/config/assemblies', environment, 'helios-api', token, (data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                enqueueSnackbar('unable to fetch workflows', {'variant':'error'})
            }
            else{
                setDefaultAssemblies(Object.values(data));
            }
        })
        
    }
    const handleDeleteNamespace = (event: INamespace) => () => {
        console.log("/api/code/"+props.code_id+"/namespace/"+event.namespace,"helios-api",environment);
        webHelpers.delete("/api/code/"+props.code_id+"/namespace/"+event.namespace,"helios-api",environment,token, (data: any) => {
                console.log("sucessfully deleted ", event.namespace);
                searchWorkflows();
        })
        console.log("delete");
        searchWorkflows();
    }
    const handleDeleteAssembly = (event: IAppliedAssembly) => () => {
        console.log("/api/code/"+props.code_id+"/assembly/"+event.assembly,"helios-api",environment);
        webHelpers.delete("/api/code/"+props.code_id+"/assembly/"+event.assembly,"helios-api",environment,token, (data: any) => {
                console.log("sucessfully deleted ", event.assembly);
                searchWorkflows();
        })
        console.log("delete");
        searchWorkflows();
    }
    useEffect(() => {
        searchWorkflows();
    }, [page, environment]);

    const refreshTimeProfile = () => {
        //searchWorkflows("");
        console.log("refreshed");
    }
    useEffect(() => {
        const requestTimeout = setTimeout(() => {
            searchWorkflows()
        }, 500);
        return () => clearTimeout(requestTimeout);
    }, [search, rowsPerPage]);

    const addNewNamespace = (payload: string) => {
        let payloadToSend = {
            namespace: payload
        }
        console.log('/api/code/'+props.code_id+"/namespace",'helios-api',environment, payloadToSend, token);
        webHelpers.put('/api/code/'+props.code_id+"/namespace/",'helios-api',environment, payloadToSend, token, (data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                enqueueSnackbar('unable to add new namespace', {'variant':'error'})
            }
            else{
                console.log("default namespaces : ", data);
                searchWorkflows();
            }
        })
    }
    const addNewAssembly = (payload: string) => {
        let payloadToSend = {
            assembly_name: payload
        }
        console.log('/api/code/'+props.code_id+"/assembly",'helios-api',environment, payloadToSend, token);
        webHelpers.put('/api/code/'+props.code_id+"/assembly/",'helios-api',environment, payloadToSend, token, (data: any) => {
            if (data === undefined || data === null || data.status === 400) {
                enqueueSnackbar('unable to add new nameserver', {'variant':'error'})
            }
            else{
                console.log("default assemblies : ", data);
                searchWorkflows();
            }
        })
    }
    return (
        <>
            <div className="table-container">
                <div className="search-bar-centered">

                </div>
                <ErrorBoundary>
                    {rows !== defaultWorkflow ? (
                        <>
                            <Box className="test-box">
                            <p className="applied-assembly-title">Applied Assemblies for Code</p>
                            
                            <p className="applied-namespace-subtitle">Enabled by Default</p>
                            {rows4.map((workflow: IAppliedAssembly) => (
                                (workflow.is_auto ? 
                                    <>
                                    <Chip label={workflow.assembly} variant="default" className="namespace-listing"/>
                                    <br></br>
                                    <br></br>
                                    </>
                                 :  <p></p>)
                            ))}
                            <p className="applied-namespace-subtitle">
                                <br></br>
                                Selected by User
                                </p>
                            {rows4.map((workflow: IAppliedAssembly) => (
                                (workflow.is_auto ?
                                    <p></p>
                                    :
                                    <>
                                    <Chip 
                                    label={workflow.assembly} 
                                    variant="default"  
                                    onDelete={handleDeleteAssembly(workflow)}
                                    className="namespace-listing" 
                                    color="primary"
                                    />
                                    <br></br>
                                    <br></br>
                                    </>
                                )))}
                            <AssembliesModal className="add-button" cb={addNewAssembly} />
                            <p className="applied-namespace-title">Applied Namespaces</p>
                            <p className="applied-namespace-subtitle">Enabled by Default</p>
                            {rows.map((workflow: INamespace) => (
                                (workflow.is_auto ? 
                                    <>
                                    <Chip label={workflow.namespace} variant="default" className="namespace-listing"/>
                                    <br></br>
                                    <br></br>
                                    </>
                                 :  <p></p> )
                                
                            ))}
                            <p className="applied-namespace-subtitle">
                                <br></br>
                                Selected by User
                                </p>
                            
                            {rows.map((workflow: INamespace) => (
                                (workflow.is_auto ?
                                    <p></p>
                                    :
                                    <>
                                    <Chip 
                                    label={workflow.namespace} 
                                    variant="default"  
                                    onDelete={handleDeleteNamespace(workflow)}
                                    className="namespace-listing" 
                                    color="primary"
                                    />
                                    <br></br>
                                    <br></br>
                                    </>
                            )))}
                            <NamespaceModal className="add-button" cb={addNewNamespace} />
                            
                            {/* <p className="applied-namespace-title">Default Namespaces</p>
                            {default_namespaces.map((workflow: IDefaultNamespaces) => (
                                <p>{workflow.name_space}, {String(workflow.is_default)}</p>
                            ))}
                            <p className="applied-namespace-title">Default Assemblies</p>
                            {default_assemblies.map((workflow: IDefaultAssemblies) => (
                                <p>{workflow.assembly_name}, {String(workflow.is_default)}</p>
                            ))} */}
                            
                            </Box>
                        </>

                    ) : (
                        <></>
                    )
                    }
                </ErrorBoundary>
            </div>
        </>
    );
}
