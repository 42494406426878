// React Imports
import * as React from 'react';
import { useState, useEffect, memo } from 'react';
import webHelpers from '../../scripts/helpers';
// Component Imports
import RefreshIcon from '@mui/icons-material/Refresh';
import ErrorBoundary from '../../components/error-boundary/error-boundary.component';
import IconButton from '@mui/material/IconButton';
import { Tooltip } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import FormLoading from '../../components/form-loading/form-loading-feedback.component';
import CreateUserDialog from '../../components/create-user-dialog/create-user-dialog.component';

import Card from '../../components/card/card.component';
// Style and Static file Imports
import './staff.styles.scss';
import UsersTable from '../../components/user-table-pagination/users-table.component';
import { useAppSelector } from '../../app/hooks';
import { useSnackbar } from 'notistack'
const Staff = memo(function StaffPage() {
    const [backdrop, setBackdrop] = useState(false);

    let token = useAppSelector(state => state.loggedIn.token)

    let environment = useAppSelector(state => state.loggedIn.environment)

    const { enqueueSnackbar } = useSnackbar();
    const syncHandler = () => {
        setBackdrop(true);
        webHelpers.post('/api/workers/roles/sync', environment, "zeus-api", {}, token, (data: any) => {
            if (data.status === 200) {
                enqueueSnackbar("Skills successfully synced with Twilio", { "variant": "success" });
            }
            else {
                enqueueSnackbar("Unable to Sync skills with Twilio", { "variant": "success" });
            }
        });
        setTimeout(() => { setBackdrop(false) }, 5000);

    };
    
    const handleClose = () => {
        setBackdrop(false);
    };

        return (
            <ErrorBoundary>
                {backdrop ?
                    (
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={backdrop}
                            onClick={handleClose}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    ) : (
                        <></>
                    )}

                <Box >

                    <Card >
                        <div className="card-header-container">
                            <div className="left-items">
                                <h2>Staff Management</h2>
                            </div>
                            <div className="right-items">
                                <Tooltip title='Force Attribute Sync'>
                                    <IconButton aria-label="refresh" color="secondary" onClick={syncHandler}>
                                        <RefreshIcon />
                                    </IconButton>
                                </Tooltip>
                                <CreateUserDialog />
                            </div>
                        </div>

                        <div className="table-container">
                            <UsersTable />
                        </div>
                        <br />
                    </Card>

                </Box>

            </ErrorBoundary >
        );
});

export default Staff;