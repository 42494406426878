import * as React from 'react';
import '../create-role/create-role.styles.scss';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import ChooseSkills from '../choose-skills.component';
import { useAppSelector } from '../../../app/hooks';
import webHelpers from '../../../scripts/helpers';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import {Skill, Level, Detail} from '../../../assets/global-interfaces/role-management.interfaces';
import Chip from '@mui/material/Chip';
import OutboundSelector from '../../outbound-selector/outbound-selector.component';
import Divider from '@mui/material/Divider';

// The titles for each step
const steps = ['Enter a name', 'Select one or more skills', 'Add an Outbound number'];

interface StepperProps {
    skills: Skill[];
    outbound_numbers: any,
    userId: string,
    user_name: string,
    cb: Function,
}

export interface IAddedSkill {
    id: string,
    level: Level,
    name: string,
}
interface IOutboundNumber {
    id: string,
    name: string,
    number: string,
}
export interface ISkill {
    skill_id: string,
    level_id: string,
}

export default function WorkerRoleStepper(props: StepperProps) {
    const [activeStep, setActiveStep] = useState(0);
    const [name, setName] = useState<string>('');
    const [skills, setSkills] = useState<Skill[]>([]);
    const [potentialSkills, setPotentialSkills] = useState<IAddedSkill[]>([]);
    const [potentialOutboundNumber, setPotentialOutboundNumber] = useState<IOutboundNumber>();
    const [workerId, setWorkerId] = useState<string>();

    const token = useAppSelector(state => state.loggedIn.token)
    const environment = useAppSelector(state => state.loggedIn.environment)
    const { enqueueSnackbar } = useSnackbar();

    const handleNext = () => {
        if (activeStep === 0 && name === '') {
            enqueueSnackbar('You must enter a name for the role!', { 'variant': 'warning' })
        }
        else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    function createRole(id: string) {
        let skills: ISkill[] = [];
        potentialSkills.forEach(skill => {
            let newSkill = { 'skill_id': skill.id, 'level_id': skill.level.id };
            skills.push(newSkill);
        })
        if (name !== '' && potentialOutboundNumber !== undefined) {
            let data = {
                'name': name,
                'outbound_number_id': potentialOutboundNumber.id,
                'skills': skills,
                'worker_id': id,
            }
            console.log(data)
            webHelpers.put('/api/worker/role', 'zeus-api', environment, data, token, (data: any) => {
                if (data.status === 400) {
                    enqueueSnackbar('Unable to create worker and role!', { 'variant': 'error' })
                }
                else {
                    enqueueSnackbar(`Worker with role ${name} successfully reated!`, { 'variant': 'success' })
                    props.cb(id);
                }
            })
        }

    }

    const handleSubmit = () => {
        // generate payload
        let user = {
            name: props.user_name,
            user_id: props.userId,
        }
        // Create worker
        if (name !== '' && potentialOutboundNumber !== undefined) { // checking form is filled out properly
            webHelpers.put('/api/worker', 'zeus-api', environment, user, token, (data: any) => {
                if (data.status !== 200) {
                    let id: string = data.id;
                    setWorkerId(id);
                    createRole(id);
                }
                else {
                    enqueueSnackbar(`${data.status}: ERROR! Worker could not be created.`, { 'variant': 'error' })
                }

            })
        }
    }
    const handleNameChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
        setName(e.target.value)
    }
    const handleDelete = (removedSkill: IAddedSkill) => {
        let newArr: IAddedSkill[] = [];
        potentialSkills.forEach(skill => {
            if (skill.level.skill_id !== removedSkill.level.skill_id) {
                newArr.push(skill)
            }
            else {
                enqueueSnackbar(`Removed ${removedSkill.level.name}`)
            }
        })
        setPotentialSkills(newArr);
    }
    function handleAddedSkills(addedSkill: IAddedSkill) {
        let dirty = false;
        console.log(`Added Skill: ${addedSkill}`)
        if (potentialSkills.length > 0) {
            potentialSkills.forEach(skill  => {
                if (skill.level.skill_id === addedSkill.level.skill_id) {
                        enqueueSnackbar(`You've already added ${skill.level.name}!`, { 'variant': 'error' })
                        dirty = true;
                }
            })
            if(!dirty) {
                setPotentialSkills([...potentialSkills, addedSkill])
            }    
        }
        
        if (potentialSkills.length === 0) {
            setPotentialSkills([...potentialSkills, addedSkill]);
        }
    }
    
    return (
        <Box sx={{ width: '100%', height: '50vh' }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <div className="stepper-items-container">
                {activeStep === 0 ?
                    (
                        <Grow in={activeStep === 0} mountOnEnter unmountOnExit>
                            <Box sx={{ display: 'flex', flexDirection: 'column', pt: 2, p: 5, alignItems: 'center' }}>
                                <TextField sx={{ width: '25ch' }} id="standard-basic" label="Rolename" variant="standard" onChange={handleNameChange} />
                            </ Box>
                        </Grow>
                    ) : (<></>)
                }
                {activeStep === 1 ?
                    (
                        <>
                            <Grow in={activeStep === 1} mountOnEnter unmountOnExit>
                                <Stack sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Stack spacing={1} sx={{ display: 'flex', flexDirection: 'column', p: 3, mt: 4 }}>
                                        {potentialSkills.map(skill => (
                                            <Chip key={skill.id} label={skill.name && ': ' && skill.level.name} onDelete={() => handleDelete(skill)} />
                                        ))}
                                    </Stack>
                                    <Divider orientation="vertical" flexItem />
                                    <Stack sx={{ display: 'flex', width: '100%' }}>
                                        <ChooseSkills handleAddedSkills={handleAddedSkills} />
                                    </Stack>
                                </Stack>
                            </Grow>
                        </>
                    ) : (<></>)
                }

                {activeStep === steps.length - 1 ? (
                    // Final step should be a simple choice of setting the active outbound number from a list of options.
                    <Stack sx={{ display: 'flex', justifyContent: 'space-around', alignContent: 'center', alignItems: 'center', mt: 15, mb: 15 }}>
                        <OutboundSelector outbound_numbers={props.outbound_numbers} cb={setPotentialOutboundNumber} />
                    </Stack>
                ) : (<></>)
                }

                {activeStep === steps.length ? (
                    <React.Fragment>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'center' }}>
                            <Button
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>
                            {activeStep === steps.length - 1 ?
                                (<Button onClick={handleSubmit}>Submit</Button>) :
                                (<Button onClick={handleNext}>Next</Button>
                                )}
                        </Box>
                    </React.Fragment>
                )}
            </div>
        </Box>
    );
}