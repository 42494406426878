
import { Box, Button, Checkbox, FormControl, Input, Modal, Tab, Tabs, TextField, Typography } from "@mui/material";
import * as React from "react";
import { useState, useEffect } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import { useAppSelector } from "../../app/hooks";
import webHelpers from "../../scripts/helpers";
import { useSnackbar } from "notistack";
import StepPanel from "../workflow-steps/workflow-step-panel.component";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CreateStep from '../workflow-step-creator/workflow-step-creator.component';
import '../workflows-table-search/workflows.styles.scss';
import CodeEditorDialog from "../code-editor-dialog/code-editor-dialog.component";
//import {Checkbox, CheckboxGroup, Stack} from "@chakra-ui/react";
import { FormLabel, FormGroup, FormControlLabel, FormHelperText } from "@mui/material";
//@ts-ignore
import { TimePicker } from "react-ios-time-picker";
import { start } from "repl";
import { setDefaultResultOrder } from "dns";
import { ClassNames } from "@emotion/react";
import Send from "@material-ui/icons/Send";
import LoopTwoTone from "@material-ui/icons/LoopTwoTone"
import { time } from "console";
import { stringify } from "querystring";


interface ITimeProfileProps {
  id: string;
  is_bank_holiday_aware: boolean;
  name: string;
  days: {
    id: string;
    day: string;
    start_time: string;
    end_time: string;
  }
  cb: Function;
}
export interface IWorkflowStep {
  id: string;
  created: string;
  created_by_id: string;
  created_by_name: string;
  updated: string;
  updated_by_id: string;
  updated_by_name: string;
  name: string;
  description: string;
  definition_id: string;
  index: number;
  cooldown_time: string;
  estimated_length: string | null;
  maximum_length: string | null;
  tag: string | null;
  execution_type: string;
  code_id: string;
  events: event[];
  twilio_task_type: string | null;
  twilio_task_type_key: string | null;
}

export interface event {
  event_id: string;
  step_id: string;
  code_definition: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '45vw',
  height: '65vh',
  borderRadius: "15px",
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

var startTimesDict = {
  "Monday": "00:00",
  "Tuesday": "00:00",
  "Wednesday": "00:00",
  "Thursday": "00:00",
  "Friday": "00:00",
  "Saturday": "00:00",
  "Sunday": "00:00",
};
var endTimesDict = {
  "Monday": "00:00",
  "Tuesday": "00:00",
  "Wednesday": "00:00",
  "Thursday": "00:00",
  "Friday": "00:00",
  "Saturday": "00:00",
  "Sunday": "00:00",
}
export default function TimeProfileEditModal(props:ITimeProfileProps) {
  const [open, setOpen] = useState(false);
  const [steps, setSteps] = useState<IWorkflowStep[]>();
  const [value, setValue] = useState(1);
  const [initialOpen, setInitialOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState<IWorkflowStep>();
  const [is_bank_holiday_aware, setBankHoliday] = useState<boolean>(props.is_bank_holiday_aware);
  const [name, setName] = useState<string>(props.name);
  const [loading, setLoading] = useState<boolean>(false);
  const [id, setId] = useState<string>("");
  const [startTimes, setStartTimes] = useState({
    Monday: "10:00",
    Tuesday: "10:00",
    Wednesday: "10:00",
    Thursday: "10:00",
    Friday: "10:00",
    Saturday: "10:00",
    Sunday: "10:00",
  });
  const [endTimes, setEndTimes] = useState({
    Monday: "10:00",
    Tuesday: "10:00",
    Wednesday: "10:00",
    Thursday: "10:00",
    Friday: "10:00",
    Saturday: "10:00",
    Sunday: "10:00",
  })
  const setDictionary = () =>{
    
  }
  const [week, setWeek] = useState({
    Monday: false,
    Tuesday: false,
    Wednesday: false,
    Thursday: false,
    Friday: false,
    Saturday: false,
    Sunday: false,
  });
  const handleOpen = () => {
    setOpen(true);
    setInitialOpen(true);
    //@ts-ignore
    console.log(props);
    setId(props.id);
    console.log("initial week ",week);
    for (let days in week) {
      //@ts-ignore
      let doesExist = false;
      console.log(String(days));
      for (let i = 0; i < 7; i++){
        //@ts-ignore
        if((props.days[i].start_time !== "00:00:00" || props.days[i].end_time !== "00:00:00")&& props.days[i].day === days){
          //@ts-ignore
          startTimes[days] = (props.days[i].start_time).slice(0,-3);
          //@ts-ignore
          endTimes[days] = (props.days[i].end_time).slice(0,-3);
          //@ts-ignore
          startTimesDict[days] = (props.days[i].start_time).slice(0,-3);
          //@ts-ignore
          endTimesDict[days] = (props.days[i].end_time).slice(0,-3);
          //@ts-ignore
          doesExist = true;
        }
        //@ts-ignore
        else if (props.days[i].day === days){
          //@ts-ignore
          startTimes[days] =  "00:00";
          //@ts-ignore
          endTimes[days] = "00:00";
        }
      }
      //@ts-ignore
      week[days] = doesExist;
    }
    
    
  }
  const handleClose = () => {
    setOpen(false)
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>,) => {
    setWeek({ ...week, [event.target.name]: event.target.checked });
  }
  const handleBankHolidayChange = (event: React.ChangeEvent<HTMLInputElement>,) => {
    setBankHoliday(!is_bank_holiday_aware);
  }
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>,) => {
    setName(event.target.value);
    console.log(name);
  }
  const handleSumbit = () => {
    setLoading(true);
    let data = {
      id: id,
      name: name,
      is_bank_holiday_aware: is_bank_holiday_aware,
      days: [],
    }
    //create data structure
    for (let days in week) {
      //@ts-ignore
      console.log(week[days]);
      //@ts-ignore
      if (week[days] === true) {
        //@ts-ignore
        data.days.push({ week_day: days, start_time: startTimesDict[days], end_time: endTimesDict[days]})
        //@ts-ignore
        console.log(data.days[days]);
      }
      //@ts-ignore
      else{
        //@ts-ignore
        data.days.push({week_day: days, start_time:"00:00",end_time:"00:00"});
      }
    }
    data.name = name;
    data.is_bank_holiday_aware = is_bank_holiday_aware;
    console.log("JSON parse",JSON.parse(JSON.stringify(data)));
    console.log("/api/workflow/timeprofile/",'helios-api',environment,JSON.stringify(data), token)
    webHelpers.post("/api/workflow/timeprofile",environment,'helios-api',data, token, (data:any ) => {
      if(data.response === 400 || data === undefined){
          enqueueSnackbar('Some server error creating this event subscriber', {'variant':'error'});
      }
      else  {
          enqueueSnackbar('Time Profile Updated Successfully!', {'variant': 'success'});
          setOpen(false);
          setLoading(false);
          props.cb();
      }
    })
    
  }

  const token = useAppSelector((state) => state.loggedIn.token);
  const environment = useAppSelector((state) => state.loggedIn.environment);
  const { enqueueSnackbar } = useSnackbar();

  const changeMonStart = (timeValue: string) => { startTimesDict["Monday"] = timeValue; console.log("dict monday :", startTimesDict["Monday"]) }
  const changeMonEnd = (timeValue: string) => { endTimesDict["Monday"] = timeValue };
  const changeTueEnd = (timeValue: string) => { endTimesDict["Tuesday"] = timeValue };
  const changeWedEnd = (timeValue: string) => { endTimesDict["Wednesday"] = timeValue };
  const changeThuEnd = (timeValue: string) => { endTimesDict["Thursday"] = timeValue };
  const changeFriEnd = (timeValue: string) => { endTimesDict["Friday"] = timeValue };
  const changeSatEnd = (timeValue: string) => { endTimesDict["Saturday"] = timeValue };
  const changeSunEnd = (timeValue: string) => { endTimesDict["Sunday"] = timeValue };
  const changeTueStart = (timeValue: string) => { startTimesDict["Tuesday"] = timeValue };
  const changeWedStart = (timeValue: string) => { startTimesDict["Wednesday"] = timeValue };
  const changeThuStart = (timeValue: string) => { startTimesDict["Thursday"] = timeValue };
  const changeFriStart = (timeValue: string) => { startTimesDict["Friday"] = timeValue };
  const changeSatStart = (timeValue: string) => { startTimesDict["Saturday"] = timeValue };
  const changeSunStart = (timeValue: string) => { startTimesDict["Sunday"] = timeValue };




  const onChange = (timeValue: any, name: string) => {
    //flag represents what day/time to set
    console.log(name);
    if (name === "monday-start") {
      setStartTimes({ ...startTimes, [startTimes.Monday]: timeValue });
      console.log(startTimes.Monday);
    }
    else {
      console.log("placeholder");
    }
  }
  useEffect(() => {
    if (open) {
      console.log("open");
    }
  }, [open]);
  function editInitializer() {

  }


  useEffect(() => {
    if (steps !== undefined) {
      setCurrentStep(steps[value - 1])
    }
  }, [value])

  return (
    <>
      <Button onClick={handleOpen}>
        Edit
        <SettingsIcon />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="header-row">
            <IconButton onClick={() => setOpen(false)} >
              <CloseIcon />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2" className="test-title">
              Modify Existing Time Profile
            </Typography>

            <div></div>
          </div>
          <div className="tab-button-container">
            <div className="button-container">
              {/* TIME PROFILE FORM GOES HERE*/}
              <FormControl component="fieldset" className="time-profile-form">
                {/* ENTER NAME GOES HERE */}
                <div className="name-input">
                  <TextField id="name-input" label="Enter Name of Time Profile" variant="outlined" onChange={handleChangeName} value={name}/>
                </div>
                <div className="legend-row">
                  <FormLabel component="legend">Days of the Week</FormLabel>
                  <FormLabel>Start    Time              </FormLabel>
                  <FormLabel>End Time</FormLabel>
                </div>
                <FormGroup>
                  <div className="input-column">
                    <div className="input-row">

                      <FormControlLabel
                        control={<Checkbox checked={week.Monday} name="Monday" onChange={handleChange} />}
                        label="Monday" className="title-monday"
                      />
                      {/*@ts-ignore */}
                      <TimePicker cellHeight={35} inputClassName={week.Monday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeMonStart} value={startTimes.Monday} disabled={!week.Monday} id="Monday-Start" />
                      <TimePicker cellHeight={35} inputClassName={week.Monday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeMonEnd} value={endTimes.Monday} disabled={!week.Monday} id="Monday-End" />
                    </div>
                    <div className="input-row">
                      <FormControlLabel
                        control={<Checkbox checked={week.Tuesday} name="Tuesday" onChange={handleChange} />}
                        label="Tuesday" className="title-tuesday"
                      />
                      <TimePicker cellHeight={35} inputClassName={week.Tuesday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeTueStart} value={startTimes.Tuesday} disabled={!week.Tuesday} />
                      <TimePicker cellHeight={35} inputClassName={week.Tuesday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeTueEnd} value={endTimes.Tuesday} disabled={!week.Tuesday} />
                    </div>
                    <div className="input-row">
                      <FormControlLabel
                        control={<Checkbox checked={week.Wednesday} name="Wednesday" onChange={handleChange} />}
                        label="Wednesday" className="title-wednesday"
                      />
                      <TimePicker cellHeight={35} inputClassName={week.Wednesday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeWedStart} value={startTimes.Wednesday} disabled={!week.Wednesday} />
                      <TimePicker cellHeight={35} inputClassName={week.Wednesday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeWedEnd} value={endTimes.Wednesday} disabled={!week.Wednesday} />
                    </div>
                    <div className="input-row">
                      <FormControlLabel
                        control={<Checkbox checked={week.Thursday} name="Thursday" onChange={handleChange} />}
                        label="Thursday" className="title-thursday"
                      />
                      <TimePicker cellHeight={35} inputClassName={week.Thursday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeThuStart} value={startTimes.Thursday} disabled={!week.Thursday} />
                      <TimePicker cellHeight={35} inputClassName={week.Thursday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeThuEnd} value={endTimes.Thursday} disabled={!week.Thursday} />
                    </div>
                    <div className="input-row">
                      <FormControlLabel
                        control={<Checkbox checked={week.Friday} name="Friday" onChange={handleChange} />}
                        label="Friday" className="title-friday"
                      />
                      <TimePicker cellHeight={35} inputClassName={week.Friday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeFriStart} value={startTimes.Friday} disabled={!week.Friday} />
                      <TimePicker cellHeight={35} inputClassName={week.Friday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeFriEnd} value={endTimes.Friday} disabled={!week.Friday} />
                    </div>
                    <div className="input-row">
                      <FormControlLabel
                        control={<Checkbox checked={week.Saturday} name="Saturday" onChange={handleChange} />}
                        label="Saturday" className="title-saturday"
                      />
                      <TimePicker cellHeight={35} inputClassName={week.Saturday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeSatStart} value={startTimes.Saturday} disabled={!week.Saturday} />
                      <TimePicker cellHeight={35} inputClassName={week.Saturday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeSatEnd} value={endTimes.Saturday} disabled={!week.Saturday} />
                    </div>
                    <div className="input-row">
                      <FormControlLabel
                        control={<Checkbox checked={week.Sunday} name="Sunday" onChange={handleChange} />}
                        label="Sunday" className="title-sunday"
                      />
                      <TimePicker cellHeight={35} inputClassName={week.Sunday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeSunStart} value={startTimes.Sunday} disabled={!week.Sunday} />
                      <TimePicker cellHeight={35} inputClassName={week.Sunday ? "time-box-style" : "time-box-style2"} popupClassName="time-popup-style" onChange={changeSunEnd} value={endTimes.Sunday} disabled={!week.Sunday} />
                    </div>

                  </div>
                  <div className="input-row">
                    <FormControlLabel
                      control={<Checkbox checked={is_bank_holiday_aware} name="bank-holiday" onChange={handleBankHolidayChange} />}
                      label="Is Bank Holiday Aware?" className="bank-holiday-title"
                    />

                  </div>
                </FormGroup>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={!loading ? <Send /> : <LoopTwoTone/>}
                  className={!loading ? "send-button" : "send-button-loading"}
                  onClick={handleSumbit}
                  disabled={loading}
                >
                  {!loading ? "Send" : "Loading"}
                </Button>
              </FormControl>
            </div>


          </div>



          <div className="button-holder">
            <div className="button-container2">
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}
