import * as React from 'react';
import {useState, useEffect} from 'react';
import webHelpers from "../../scripts/helpers";
import {useAppSelector} from '../../app/hooks';
import {useSnackbar} from 'notistack';
import {
    Avatar,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Modal,
    Typography,
    Box,
    Dialog,
    DialogContent,
    DialogTitle, List, Button
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EventButton from './event.component';
import SearchBar from "../search-bar/search-bar.component";
import Event from './workflow-step-events-dialog.component';
import EventSubscriberCodeEditorComponent from "./event-subscriber-code-editor.component";
interface ICreateNewEventDialogComponentProps {
    step_id: string,
    cb: Function, //callback function to update the list of event subscribers on a step
}
export interface Event {
    id: string,
    name: string,
}
const style = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
};
function CreateNewEventDialogComponent(props: ICreateNewEventDialogComponentProps) {
   const [open, setOpen] = useState(false);
   const [eventId, setEventId] = useState('');
   const [code, setCode] = useState('');
   const [search, setSearch] = useState("");
   const [events, setEvents] = useState<Event[]>([]);
   const [eventSelected, setEventSelected] = useState(false);
    
   const {enqueueSnackbar} = useSnackbar();
    const token = useAppSelector(state => state.loggedIn.token);
    const environment = useAppSelector(state => state.loggedIn.environment);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false); 
    
   function searchEvents() {
        if(search !== ''){
            webHelpers.get('/api/events/?page=1&filter=' + search, environment, 'helios-api', token, (data: any) => {
                if(data.length === 0 || data.response === 400) {
                    enqueueSnackbar('Unable to fetch permissions with this name, please try another', {'variant': 'error'});
                }
                else {
                    setEvents(data);
                }
            })    
        }
   }
   
  const handleSubmit = () => {
       if(code === '' || eventId === '') {
           enqueueSnackbar('please select an event to subscribe to and enter some code before writing to the database', {'variant': 'error'});
       }
       else {
           let payload = {
               code: code
           }
           webHelpers.put('/api/workflow/step/' + props.step_id + '/event?event=' + eventId, 'helios-api', environment,payload, token, (data:any ) => {
               if(data.response === 400 || data === undefined){
                   enqueueSnackbar('Some server error creating this event subscriber', {'variant':'error'});
               }
               else  {
                   enqueueSnackbar('Event subscriber successfully created!', {'variant': 'success'});
                   setOpen(false);
                   props.cb();
               }
           })
       }
  } 
    
    useEffect(() => {
       const requestTimeout = setTimeout(() => {
           searchEvents();
        }, 500);
        return () => clearTimeout(requestTimeout);
    }, [search])
   
    useEffect(() => {
       setEventSelected(true) 
    },[eventId]) 
    
    useEffect(() => {
        setEventSelected(false);
    },[])
    
    return(
        <>
            <ListItem sx={{'cursor': 'pointer' }} onClick={handleOpen}>
                <ListItemAvatar>
                    <Avatar>
                        <AddIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Add Event Subscriber" />
            </ListItem>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <DialogContent>
                <List sx={style}>
                    {eventSelected ? 
                        (
                            <>
                                <DialogTitle>
                                   Code for Event: {eventId} 
                                </DialogTitle>
                            </>    
                        ) : (
                            <DialogTitle>
                                Add an Event Subscriber
                            </DialogTitle>    
                    )}
                    

                    {eventSelected ? 
                        (
                            <>
                               <div style={{display:"flex", flexDirection:'column', justifyContent:'center', alignItems:'center', alignContent:'center'}}> 
                                <EventSubscriberCodeEditorComponent cb={setCode} />
                                   <div className="button-tray">

                                       <Button onClick={() => setEventSelected(false)} >back</Button>
                                       <Button onClick={handleSubmit}>Confirm</Button>
                                   </div>
                               </div>
                            </>
                    ) : (
                     <>
                         <SearchBar placeholder={'Search events'} onChange={setSearch} />
                         {events.map((event: Event) => (<>
                                    <EventButton key={event.id} id={event.id} cb={setEventId}  />
                                 </>
                         ))}
                     </>   
                            
                    ) }

                    
                </List>
                </DialogContent>
            </Dialog> 
        </>
    )
}
export default CreateNewEventDialogComponent;