import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import LockResetIcon from '@mui/icons-material/LockReset';
import webHelpers from '../../scripts/helpers';
import { useAppSelector } from '../../app/hooks';
import { useSnackbar } from 'notistack';

interface IResetPasswordDialogProps {
    id: string,
}

export default function ResetPasswordDialog(props: IResetPasswordDialogProps) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    const token = useAppSelector(state => state.loggedIn.token)
    const environment = useAppSelector(state => state.loggedIn.environment)
    const { enqueueSnackbar } = useSnackbar();

    function resetPassword() {
        let payload = { 'user_id': props.id }
        webHelpers.post('/api/management/password/reset/request', environment, 'auth', payload, token, (data: any) => {
            if (data.status === 200) {
                enqueueSnackbar('Password reset request sent', { 'variant': 'success' })
            }
            else {
                enqueueSnackbar(`${data.status}: Unable to reset this user's password`, { 'variant': 'error' })
            }
        })
    }

    const handleSubmit = () => {
        resetPassword();
        setOpen(false);
    }
    return (
        <div>
            <Tooltip title='reset password'>
                <IconButton aria-label="reset password" onClick={handleClickOpen}>
                    <LockResetIcon sx={{ color: '#1976d2' }} />
                </IconButton>
            </Tooltip>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="reset-password">
                    Reset user password?
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This will send an email to the user asking them to reset their password, the link expires in 15 minutes.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleSubmit} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}