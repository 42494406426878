import * as React from 'react';
import {useState, useEffect} from 'react';
import webHelpers from "../../scripts/helpers";
import {useAppSelector} from '../../app/hooks';
import {useSnackbar} from "notistack";
import AceEditor from "react-ace";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import DialogTitle from "@mui/material/DialogTitle";
import {Avatar, DialogActions, DialogContentText, ListItem, ListItemAvatar, ListItemText} from "@mui/material";
import CodeIcon from '@mui/icons-material/Code';
import {blue, red} from "@mui/material/colors";

interface IEventCodeEditorProps{
    key: string,
    event_id:string,
    code_id: string,
    step_id: string,
    cb: Function,
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EventCodeEditorDialog(props: IEventCodeEditorProps) {
    const [open, setOpen] = useState(false);
    const [initialCode, setInitialCode] = useState<string>('');
    const [code, setCode]  = useState('');
    const [codeId, setCodeId] = useState<string>(props.code_id);
    const [unsavedCode, setUnsavedCode] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [confirmedDelete, setConfirmedDelete] = useState(false);
    const environment = useAppSelector(state => state.loggedIn.environment);
    const token = useAppSelector(state => state.loggedIn.token);
    const {enqueueSnackbar} = useSnackbar();

    const handleClose = () => {
        if(code !== initialCode) {
            setUnsavedCode(true);
        }
        else {
            setOpen(false);
        }
    };
    const handleCancel  = () => {
        
        setUnsavedCode(false);
    }

    function saveCode() {
        if(code !== initialCode ) {
            var payload = {
                'id': codeId,
                'code': code
            }
            webHelpers.post('/api/code/' + codeId, environment, 'helios-api', payload, token, (data:any) => {
                if(data === undefined || data === null ) {
                    enqueueSnackbar('${data.status}: Unable to update code', {'variant': 'error'});
                }
                else {
                    enqueueSnackbar('Code successfully updated!', {'variant': 'success'});
                    setOpen(false);
                    setUnsavedCode(false);
                }
            } )
        }
        else {
            enqueueSnackbar('No changes to code made!', {'variant': 'warning'});
        }
    }

    const handleSave = () => {
        saveCode();
    }
    
    const handleDelete = () => {
        webHelpers.delete('/api/workflow/step/' + props.step_id + '/event?event=' + props.event_id, 'helios-api', environment, token, (data:any) => {
               if(data === undefined || data === null) {
                   enqueueSnackbar('Unable to remove the event subscriber from this step at this time', {'variant': 'error'});
               }
               else {
                enqueueSnackbar('Event subscriber successfully deleted!', {'variant': 'success'})
                    setDeleteDialog(false);
                    props.cb();
               }
           })
    }

    function getCode(id: string) {
        if(codeId !== undefined) {
            webHelpers.get('/api/code/' + id, environment, 'helios-api', token, (data:any) => {
                if(data === undefined || data === null) {
                    enqueueSnackbar('API error: Unable to fetch code for this step', {'variant': 'error'});
                }
                else {
                    setCode(data.code);
                    setInitialCode(data.code);
                }
            })
        }
        else {
            console.log('code id is still undefined!');
        }
    }

    useEffect(() => {
        if(codeId !== undefined && open) {
            getCode(codeId);
        }
    }, [open])
    

    return(
        <>
            <ListItem  >
                    <ListItemAvatar onClick={() => setOpen(true)}>
                        <Avatar sx={{bgcolor: blue[100], color: blue[600], cursor:'pointer'}}>
                            <CodeIcon/>
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText 
                        sx={{paddingLeft:'1rem', paddingRight:'2rem'}}
                        primary={props.event_id} />    
                    <ListItemAvatar 
                        onClick={() => setDeleteDialog(true)} >
                        <Avatar
                            sx={{bgcolor: red[100], color: red[600], cursor:'pointer'}}>
                            <DeleteIcon />
                        </Avatar>
                    </ListItemAvatar>    
            </ListItem>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <p> Editing Event Subscriber for: {props.event_id} </p>
                        <Button autoFocus color="inherit" onClick={handleSave}>
                            Save
                        </Button>
                    </Toolbar>
                    <AceEditor
                        style={{
                            height: "95vh",
                            width: "100%",
                        }}
                        theme="gruvbox"
                        placeholder={initialCode}
                        mode="csharp"
                        name="Script for step"
                        onChange={(currentCode) => setCode(currentCode)}
                        fontSize={18}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        value={code}
                        tabSize={4}
                    />
                </AppBar>
            </Dialog>

            <Dialog
                open={unsavedCode}
                onClose={handleCancel}
                TransitionComponent={Transition}
                sx={{padding: '2rem' }}
            >
                <DialogTitle>Wait!</DialogTitle>
                <p style={{padding: '1rem'}}> You have made changes to the code which haven't been saved,
                    if you exit now you'll lose any changes made!</p>
                <DialogActions>
                    <Button onClick={() => setUnsavedCode(false)}>Continue Coding</Button>
                    <Button onClick={handleSave}>Save Changes</Button>
                    <Button onClick={() => {
                        setOpen(false);
                        setUnsavedCode(false);
                    }}>Discard Changes</Button>
                </DialogActions>
            </Dialog>
            
            <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)}
                    TransitionComponent={Transition}
                    sx={{padding: '1rem'}} >

                <DialogTitle>Delete Event Subscriber </DialogTitle>
                <p> Are you sure you want to delete the event subscriber for {props.event_id}? </p>
                
                <DialogActions>
                    <Button onClick={() => setDeleteDialog(false)}> Cancel</Button>
                    <Button onClick={handleDelete}> Confirm</Button>
                    
                </DialogActions>
                
            </Dialog>
        </>
    )
}
export default EventCodeEditorDialog;