import * as React from 'react';
import {useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {TextField} from "@mui/material";


interface TagProps {
    tag: string | null,
    cb: Function,
}

let initialVal : string;
function TagEditor(props: TagProps){
    const[open, setOpen] = useState(false);
    const[tag, setTag] = useState(props.tag);
    const[checked, setChecked] = useState(false);
    const[confirmed, setConfirmed] = useState(false);
    
   const handleTagChange = (e: { target: { value: React.SetStateAction<string | null>; }; }) => {
       setTag(e.target.value);
   } 
   const handleNext = () => {
       setChecked(true);
   }
    const handleClickOpen = () => {
        setOpen(true);
    };
   
   const handleSubmit = () => {
       props.cb(tag);
       setOpen(false);
   }
    const handleClose = () => {
        setOpen(false);
    };
    const handleContinue = () => {
        setConfirmed(true);
    }
    useEffect(() => {
        if(props.tag !== null){
            initialVal = props.tag
        }
    }, [])
    useEffect(() => {
       if(confirmed || checked) {
           setConfirmed(false);
           setChecked(false);
           setTag('')
       }
    }, [open])

    return(
    <>
        <Button variant="outlined" onClick={handleClickOpen} sx={{marginLeft: '0.5rem'}}>
            Edit Tag
        </Button>
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Editing Tag: {props.tag}
            </DialogTitle>
            <DialogContent>
                {!confirmed && !checked ?
                (
                    <DialogContentText id="alert-dialog-description">
                        Please be aware, editing the tag has serious consequences for functionality of other programs which rely on it.
                        Only do this if you're absolutely sure you know what you're doing.
                    </DialogContentText>
                ) : (
                    <></>
                    )}
                {confirmed  && !checked ? 
                    (
                   <>
                       <TextField
                           label={'tag'}
                           value={tag}  
                           onChange={handleTagChange}
                           />
                   </> 
                    
                ) : (
                 <></> 
            ) }
                {confirmed && checked ? (
                    <>
                   <p> Changing tag from: {initialVal} {'\u2192'} {tag}</p> 
                        <strong><p>Are you sure this is correct?</p></strong>
                        <em style={{fontSize:'11px'}}>Note: changes will only take effect once you have pressed "save changes" on the step</em>
                    </>
                ): (
                   <></> 
                )}
           
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>Close</Button>
            {confirmed && !checked ? (
                    <>
                   <Button  onClick={handleNext}>Next</Button>  
                    </>
                ) : (
                    <>
                            
                    </>
                )}
            {!confirmed && !checked ? (
                <Button onClick={handleContinue} autoFocus>
                    Continue
                </Button>
            ) : (<></>)}
            {confirmed && checked ? 
            (
               <Button onClick={handleSubmit}>Submit</Button> 
            ) : (
               <></> 
                ) }
                
            </DialogActions>
        </Dialog> 
    
    </>
    )
}


export default TagEditor;