import * as React from 'react';
import './card.styles.scss';

const Card = (props: any) => {
    return (
        <>
            <div className="card">
                {props.children}
            </div>
        </>
    )
}

export default Card;