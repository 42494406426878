import * as React from "react";
import { useState } from "react";
import "./blocked-numbers-modal.styles.scss";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import CountryCodes from "./country-codes.component";
import webHelpers from "../../scripts/helpers";
import { useAppSelector } from "../../app/hooks";
import { useSnackbar } from "notistack";

interface AddNumberProps {
  cb: Function;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: 400,
  width: 800,
  bgcolor: "background.paper",
  borderRadius: "1rem",
  boxShadow: 14,
  p: 4,
};

const AddNumber = (props: AddNumberProps) => {
  const [open, setOpen] = useState(false);
  const [newNum, setNewNum] = useState("");
  const [reason, setReason] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [type, setType] = useState("3");

  const token = useAppSelector((state) => state.loggedIn.token);
  const environment = useAppSelector((state) => state.loggedIn.environment);
  const { enqueueSnackbar } = useSnackbar();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNumberChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setNewNum(e.target.value);
  };
  const handleReasonChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setReason(e.target.value);
  };

  const handleTypeChange = (event: SelectChangeEvent) => {
    setType(event.target.value as string);
  };

  function AddNumber() {
    let fullNumber;
    if (type == "0" || type == "1") {
      fullNumber = countryCode + newNum;
      fullNumber.replace('+', ''); // Replace the + so we can re-add it server side
    } else {
      fullNumber = newNum;
    }
    const payload = {
      id: fullNumber,
      reason: reason,
      type: type,
    };

    webHelpers.put(
      "/api/call/control/block/",
      "zeus-api",
      environment,
      payload,
      token,
      (data: any) => {
        if (data === undefined) {
          enqueueSnackbar(
            "Unable to add number to the database of blocked numbers!",
            { variant: "error" }
          );
        } else {
          props.cb();
          setOpen(false);
        }
      }
    );
  }

  const handleAddNumber = () => {
    let dirty = false;
    if (type == "3" || reason === "" || newNum === "") {
      enqueueSnackbar("Please complete all form fields!", {
        variant: "warning",
      });
      dirty = true;
    }
    if (/^[0-9]+$/.test(newNum) === false) {
      enqueueSnackbar(
        "only enter digits into the number field, \nif you need to use a country code be sure to select whole number from the drop-down menu.",
        { variant: "warning" }
      );
      dirty = true;
    }
    if (type == "0" && (newNum.length < 3 || newNum.length > 10)) {
      console.log(countryCode);
      enqueueSnackbar(
        "Please enter a number that is between 3 and 10 digits long",
        { variant: "warning" }
      );
      dirty = true;
    }
    if (
      (type == "1" || type == "2") &&
      (newNum.length < 3 || newNum.length > 10)
    ) {
      enqueueSnackbar(
        "Please enter a number that is between 3 and 10 digits long",
        { variant: "warning" }
      );
      dirty = true;
    } else if (dirty === false) {
      AddNumber();
    }
  };

  return (
    <>
      <Button onClick={handleOpen}>Add Number</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h3">
            Block a new number
          </Typography>
          <div className="form">
            <div className="form-inputs">
              <div className="filler"></div>
              <div className="form-inputs-type">
                <FormControl>
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Select
                    labelId="Number Input Type Label"
                    id="number-type"
                    value={type}
                    label="Type"
                    onChange={handleTypeChange}
                  >
                    <MenuItem value={0}>Whole Number</MenuItem>
                    <MenuItem value={1}>Starts With</MenuItem>
                    <MenuItem value={2}>Ends With</MenuItem>
                  </Select>
                </FormControl>
              </div>

              <div className="form-inputs-number">
                {type == "0" || type == "1" ? (
                  <>
                    <CountryCodes cb={setCountryCode} />
                  </>
                ) : (
                  <></>
                )}
                <TextField type="number" label="Number" onChange={handleNumberChange} />
              </div>
              <div className="form-inputs-reason">
                <TextField label="Reason" onChange={handleReasonChange} />
              </div>
            </div>
          </div>
          <div className="button-tray">
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleAddNumber}>Add</Button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default AddNumber;
