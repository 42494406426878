import * as React from 'react';
import { useState, useEffect } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { Chip } from '@mui/material';
import { Detail, Level, Skill, UserRole } from '../../../assets/global-interfaces/role-management.interfaces';
import Zoom from '@mui/material/Zoom';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { blue } from '@mui/material/colors';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import webHelpers from '../../../scripts/helpers';
import { useAppSelector } from '../../../app/hooks';
import { useSnackbar } from 'notistack';

interface SkillChipProps {
    skill: Detail,
    handleEdit: Function,
    handleDelete: Function,
    levels: Level[],
    allSkills: Skill[],
    userId: string,
}

export default function SkillChip(props: SkillChipProps) {
    const [open, setOpen] = useState(false);
    const [relevantLevels, setRelevantLevels] = useState<Level[]>([]);
    const [skill, setSkill] = useState(props.skill);

    const handleClick = () => {
        setOpen(true);
    }
    const handleClose = () => {
        setOpen(false);
    };
    const token = useAppSelector(state => state.loggedIn.token);
    const environment = useAppSelector(state => state.loggedIn.environment);
    const { enqueueSnackbar } = useSnackbar();


    const handleListItemClick = (level: Level) => {
        // Call to update the skill level starts here
        props.handleEdit(props.skill.skill_id, level);
        refreshSkill();
        setOpen(false);
    };

    useEffect(() => {
        props.allSkills.map((skill: Skill) => {
            if (skill.id === props.skill.skill.id) {
                skill.levels.forEach((level) => {
                    setRelevantLevels((previousLevels) => [...previousLevels, level]);
                });
            }
        })
    }, []);

    function refreshSkill() {
        // For updating the skills chips on change
        webHelpers.get('/api/worker/roles/' + props.userId, environment, 'zeus-api', token, (data: UserRole[]) => {
            if (data === undefined || data === null) {
                enqueueSnackbar(`${data}: Unable to fetch skills`, { 'variant': 'error' })
            }
            else {
                data.forEach((role: UserRole) => {
                    if (role.id === props.skill.role_id) {
                        role.details.forEach((detail: Detail) => {
                            if (detail.skill_id === skill.skill_id) {
                                console.log(detail);
                                setSkill(detail);
                            }
                        })
                    }
                })
            }
        });

    }

    return (
        <>

            <Tooltip title="Edit" enterDelay={500} leaveDelay={200} TransitionComponent={Zoom} placement='left'>
                <Chip

                    color='primary'
                    variant='outlined'
                    id={props.skill.skill_id}
                    label={props.skill.skill.name && ' ' && props.skill.level.name}
                    onDelete={() => props.handleDelete(props.skill.id)}
                    onClick={handleClick}
                    sx={{ 'display': 'flex', 'flexDirection': 'row', 'justify-content': 'space-between' }}
                />
            </Tooltip>

            <Dialog onClose={handleClose} open={open}>
                <DialogTitle>Select Skill Level</DialogTitle>
                <List sx={{ pt: 0 }}>
                    {relevantLevels.map((level: Level) => (
                        <ListItem  button onClick={() => handleListItemClick(level)} key={level.id}>
                            <ListItemAvatar>
                                <Avatar sx={{ bgcolor: blue[100], color: blue[600] }}>
                                    <FitnessCenterIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText>{level.name}</ListItemText>
                        </ListItem>
                    ))}
                </List>
            </Dialog>
        </>)
}
