import React from 'react';
import SearchIcon from './magnifying-glass.svg';
import './search-bar.styles.scss';
// PLEASE NOTE: This file depends on the npm module 'sass'
// If missing please run 'npm install sass' or 'yarn add sass'


// The Search bar component is a very simple functional component that takes a placholder string and function as props from the parent element.
// This allows the parent element to use hooks/state to update based on the input of this search bar.

interface SEARCHBARPROPS {
    placeholder: string;
    onChange: Function;
}

const SearchBar = (props: SEARCHBARPROPS) => {
    // call back function prop tied to the onChange event handler of the search-bar's input box
    const handleChange = (e: React.FormEvent<HTMLInputElement>)  => {
        props.onChange(e.currentTarget.value);
    }
            

    return(
        <div className="search-bar-container">
            <img src={SearchIcon} className="search-bar-icon" alt='search-icon' />
            <input type='text' className='search-bar-input' onChange={handleChange} placeholder={props.placeholder} />
        </div>
    );


};
export default SearchBar;