import * as React from 'react';
import { useState, useEffect } from 'react';
import webHelpers from '../../scripts/helpers';
import {UserRole, UserRoleUpdate} from '../../assets/global-interfaces/role-management.interfaces';
import {TableCell,TableRow} from '@mui/material';
import CloneRoleDialog from './clone-role.component';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import { useSnackbar } from 'notistack';
import { useAppSelector } from '../../app/hooks';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import SkillsDialog from './edit-role/role-skills-management-dialog.component';
import DeleteIcon from '@mui/icons-material/Delete';
import {  ISkill } from './create-role/create-role-stepper.component';
import {useDictionary} from "../../scripts/useDictionary";
import {ISkillToAdd} from "./edit-role/role-add-skill.component";

interface RoleProps {
    userId: string,
    role: UserRole,
    handleDelete: Function,
    handleSetActive: Function,
    activeRoleId?: string,
    cb: Function,
}

const useSkillsDictionary = function () {
    const {
        state,
        onUpdateValue,
        onClearValue,
        onClear
    } = useDictionary<{ [skill_id: string]: ISkill }, string>({});

    return {
        skills: state, onUpdateSkill: onUpdateValue, onClearSkill: onClearValue, onClearSkills: onClear
    }
}

const Role = (props: RoleProps) => {
    const [role, setRole] = useState<UserRole>(props.role);

    const { skills, onUpdateSkill, onClearSkill, onClearSkills } = useSkillsDictionary();
    
    const token = useAppSelector(state => state.loggedIn.token);
    const environment = useAppSelector(state => state.loggedIn.environment);
    const { enqueueSnackbar } = useSnackbar();

    function handleClone(role_name: string) {
        let newRole : UserRoleUpdate = {
            id: role.id,
            name: role_name,
            worker_id: role.worker_id,
            allow_aux: role.allow_aux,
            auto_answer: role.auto_answer,
            skills: [],
            outbound_rule: role.outbound_rule,
            outbound_number_id: role.outbound_number_id
        };

        role.details.forEach(s => {
            newRole.skills.push({ skill_id: s.skill_id, level_id: s.level_id })
        });

        webHelpers.put('/api/worker/role', 'zeus-api', environment, newRole, token, (data: any) => {
            if (data.status === undefined) {
                enqueueSnackbar('New role successfully created', { 'variant': 'success' })
                props.cb();
            }
            else {
                enqueueSnackbar(`${data.status}: Unable to create new role`, { 'variant': 'error' })
            }
        })
    }
    
    function handleAuxChange(aux_value: boolean) {
        let newRole : UserRole = {
            id: role.id,
            name: role.name,
            details: role.details,
            worker_id: role.worker_id,
            allow_aux: aux_value,
            auto_answer: role.auto_answer,
            outbound_rule: role.outbound_rule,
            outbound_number_id: role.outbound_number_id
        };
        setRole(newRole);
        updateRole();
    }

    function handleAutoAnswerChange(auto_answer: boolean) {
        let newRole = role;
        newRole.auto_answer = !auto_answer;
        setRole(newRole)
        updateRole();
    }
    
    function submitAddedSkills(additionalSkills: { [ skill_id: string ]: ISkillToAdd }) {
        Object.values(additionalSkills).forEach(skill => {
            console.log("Skill: ", skill)
            onUpdateSkill(skill.skill_id, skill); 
        });
        console.log("Updated Skills: ", skills)
        updateRole();
    }

    function removeSkill(id: string) {
        onClearSkill(id)
        updateRole();
    }
    function handleSetActive(id: string) {
        console.log("setting active");
        console.log("id = ", id);
        console.log("/api/worker/role/"+id+"/set", environment)
        
        webHelpers.post('/api/worker/role/' + id + '/set', environment, 'zeus-api', {}, token, (data: any) => {
            if (data.status === undefined) {
                enqueueSnackbar('role successfully set to active!', { 'variant': 'success' });
                props.cb();
                
                
                
                
            }
        })
        
    }
    
    function updateRole() {
        let r: UserRoleUpdate = {
            ...role,
            skills: Object.values(skills)
        };
        console.log("monkey");
        console.log("updating role: ", r);
        console.log("previous role: ", role);
        
        webHelpers.post('/api/worker/role', environment, 'zeus-api', r, token, (data: any) => {
            setRole(data);
            enqueueSnackbar('role updated!');
        });
    }

    useEffect(() => {
        onClearSkills();
        
        role.details.forEach(detail => {
            onUpdateSkill(detail.skill_id, { skill_id: detail.skill_id, level_id: detail.level_id});
        });
    }, [ role ]);
    
    return (<>
        <TableRow key={role.id}>
            <TableCell style={{ width: 160 }} component="th" scope="row" align="left">
                <div className="icon-name-container">
                    {props.activeRoleId === role.id ? <DoubleArrowIcon color='primary' /> :
                        (
                            <IconButton aria-label="delete" size="large" onClick={() => handleSetActive(role.id)} >
                                <SyncAltIcon />
                            </IconButton>
                        )
                    }
                    <p>{role.name}</p>
                </div>

            </TableCell>

            <TableCell style={{ width: 160 }} align="left">
                {skills !== undefined &&
                    <SkillsDialog role={role} submitAddedSkills={submitAddedSkills} removeSkill={removeSkill} userId={props.userId} refreshRoles={props.cb} />
               }

            </TableCell>

            <TableCell style={{ width: 80 }} align="left">
                <Switch
                    checked={role.auto_answer}
                    onChange={() => handleAutoAnswerChange(role.auto_answer)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </TableCell>

            <TableCell style={{ width: 80 }} align="left">
                <Switch
                    checked={role.allow_aux}
                    onChange={() => handleAuxChange(role.allow_aux)}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
            </TableCell>
            <TableCell style={{ width: 10 }} align="right">
                <CloneRoleDialog cloneRoleFunction={handleClone} />
            </TableCell>
            <TableCell style={{ width: 5 }} align="right">
                <IconButton aria-label="delete" size="large" onClick={() => props.handleDelete(role.id)}>
                    <DeleteIcon />
                </IconButton>
            </TableCell>

        </TableRow>
    </>)
}

export default Role;